import React from 'react';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { ExecutionHistory } from './common/ExecutionHistory';

class BaseSwapContractChargeType {
    contractId = '';

    targetChargeType = '';

    sourceChargeType = '';
}

class SwapContractChargeType extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            swapChargeTypeData: new BaseSwapContractChargeType(),
            isValidated: false,
            selectedChargeTypeOption: null,
            selectedContractOption: null,
            selectedBasedChargeTypeOption: null,
            chargeTypeEmpty: false,
            basedChargeTypeList: [],
        };

        this.onChange = this.onChange.bind(this);
    }

    onBaseChargeTypeChanged = (selectedOption) => {
        this.setState((prevState) => ({
            selectedBasedChargeTypeOption: selectedOption,
            swapChargeTypeData: {
                ...prevState.swapChargeTypeData,
                sourceChargeType: selectedOption ? selectedOption.value : null,
            },
        }));
    };

    onChange = onFieldChange;

    onChargeTypeChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            swapChargeTypeData: {
                ...prevState.swapChargeTypeData,
                targetChargeType: selectedOption.value,
            },
            selectedChargeTypeOption: selectedOption,
        }));
    };

    onSubmit = async () => {
        const { swapChargeTypeData } = { ...this.state };
        const chargeTypeEmpty = !this.state.selectedChargeTypeOption;
        if (chargeTypeEmpty) {
            this.setState({ isValidated: true });
            toasty.error('Please fill in all required fields.');
            return;
        }

        this.setState({ saving: true });

        try {
            await util.fetch.post(ApiRoutes.adminTool.swapContractChargeType(), swapChargeTypeData);

            this.setState({
                swapChargeTypeData: {
                    ...this.state.swapChargeTypeData,
                    contractId: '',
                    targetChargeType: '',
                    sourceChargeType: ''
                },
                isValidated: false,
                selectedContractOption: null,
                selectedChargeTypeOption: null,
                selectedBasedChargeTypeOption: null,
            });

            toasty.success('Charge types have been swapped successfully.');

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error('Error: Swap failed. Please Check Form Inputs');
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    onContractNumberBlur = async (event) => {
        const { selectedContractOption } = this.state;
        const contractId = selectedContractOption ? selectedContractOption.value : '';

        try {
            const basedChargeTypes = await util.fetch.js(
                ApiRoutes.chargeType.byContractId(contractId),
            );
            if (!basedChargeTypes || basedChargeTypes.length === 0) {
                this.setState({
                    basedChargeTypeList: [],
                });
                return;
            }

            const basedChargeTypeOptions = basedChargeTypes.map((item) => ({
                value: item.id,
                label: item.description,
            }));

            this.setState({
                basedChargeTypeList: basedChargeTypeOptions,
            });
        } catch (err) {
            this.setState({
                basedChargeTypeList: [],
            });
        }
    };

    getChargeType = async (targetChargeType) => {
        const chargeType = await util.fetch.js(
            ApiRoutes.chargeType.byId(targetChargeType),
        );
        return chargeType;
    };

    loadOptions = (inputValue, callback) => {
        fetch(ApiRoutes.typeAheads.chargeTypeSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                const options = data.map((item) => ({
                    label: item.label,
                    value: item.value,
                }));
                callback(options);
            });
    };

    loadContractOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.contractsSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.name}] ${item.status}`,
                    value: item.value
                }));
            })
            .catch((error) => {
                console.error('Error fetching contract options:', error);
                return [];
            });
    };

    onContractChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            swapChargeTypeData: {
                ...prevState.moveTimesheet,
                contractId: selectedOption.value
            },
            selectedContractOption: selectedOption,
        }));
    };

    render() {
        const {
            loading, saving, isValidated, basedChargeTypeList,
            selectedBasedChargeTypeOption,
        } = this.state;
        
        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Swap Charge Type"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="contractNum" text="Contract Number" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadContractOptions}
                                        placeholder="Type Contract Number Here"
                                        id="contractNum"
                                        value={this.state.selectedContractOption}
                                        onChange={this.onContractChanged}
                                        onBlur={this.onContractNumberBlur}
                                        cacheOptions
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Contract Number is Required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="sourceChargeType" text="Old Charge Type" required />
                                    <ValidatedSelect
                                        id="sourceChargeType"
                                        name="sourceChargeType"
                                        required
                                        options={basedChargeTypeList}
                                        value={selectedBasedChargeTypeOption}
                                        onChange={this.onBaseChargeTypeChanged}
                                        validationMessage="An Old Charge Type selection is required."
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="targetChargeType" text="New Charge Type" required />
                                    <div className="d-flex flex-column">
                                        <AsyncSelect
                                            name="targetChargeType"
                                            classNamePrefix="react-async"
                                            className="smallReactAsync"
                                            loadOptions={this.loadOptions}
                                            placeholder="Type Charge Type Here"
                                            id="targetChargeType"
                                            value={this.state.selectedChargeTypeOption}
                                            onChange={this.onChargeTypeChanged}
                                            isClearable
                                            cacheOptions
                                        />
                                    </div>
                                </FormGroup>

                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <List type="unstyled">
                            {record.input.contract_number && (
                                <li>
                                    <strong>Contract:</strong>
                                    {' '}
                                    {record.input.contract_number}
                                </li>
                            )}
                            {record.input.target_charge_type && (
                                <li>
                                    <strong>New Charge Type:</strong>
                                    {' '}
                                    {record.input.target_charge_type}
                                </li>
                            )}
                            {record.input.source_charge_type && (
                                <li>
                                    <strong>Old Charge Type:</strong>
                                    {' '}
                                    {record.input.source_charge_type}
                                </li>
                            )}
                        </List>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(SwapContractChargeType);
