import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Input,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { ExecutionHistory } from './common/ExecutionHistory';

class GrantMultiTenantAccessViewModel {
    userName = '';
}

class AddJobCity extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            data: new GrantMultiTenantAccessViewModel(),
            isValidated: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange = onFieldChange;

    onSubmit = async () => {
        const { data } = this.state;

        this.setState({ saving: true });

        try {
            await util.fetch.post(
                ApiRoutes.adminTool.grantMultiTenantAccess(),
                data,
            );

            this.setState({
                data: new GrantMultiTenantAccessViewModel(),
                isValidated: false,
            });

            toasty.success(`Access has been granted to ${data.userName}`);

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error(
                'Error: Access grant. Please Check Form Inputs',
            );
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    render() {
        const {
            loading,
            saving,
            isValidated,
            data,
        } = this.state;

        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Grant Multi-Tenant Access"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col className="bg-light border" xs="12">
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel
                                        htmlFor="userName"
                                        text="User name"
                                        required
                                    />
                                    <Input
                                        id="userName"
                                        name="data.userName"
                                        placeholder="Full username you wish to grant permission to"
                                        onChange={this.onChange}
                                        value={data.userName}
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">
                                        User name is Required.
                                    </small>
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={saving}
                                    >
                                        {!saving && <span>Submit</span>}
                                        {saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>
                                                    Submitting, please wait...
                                                </span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <List type="unstyled">
                            {record.input.userName && (
                                <li>
                                    <strong>User name:</strong>
                                    {' '}
                                    {record.input.userName}
                                </li>
                            )}
                        </List>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(AddJobCity);
