import React from 'react';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { ExecutionHistory } from './common/ExecutionHistory';

class RejectBillingPayrollVM {
    timesheetId = '';
}

class RejectBillingPayroll extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            RejectBillingPayrollVM: new RejectBillingPayrollVM(),
            selectedTimesheetOption: null,
            isValidated: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange = onFieldChange;

    onSubmit = async () => {
        const { RejectBillingPayrollVM } = { ...this.state };

        this.setState({ saving: true });
        try {
            await util.fetch.post(ApiRoutes.adminTool.rejectBillingPayroll(), RejectBillingPayrollVM);

            this.setState({
                RejectBillingPayrollVM: {
                    ...this.state.RejectBillingPayrollVM,
                    timesheetNumber: '',
                },
                selectedTimesheetOption: null,
                isValidated: false,
            });

            toasty.success('Billing Payroll Rejection Success');

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error('Error: Billing Payroll Rejection Failed. Please Check Timesheet Number');
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    loadTimesheetOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.timesheetNumberSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.customer}] - ${item.status}${item.isDeleted ? ' (Deleted)' : ''}`,
                    value: item.value,
                    isDeleted: item.isDeleted
                }));
            })
            .catch((error) => {
                console.error('Error fetching timesheet options:', error);
                return [];
            });
    };

    onTimesheetChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            RejectBillingPayrollVM: {
                timesheetId: selectedOption.value
            },
            selectedTimesheetOption: selectedOption,
        }));
    };

    render() {
        const {
            loading, saving, isValidated,
        } = this.state;
        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Reject Billing Payroll"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNum" text="Timesheet Number" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadTimesheetOptions}
                                        placeholder="Type Timesheet Number Here"
                                        id="timesheetNumber"
                                        value={this.state.selectedTimesheetOption}
                                        onChange={this.onTimesheetChanged}
                                        cacheOptions
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Timesheet Number is Required.
                                    </small>
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <>
                            <div>
                                <List type="unstyled">
                                    {record.input.timesheetNumber && (
                                        <li>
                                            <strong>Timesheet:</strong>
                                            {' '}
                                            {record.input.timesheetNumber}
                                        </li>
                                    )}
                                </List>
                            </div>

                            <div>
                                <List type="unstyled">
                                    {record.output.removedBillingDetails && (
                                        <li>
                                            <strong>Removed Billing:</strong>
                                            {' '}
                                            {record.output.removedBillingDetails}
                                        </li>
                                    )}
                                    {record.output.removedPayrollCalculationIds && (
                                        <li>
                                            <strong>Removed Payroll Calcuations (ID):</strong>
                                            {' '}
                                            {record.output.removedPayrollCalculationIds}
                                        </li>
                                    )}
                                    {record.output.removedPayrollIds && (
                                        <li>
                                            <strong>Removed Payrolls (ID):</strong>
                                            {' '}
                                            {record.output.removedPayrollIds}
                                        </li>
                                    )}
                                    {!record.output.removedPayrollIds && !record.output.removedPayrollCalculationIds && !record.output.removedBillingDetails && (
                                        <li>
                                            <strong>Result:</strong>
                                            {' '}
                                                                        Billing Payroll Removal Success
                                        </li>
                                    )}
                                </List>
                            </div>
                        </>

                        
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(RejectBillingPayroll);
