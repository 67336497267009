import React from 'react';
import { Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    indexCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { util } from '../Util';

class USStatesIndex extends React.Component {
    static contextType = CommonContext;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowData: [],
        };
    }

    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        try {
            const usStates = await util.fetch.js(ApiRoutes.USStates.all());
            console.log('API Response:', usStates);

            if (!Array.isArray(usStates)) {
                throw new Error('Invalid API response: Expected an array of states.');
            }

            const mappedStates = usStates.map((state) => ({
                id: state.id,
                name: state.name,
                abbreviation: state.abbreviation,
                perDiemRate: state.perDiemRate ?? 0, 
            }));

            const usStateFilterParams = {
                suppressFilterButton: true,
                options: mappedStates,
                optionsLabel: 'name',
                optionsValue: 'name',
            };

            const gridOptions = createGridOptions(this);

            gridOptions.components = {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                nameRenderer: LinkCellRenderer,
            };

            gridOptions.columnDefs = [
                {
                    flex: 0,
                    maxWidth: 80,
                    headerName: '',
                    valueGetter: 'node.id',
                    sortable: false,
                    cellRenderer: indexCellRenderer,
                },
                {
                    colId: 'name',
                    headerName: 'State',
                    sortable: true,
                    flex: 1.5,
                    field: 'name',
                    cellRenderer: 'nameRenderer',
                    cellRendererParams: {
                        to: (id) => `${AppNavPaths.USState}/${id}`,
                        nameField: 'name',
                        idField: 'id',
                        title: 'View this State',
                    },
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: usStateFilterParams,
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: usStateFilterParams,
                },
                {
                    colId: 'perDiemRate',
                    sortable: true,
                    headerName: 'Per-Diem Rate',
                    field: 'perDiemRate',
                    valueFormatter: (params) => `$${params?.value?.toFixed(2)}`,
                },
            ];

            const dataSource = createDataSource(ApiRoutes.USStates.search(), gridOptions);

            this.setState({
                loading: false,
                rowData: mappedStates,
                gridOptions,
                dataSource,
            });
        } catch (error) {
            console.error('Error populating states:', error);
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, gridOptions, dataSource, rowData } = this.state;

        if (loading) return <Progress />;

        return (
            <PageWrap>
                <PageHeading>
                    <span>States</span>
                </PageHeading>
                <DataGridToolbar
                    entity="state"
                    gridApi={this.state.gridApi}
                    dataSource={dataSource}
                    hideExcelButton={false}
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(USStatesIndex);
