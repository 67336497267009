export class PayrollCalculationEdit {
    currentId = null;

    start = null;

    end = null;

    quantity = 0.00;

    notes = '';

    increment = null;

    pay = 0.00;

    reg = 0.00;

    rsd = 0.00;

    ot = 0.00;

    otsd = 0.00;

    hol = 0.00;

    brv = 0.00;

    prd = 0.00;

    sck = 0.00;

    vac = 0.00;

    pw = 0.00;

    pwot = 0.00;

    pwt15 = 0.00;
}
