export class ChargeTypeGroup {
    id = 0;

    tenantId = 0;

    groupName = '';

    billCharge = 0.0;

    billQuantity = 8.0;

    chargeTypes = [];

    quickBooksInventoryItemId = null;
}
