import React from 'react';
import { Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource, createGridOptions, DataGrid, LinkCellRenderer, TextFilterDefaults, indexCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { util } from '../Util';

class USCountyGroupsIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rowData: [],
        };

        this.onAddCountyGroup = this.onAddCountyGroup.bind(this);
    }

    componentDidMount() {
        return this.populateState();
    }

    onAddCountyGroup = () => {
        this.props.history.push(`${AppNavPaths.USCountyGroupNew}`);
    };

    async populateState() {
        const [groupTypes, usStates, contractUnionWorkTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.USCounties.groupTypes()),
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
            util.fetch.js(ApiRoutes.typeAheads.contractUnionWorkTypes()),
        ]);

        const gridOptions = createGridOptions(this);

        const workTypeFilterParams = {
            suppressFilterButton: true,
            options: contractUnionWorkTypes.filter(x => x.isUnionWork),
            optionsLabel: 'label',
            optionsValue: 'id',
        };

        const groupTypeFilterParams = {
            suppressFilterButton: true,
            options: groupTypes,
            optionsLabel: 'description',
            optionsValue: 'id',
        };

        const usStateFilterParams = {
            suppressFilterButton: true,
            options: usStates,
            optionsLabel: 'label',
            optionsValue: 'id',
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        gridOptions.columnDefs = [
            {
                flex: 0,
                maxWidth: 80,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Name',
                sortable: false,
                headerName: 'Name',
                field: 'description',
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    to: (id) => `${AppNavPaths.USCountyGroup}/${id}`,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this County Group',
                },
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilter: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'USState',
                headerName: 'State',
                sortable: false,
                flex: 1.5,
                field: 'usStateName',
                tooltipField: 'usStateName',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: usStateFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: usStateFilterParams,
            },
            {
                colId: 'GroupType',
                headerName: 'Type',
                sortable: false,
                flex: 1.5,
                field: 'groupType',
                tooltipField: 'groupType',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: groupTypeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: groupTypeFilterParams,
            },
            {
                colId: 'WorkType',
                headerName: 'Applicable Work Type(s)',
                sortable: false,
                flex: 1.5,
                field: 'contractUnionWorkTypeList',
                tooltipField: 'contractUnionWorkTypeList',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: workTypeFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: workTypeFilterParams,
            },
            {
                colId: 'PayRate',
                headerName: 'Pay Rate',
                sortable: false,
                flex: 1.5,
                field: 'workTypePayRates', // Assuming this field is present in the row data
                valueGetter: (params) => {
                    const rates = params.data?.workTypePayRates;
                    if (rates && rates.length > 0) {
                        return rates
                            .map(
                                (rate) =>
                                    `${rate.contractUnionWorkTypeName || 'Unknown'}: $${rate.payRate.toFixed(2)}`
                            )
                            .join(', ');
                    }
                    return 'N/A';
                },
            },
        ];

        const dataSource = createDataSource(ApiRoutes.USCounties.groups(), gridOptions);

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    render() {
        if (this.state.loading) return (<Progress />);

        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <span>County Groups/Zones</span>
                </PageHeading>
                <DataGridToolbar
                    entity="county_group"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddCountyGroup}
                    addLabel="Add County Group"
                    hideExcelButton
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
            </PageWrap>
        );
    }
}
export default withRouter(USCountyGroupsIndex);
