import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import { SmallButton } from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

class AdminToolIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = { loading: true };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    getAdminTool = async () => {
        await this.setState({ loading: true });

        const [adminToolList] = await Promise.all([
            util.fetch.js(ApiRoutes.adminTool.search()),
        ]);

        await this.setState((state) => ({
            loading: false,
            toolList: adminToolList,
        }));
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        const tenant = await util.fetch.js(ApiRoutes.tenant.settings());

        if (isAuthenticated) {
            await this.setState({ loading: true });
            this.getAdminTool();
            if (tenant && tenant.id) {
                await this.setState({ tenantId: tenant.id });
            }
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label="Loading admin tools..."
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <div className="p-5">
                <h2>
                    Admin Tools
                </h2>

                <Link
                    component={SmallButton}
                    type="button"
                    className="mb-2"
                    to={`${AppNavPaths.Tenant}/${this.state.tenantId}`}
                >
                    <FontAwesomeIcon icon={faCogs} className="mr-2" />
                    Tenant Settings
                </Link>

                <div>
                    <ListGroup style={{ maxHeight: 'calc(100vh - 165px)', overflowY: 'auto' }}>
                        {this.state.toolList.map((item) => (
                            <Link
                                component={ListGroupItem}
                                tag="a"
                                to={`${AppNavPaths.AdminTool}/${item.id}`}
                                action
                                key={item.id}
                            >
                                <ListGroupItemHeading>
                                    {item.toolName}
                                </ListGroupItemHeading>

                                <ListGroupItemText className="mb-0">
                                    {item.description}
                                </ListGroupItemText>
                            </Link>
                        ))}
                    </ListGroup>
                </div>

            </div>
        );
    }
}

export default withRouter(AdminToolIndex);
