import * as moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Can } from '../../Can';
import CommonContext from '../../Common';
import { util } from '../../Util';
import {
    FlexEndRow,
    FlexRow,
    FlexStartRow,
    SmallButton,
    toasty,
} from '../forms/FormElements';
import {
    DataGridFilterClearButton,
    DataGridRefreshButton,
    DataGridExportExcelButton,
    getMappedFilterModel,
    ServerSideScrollRowsRequest,
} from './DataGrid';

export default class DataGridToolbar extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.state = {
            exporting: false,
        };

        this.processServerExport = this.processServerExport.bind(this);
    }

    processServerExport = async () => {
        const { exporting } = this.state;

        if (!exporting) {
            await this.setState({ exporting: true });
            try {
                const { serverExport, gridOptions } = { ...this.props };

                if (!serverExport) {
                    throw new Error('serverExport must be supplied.');
                }

                if (!gridOptions) {
                    throw new Error('gridOptions must be supplied.');
                }

                const { filePrefix, apiPath } = { ...serverExport };
                
                const filterModel = gridOptions.api.getFilterModel();
                const sortModel = gridOptions.api.columnModel.getColumnState();
                const filterModelMapped = getMappedFilterModel(
                    { ...filterModel },
                    { ...gridOptions },
                );
                const req = new ServerSideScrollRowsRequest();
                req.startRow = 0;
                req.endRow = 9999999;
                req.filterModel = { ...filterModelMapped };
                req.sortModel = Object.keys(sortModel).length
                    ? sortModel
                    : [];
                const fileName = `${filePrefix}_${moment().format(
                    'YYYY-M-D_hhmmss',
                )}.xlsx`;

                await util.fetch.downloadFile(apiPath, req, fileName);
            } catch (error) {
                toasty.error('There was a problem exporting the data.');
                console.error({ error });
            } finally {
                await this.setState({ exporting: false });
            }
        }
    };

    render() {
        const {
            mobileTitle,
            loading,
            gridApi,
            dataSource,
            hideExcelButton,
            hideAdd,
            hideClearFilterButton,
            entity,
            showBulk,
            serverExport,
            addIcon,
            addLabel,
            appendToExportFileName,
            serverExportDisabled
        } = this.props;

        const { exporting } = this.state;
        
        return (
            <Row className="datagrid-toolbar-row">
                <Col>
                    <FlexRow className="datagrid-toolbar-button-row">
                        <FlexStartRow className="datagrid-toolbar-button-row-left">
                            {!!mobileTitle && (
                                <strong className="datagrid-toolbar-mobile-title text-muted mr-2">
                                    {mobileTitle}
                                </strong>
                            )}
                            <DataGridRefreshButton
                                loading={loading}
                                gridApi={gridApi}
                                dataSource={dataSource}
                            />
                            {!hideClearFilterButton && (
                                <DataGridFilterClearButton
                                    loading={loading}
                                    gridApi={gridApi}
                                    dataSource={dataSource}
                                />
                            )}
                        </FlexStartRow>
                        <FlexEndRow className="datagrid-toolbar-button-row-right">
                            {!hideExcelButton && (
                                <DataGridExportExcelButton
                                    gridApi={gridApi}
                                    entity={entity}
                                    appendToExportFileName={
                                        appendToExportFileName
                                    }
                                />
                            )}

                            {showBulk && (
                                <CommonContext.Consumer>
                                    {(value) => (
                                        <SmallButton
                                            type="button"
                                            disabled={!!value.formIsOpen}
                                            onClick={this.props.onBulk}
                                        >
                                            <i className="fa fa-file-excel fa-md" />
                                            <span className="ml-2 small-viewport-hide">
                      Export Bulk
                                            </span>
                                        </SmallButton>
                                    )}
                                </CommonContext.Consumer>
                            )}

                            {!hideAdd && (
                                <Can I="create" a={entity}>
                                    <CommonContext.Consumer>
                                        {(value) => (
                                            <SmallButton
                                                type="button"
                                                disabled={
                                                    !!value.formIsOpen
                                                }
                                                onClick={this.props.onAdd}
                                            >
                                                <i
                                                    className={`fa fa-md ${
                                                        addIcon
                                                            ?? 'fa-plus-circle'
                                                    }`}
                                                />
                                                <span className="ml-2 small-viewport-hide">
                                                    {addLabel}
                                                </span>
                                            </SmallButton>
                                        )}
                                    </CommonContext.Consumer>
                                </Can>
                            )}
                            {!!serverExport && (
                                <SmallButton
                                    disabled={ serverExportDisabled || !!exporting}
                                    onClick={this.processServerExport}
                                >
                                    <i className="fa fa-file-excel fa-lg" />
                                    <span className="ml-2 small-viewport-hide">
                                        {exporting
                                            ? 'Exporting data...'
                                            : 'Export to Excel'}
                                    </span>
                                </SmallButton>
                            )}
                            {this.props.children}
                        </FlexEndRow>
                    </FlexRow>
                </Col>
            </Row>
        );
    }
}
