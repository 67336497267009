import { useCallback, useEffect, useState } from 'react';
import AddChargeTypeToTimesheetEquipment from './AddChargeTypeToTimesheetEquipment';
import AddJobCity from './AddJobCity';
import ImportCustomerData from './ImportCustomerData';
import MoveTimesheetToContract from './MoveTimesheetToContract';
import MoveTimesheetToJob from './MoveTimesheetToJob';
import RejectBillingPayroll from './RejectBillingPayroll';
import RemoveCharges from './RemoveCharges';
import SwapContractChargeType from './SwapContractChargeType';
import SyncTimesheetContractBilling from './SyncTimesheetContractBilling';
import { util } from '../Util';
import { ApiRoutes } from '../Common';
import GrantMultiTenantAccess from './GrantMultiTenantAccess';

export const AdminTools = ({ match }) => {
    let Component = 'div';

    const adminToolId = parseFloat(match.params.adminToolId);
    const [usageHistory, setUsageHistory] = useState([]);

    const refreshUsageHistory = useCallback(async () => {
        const history = await util.fetch.js(ApiRoutes.adminTool.getAdminToolHistory(adminToolId));

        setUsageHistory(history);
    }, [adminToolId]);

    useEffect(() => {
        if (adminToolId) {
            void refreshUsageHistory();
        }
    }, [adminToolId, refreshUsageHistory]);

    switch (adminToolId) {
        case 1:
            Component = MoveTimesheetToJob;
            break;
        case 4:
            Component = AddChargeTypeToTimesheetEquipment;
            break;
        case 5:
            Component = AddJobCity;
            break;
        case 6:
            Component = RejectBillingPayroll;
            break;
        case 7:
            Component = SwapContractChargeType;
            break;
        case 8:
            Component = SyncTimesheetContractBilling;
            break;
        case 9:
            Component = RemoveCharges;
            break;
        case 10:
            Component = MoveTimesheetToContract;
            break;
        case 11:
            Component = ImportCustomerData;
            break;
        case 12:
            Component = GrantMultiTenantAccess;
            break;
        default:
            break;
    }

    return (
        <Component
            key={adminToolId}
            usageHistory={usageHistory}
            refreshUsageHistory={refreshUsageHistory}
        />
    );
};