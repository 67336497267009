import React from 'react';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormLabel,
    onFieldChange,
    toasty,
    FormCheckbox,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ValidatedSelect from '../common/forms/ValidatedSelect';

class TenantViewModel {
    constructor() {
        this.id = 0;
        this.name = '';
        this.address1 = '';
        this.address2 = '';
        this.zip = '';
        this.state = 0;
        this.phone = '';
        this.city = '';

        this.code = '';
        this.active = 0;
    }
}

const AddressInput = ({ id, name, value, onChange, placeholder, label, required }) => (
    <FormGroup>
        <FormLabel
            htmlFor={id}
            text={label}
            required={required}
        />
        <Input
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type="text"
            required={required}
        />
    </FormGroup>
);

export default AddressInput;

export class ManageTenant extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const initialTenant = this.props.company ?? new TenantViewModel();

        this.state = {
            company: initialTenant,
            usStates: [],
            ...new BaseFormViewModel(),
        };

        this.onChange = this.onChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount() {
        const { show } = this.props;
        this.loadUSStates();
        if (show) {
            this.open();
        }
    }

    async loadUSStates() {
        try {
            const usStates = await util.fetch.js(ApiRoutes.typeAheads.USStates());
            this.setState({ usStates });
        } catch (error) {
            console.error('Failed to load US States:', error);
        }
    }

    onChange = onFieldChange;

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    onSubmit = async () => {
        const { company } = this.state;
        this.setState({ errors: {}, saving: true });

       

        const isUpdate = !!company.id && company.id > 0;

        const url = isUpdate
            ? ApiRoutes.tenant.byId(company.id)
            : ApiRoutes.tenant.create();
        const method = isUpdate ? util.fetch.put : util.fetch.post;

        const response = await method(url, company).catch(this.handleSaveError);

        if (response) {
            this.onClose();
            toasty.success(
                'Tenant Saved',
                `Tenant saved successfully.`
            );
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (companyId) => {
        this.setState({ loading: true });
        this.resetForm();

        let company;
        if (companyId) {
            company = await util.fetch.js(ApiRoutes.company.byId(companyId));
            company = {
                ...company,
                companyName: company.name,
            };
        } else {
            company = new TenantViewModel();
        }

        this.setState({ company }, () => {
            this.props.toggleShow(true);
            this.context.setFormOpened(true);

            this.setState({ loading: false });
        });
    };

    resetForm() {
        this.setState({
            formValidated: false,
        });
    }

    render() {
        const { formValidated, validationMessage, usStates, company } = this.state;

        const formTitle =
            !!parseInt(company.id ?? 0) > 0
                ? 'Edit Tenant'
                : 'Add Tenant';

        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id="companyForm"
                formIcon={faBuilding}
                formTitle={formTitle}
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                errors={this.state.errors}
                validationMessage={validationMessage}
            >

                <FormGroup>
                    <FormLabel
                        htmlFor="companyName"
                        text="Company Name"
                        required
                    />
                    <Input
                        id="companyName"
                        name="company.companyName"
                        value={company.companyName || ''}
                        onChange={this.onChange}
                        placeholder="Enter the company name"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
                        Company Name is required.
                    </ValidationErrorMessage>
                </FormGroup>


                <FormGroup>
                    <FormLabel
                        htmlFor="companyCode"
                        text="Company Code"
                        required
                    />
                    <Input
                        id="companyCode"
                        name="company.code"
                        value={company.code || ''}
                        onChange={this.onChange}
                        placeholder="Enter the company Code"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
                        Company Code is required.
                    </ValidationErrorMessage>
                </FormGroup>


                <AddressInput
                    id="companyAddress1"
                    name="company.address1"
                    value={company.address1 || ''}
                    onChange={this.onChange}
                    placeholder="Enter the address"
                    label="Address 1"
                    required
                />

                <AddressInput
                    id="companyAddress2"
                    name="company.address2"
                    value={company.address2 || ''}
                    onChange={this.onChange}
                    placeholder="Enter the address 2"
                    label="Address 2"
                />

                <FormGroup>
                    <FormLabel
                        htmlFor="companyZip"
                        text="Zip"
                        required
                    />
                    <Input
                        id="companyZip"
                        name="company.zip"
                        value={company.zip || ''}
                        onChange={this.onChange}
                        placeholder="Enter the zip code"
                        type="text"
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="state" text="State" required />
                    <ValidatedSelect
                        id="usStateId"
                        name="company.state"
                        required
                        options={usStates.map((state) => ({
                            value: state.value,
                            label: state.label,
                        }))}
                        value={usStates.find((s) => s.value === company.state) ?? null}
                        onChange={(selectedOption) => {
                            console.log('Selected State:', selectedOption);
                            this.setState((prevState) => ({
                                company: {
                                    ...prevState.company,
                                    state: selectedOption?.value,
                                },
                            }));
                        }}
                        validationMessage="A state selection is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="companyPhone"
                        text="Phone"
                    />
                    <Input
                        id="companyPhone"
                        name="company.phone"
                        value={company.phone || ''}
                        onChange={this.onChange}
                        placeholder="Enter the phone number"
                        type="tel"
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="companyCity"
                        text="City"
                    />
                    <Input
                        id="companyCity"
                        name="company.city"
                        value={company.city || ''}
                        onChange={this.onChange}
                        placeholder="Enter the city"
                        type="text"
                    />
                </FormGroup>

                <FormGroup>
                    <FormCheckbox
                        id="isCompanyActive"
                        name="company.active"
                        type="checkbox"
                        checked={company.active}
                        onChange={this.onChange}
                        labelText="Active"
                    />
                </FormGroup>
            </SlideForm>
        );
    }
}
