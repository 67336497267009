import React, { useState, useCallback } from 'react';
import { Button, Table, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import NumericInput from '../common/forms/NumericInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormLabel, SmallButton } from '../common/forms/FormElements';

export const EquipmentTypePayrollIncentiveGrid = ({
    incentives: initialIncentives,
    userGroups,
    onIncentivesChange,
    usStates,
}) => {
    const [incentives, setIncentives] = useState(initialIncentives || []);
    const [showModal, setShowModal] = useState(false);
    const [newIncentive, setNewIncentive] = useState({ amount: 0, applicationUserGroupId: null, usStateId: null });

    const handleSubmit = useCallback(() => {
        const updatedIncentives = [...incentives, { ...newIncentive, id: null, editing: false }];

        setIncentives(updatedIncentives);
        setNewIncentive({ amount: 0, applicationUserGroupId: null, usStateId: null }); // Reset
        setShowModal(false); // Close modal

        onIncentivesChange(updatedIncentives);
    }, [incentives, newIncentive, onIncentivesChange]);

    const handleEditIncentiveButtonClick = useCallback((index) => {
        const updated = [...incentives];
        updated[index].editing = true;

        setIncentives(updated);
    }, [incentives]);

    const handleRemoveIncentiveButtonClick = useCallback((index) => {
        const updated = incentives.filter((_, i) => i !== index);
        setIncentives(updated);
        onIncentivesChange(updated);
    }, [incentives, onIncentivesChange]);

    const handleUpdateIncentive = useCallback((index, field, value) => {
        const updated = [...incentives];
        updated[index][field] = value;
        setIncentives(updated);
    }, [incentives]);

    return (
        <div>
            <div className="d-flex justify-content-end mb-1">
                <SmallButton
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    <FontAwesomeIcon icon={faPlusCircle} size="md" className="mr-2" />
                    Add Incentive
                </SmallButton>
            </div>

            <Table className="table table-sm" bordered responsive>
                <thead>
                    <tr>
                        <th>Adjustment</th>
                        <th>User Group</th>
                        <th>State</th>
                        <th className="text-center"><FontAwesomeIcon icon={faEdit} /></th>
                        <th className="text-center"><FontAwesomeIcon icon={faTrash} /></th>
                    </tr>
                </thead>
                <tbody>
                    {incentives.length === 0 ? (
                        <tr>
                            <td colSpan={5} className="text-center">
                                No incentives found.
                            </td>
                        </tr>
                    ) : (
                        incentives.map((incentive, index) => (
                            <tr key={incentive.id}>
                                {incentive.editing ? (
                                    <>
                                        <td>
                                            <FormGroup>
                                                <NumericInput
                                                    className="form-control text-right"
                                                    name="amount"
                                                    required
                                                    value={incentive.amount}
                                                    onChange={(e) =>
                                                        handleUpdateIncentive(index, 'amount', parseFloat(e.target.value))
                                                    }
                                                    type="number"
                                                    min="0"
                                                    step="0.01"
                                                />
                                            </FormGroup>
                                        </td>
                                        <td>
                                            {
                                                userGroups.find(
                                                    (group) => group.value === incentive.applicationUserGroupId
                                                )?.label || 'No Group'
                                            }
                                        </td>
                                        <td>
                                            {
                                                usStates.find(
                                                    (state) => state.id === incentive.usStateId
                                                )?.label || 'No state override'
                                            }
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{incentive.amount.toFixed(2)}</td>
                                        <td>
                                            {
                                                userGroups.find(
                                                    (group) => group.value === incentive.applicationUserGroupId
                                                )?.label || 'No Group'
                                            }
                                        </td>
                                        <td>
                                            {
                                                usStates.find(
                                                    (state) => state.id === incentive.usStateId
                                                )?.label || 'No state override'
                                            }
                                        </td>
                                    </>
                                )}
                                <td className="text-center" style={{ width: '30px' }}>
                                    <Button
                                        type="button"
                                        color="outline-primary"
                                        size="sm"
                                        onClick={() => handleEditIncentiveButtonClick(index)}
                                    >
                                        Edit
                                    </Button>
                                </td>
                                <td className="text-center" style={{ width: '30px' }}>
                                    <Button
                                        type="button"
                                        color="outline-primary"
                                        onClick={() => handleRemoveIncentiveButtonClick(index)}
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>

            {/* Modal for Adding Incentives */}
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>
                    Add Incentive
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <FormLabel
                            htmlFor="newAmount"
                            text="Adjustment"
                            required
                        />
                        <NumericInput
                            className="form-control"
                            id="newAmount"
                            name="newAmount"
                            value={newIncentive.amount}
                            onChange={(e) => setNewIncentive({ ...newIncentive, amount: parseFloat(e.target.value) })}
                            type="number"
                            min="0"
                            step="0.01"
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            htmlFor="newUserGroup"
                            text="User Group"
                        />
                        <ValidatedSelect
                            id="newUserGroup"
                            options={userGroups}
                            value={userGroups.find((x) => x.value === newIncentive.applicationUserGroupId)}
                            onChange={(selected) =>
                                setNewIncentive({
                                    ...newIncentive,
                                    applicationUserGroupId: selected ? selected.value : null,
                                })
                            }
                            isClearable
                            validationMessage="Select a valid user group or leave empty."
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel
                            htmlFor="usState"
                            text="State/Province Override"
                        />
                        <ValidatedSelect
                            id="usState"
                            options={usStates}
                            value={usStates.find((x) => x.id === newIncentive.usStateId)}
                            isClearable
                            validationMessage="Select a state or leave empty."
                            onChange={(selected) =>
                                setNewIncentive({
                                    ...newIncentive,
                                    usStateId: selected ? selected.id : null,
                                })
                            }
                        />
                    </FormGroup>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} className="mr-1">
                        Save
                    </Button>

                    <Button color="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
