import React from 'react';
import { Button, Col, Collapse, FormGroup, Input, Progress } from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { toast } from 'react-toastify';
import {
    faSave,
    faFileInvoiceDollar,
    faCheckCircle,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonContext, { ApiRoutes, AppNavPaths, ServiceResponseResult } from '../Common';
import {
    AppPageForm,
    FlexCenterRow,
    FormCheckbox,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    onFieldChange,
    onReactSelectChanged,
    SubHeading,
    ToastMessage,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';
import AsyncSelect from 'react-select/async';
import NumericInput from '../common/forms/NumericInput';

class USCountyGroup {
    id = null;
    description = '';
    groupType = null;
    usStateId = null;
    usCountyGroupTypeId = null;
    counties = [];
    contractUnionWorkTypes = [];
    workTypePayRates = [];
    useCountyGroupPayRate = false;
    contractScheduleTypeId = 1;
    hasUnionPayrollSchedule = false;
    alwaysHasSaturdayOvertime = false;
}

class USCountyGroupForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            originalData: new USCountyGroup(),
            countyGroup: new USCountyGroup(),
            usStates: [],
            groupTypes: [],
            counties: [],
            contractUnionWorkTypes: [],
            contractScheduleTypes: [],
            loading: true,
            saving: false,
        };

        this.handlers.change = this.handlers.change.bind(this);
        this.handlers.select = this.handlers.select.bind(this);
    }

    componentDidMount() {
        return this.populateState();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps
            && this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    }

    onClearErrors = () => this.setState((state) => ({ errors: {} }));

    onSubmit = (e) => {
        this.onClearErrors();
        this.setSaving(true);

        const cloned = util.object.clone(this.state.countyGroup);

        // Ensure `workTypePayRates` includes `contractUnionWorkTypeId`
        cloned.workTypePayRates = (this.state.countyGroup.workTypePayRates || []).map((rate, index) => {
            const workType = this.state.countyGroup.contractUnionWorkTypes[index];
            return {
                contractUnionWorkTypeId: workType?.id,
                payRate: rate.payRate,
                usCountyGroupId: this.state.countyGroup.id || 0,
            };
        });

        this.props.location.pathname == AppNavPaths.USCountyGroupNew
            ? this.create(cloned)
            : this.update(cloned);
    };

    setSaving = (b) => this.setState({ saving: b });

    create = async (countyGroup) => {
        countyGroup.id = 0;

        const response = await util.fetch
            .post(ApiRoutes.USCounties.group(), countyGroup)
            .catch(this.handleSaveError);

        if (response) {
            if (response.result === ServiceResponseResult.Ok) {
                toast.success(
                    <ToastMessage
                        icon={faCheckCircle}
                        header="Save Successful"
                        message="Saved."
                    />,
                );
                util.navigation.localRedirect(
                    this,
                    `${AppNavPaths.USCountyGroup}/${response.data}`,
                );
            } else {
                toast.error(<ToastMessage
                    icon={faExclamationTriangle}
                    header="There was a problem saving"
                    message={`${response.message}`}
                />);
            }
        }
        this.setSaving(false);
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    handlers = {
        change: onFieldChange,
        select: onReactSelectChanged,
    };

    async populateState() {
        const { id } = this.props.match.params;

        const [groupTypes, usStates, contractUnionWorkTypes, countyGroup, contractScheduleTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.USCounties.groupTypes()),
            util.fetch.js(ApiRoutes.typeAheads.USStates()),
            util.fetch.js(ApiRoutes.typeAheads.contractUnionWorkTypes()),
            id ? util.fetch.js(ApiRoutes.USCounties.byGroupId(id)) : new USCountyGroup(),
            util.fetch
                .js(ApiRoutes.typeAheads.contractScheduleTypes())
                .catch((error) => console.log('Unable to get contract schedule types.')),
        ]);

        this.setState({
            originalData: { ...countyGroup },
            usStates: usStates.map((x) => ({ label: x.label, value: x.id })),
            groupTypes: groupTypes.map((x) => ({ label: x.description, value: x.id })),
            contractUnionWorkTypes: contractUnionWorkTypes.filter(x => x.isUnionWork),
            contractScheduleTypes,
            countyGroup: {
                ...countyGroup,
                workTypePayRates: this.initializePayRates(
                    countyGroup.contractUnionWorkTypes,
                    countyGroup.workTypePayRates
                ),
            },
            loading: false,
            saving: false,
        });
    }

    update = async (countyGroup) => {
        const response = await util.fetch
            .put(ApiRoutes.USCounties.byGroupId(countyGroup.id), countyGroup)
            .catch(this.handleSaveError);

        if (response) {
            if (response.result === ServiceResponseResult.Ok) {

                this.setState({
                    saving: false,
                    formValidated: false,
                    originalData: countyGroup,
                });

                toast.success(
                    <ToastMessage
                        icon={faCheckCircle}
                        header="Save Successful"
                        message="County Group saved."
                    />,
                );
            } else {
                toast.error(<ToastMessage
                    icon={faExclamationTriangle}
                    header="There was a problem saving"
                    message={`${response.message}`}
                />);
            }
        }
        this.setSaving(false);
    };

    onCountyChange = (value) => {
        let { countyGroup } = { ...this.state };

        countyGroup.counties = value;

        this.setState({ countyGroup });
    };

    normalizeOptions = (options, labelKey, valueKey) =>
        options.map((option) => ({ label: option[labelKey], value: option[valueKey] }));

    initializePayRates = (workTypes = [], existingRates = []) =>
        workTypes.map((workType) => {
            const existingRate = existingRates.find(
                (rate) => rate.contractUnionWorkTypeId === workType.id
            );
            return existingRate || { contractUnionWorkTypeId: workType.id, payRate: 0 };
        });

    handleWorkTypeChange = (selectedWorkTypes) => {
        this.setState((prevState) => ({
            countyGroup: {
                ...prevState.countyGroup,
                contractUnionWorkTypes: selectedWorkTypes,
                workTypePayRates: this.initializePayRates(
                    selectedWorkTypes,
                    prevState.countyGroup.workTypePayRates
                ),
            },
        }));
    };

    handlePayRateChange = (contractUnionWorkTypeId, value) => {
        this.setState((prevState) => ({
            countyGroup: {
                ...prevState.countyGroup,
                workTypePayRates: prevState.countyGroup.workTypePayRates.map((rate) =>
                    rate.contractUnionWorkTypeId === contractUnionWorkTypeId
                        ? { ...rate, payRate: parseFloat(value) || 0 }
                        : rate
                ),
            },
        }));
    };

    loadOptions = (searchString, callback) => {
        util.fetch.post(ApiRoutes.USCounties.all(), { searchString })
            .then((data) => callback(data));
    };

    renderForm() {
        const {
            groupTypes,
            countyGroup,
            originalData,
            saving,
            errors,
            loading,
            formValidated,
            usStates,
            contractUnionWorkTypes,
            contractScheduleTypes,
        } = this.state;

        if (!countyGroup) {
            return '';
        }

        const newCountyGroup = parseInt(countyGroup.id ?? 0) <= 0;

        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, countyGroup)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId="countyGroupForm"
                    formHeadingIcon={faFileInvoiceDollar}
                    formHeading={
                        newCountyGroup
                            ? 'New County Group'
                            : 'Edit County Group'
                    }
                    formName="countyGroupForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    loading={loading}
                >
                    <SubHeading first>Details</SubHeading>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="description"
                                    text="County Group Name"
                                    required
                                />
                                <Input
                                    id="description"
                                    name="countyGroup.description"
                                    value={countyGroup.description ?? ''}
                                    onChange={this.handlers.change}
                                    placeholder="Enter Name"
                                    type="text"
                                    required
                                />
                                <ValidationErrorMessage>
                                    Name is required.
                                </ValidationErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="usCountyGroupTypeId"
                                    text="Group Type"
                                    required
                                />
                                <ValidatedSelect
                                    id="usCountyGroupTypeId"
                                    name="countyGroup.usCountyGroupTypeId"
                                    required
                                    options={groupTypes}
                                    value={
                                        (groupTypes ?? []).find(
                                            (s) => s.value
                                                === countyGroup.usCountyGroupTypeId,
                                        ) ?? ''
                                    }
                                    onChange={this.handlers.select.bind(this)}
                                    validationMessage="A Group Type selection is required."
                                />
                            </FormGroup>

                            <Collapse isOpen={countyGroup.usCountyGroupTypeId === 2}>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="localNumber"
                                        text="Local number"
                                        required
                                    />
                                    <Input
                                        id="localNumber"
                                        name="countyGroup.localNumber"
                                        value={countyGroup.localNumber ?? ''}
                                        onChange={this.handlers.change}
                                        type="text"
                                        required={countyGroup.usCountyGroupTypeId === 2}
                                    />
                                    <ValidationErrorMessage>
                                        A local number is required.
                                    </ValidationErrorMessage>
                                </FormGroup>
                            </Collapse>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="contractUnionWorkTypes"
                                    text="Applicable Union Work Type(s)"
                                    required
                                />
                                <ValidatedSelect
                                    id="contractUnionWorkTypes"
                                    name="contractUnionWorkTypes"
                                    isMulti
                                    required
                                    options={contractUnionWorkTypes}
                                    value={countyGroup.contractUnionWorkTypes ?? ''}
                                    getOptionLabel={(option) => option.description ?? option.name}
                                    getOptionValue={(option) => option.id ?? option.value}
                                    onChange={this.handleWorkTypeChange}
                                    validationMessage="At least one work type is required."
                                />
                            </FormGroup>
                        </FormGroupColumn>

                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel htmlFor="state" text="State" required />
                                <ValidatedSelect
                                    id="usStateId"
                                    name="countyGroup.usStateId"
                                    required
                                    options={usStates}
                                    value={usStates.find((s) => s.value === countyGroup.usStateId) ?? ''}
                                    onChange={this.handlers.select.bind(this)}
                                    validationMessage="A state selection is required."
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel
                                    htmlFor="counties"
                                    text="Counties"
                                    required
                                />
                                <ValidatedSelect
                                    id="counties"
                                    name="counties"
                                    component={AsyncSelect}
                                    isMulti
                                    required
                                    loadOptions={this.loadOptions}
                                    value={countyGroup.counties ?? ''}
                                    getOptionLabel={(option) => `${option.name} (${option.usState.name})`}
                                    getOptionValue={(option) => option.id}
                                    onChange={this.onCountyChange}
                                    placeholder="Start typing a county…"
                                    noOptionsMessage={() => 'Start typing a county…'} 
                                    validationMessage="At least one group selection is required."
                                />
                            </FormGroup>
                        </FormGroupColumn>
                    </GroupedRow>

                    <Collapse isOpen={countyGroup.usCountyGroupTypeId === 2 && countyGroup.contractUnionWorkTypes?.length > 0}>
                        <SubHeading>Pay Rates</SubHeading>
                        <GroupedRow className="justify-content-center">
                            <Col xs={9}>
                                <FormGroup>
                                    <FormCheckbox
                                        id="countyGroup.useCountyGroupPayRate"
                                        name="countyGroup.useCountyGroupPayRate"
                                        checked={countyGroup.useCountyGroupPayRate || false}
                                        labelText="Employees in this local will use these pay rates when dispatched to jobs instead of the job location pay rates"
                                        onChange={this.handlers.change}
                                    />
                                </FormGroup>

                                {(countyGroup.contractUnionWorkTypes || []).map((workType) => {
                                    const payRate = (countyGroup.workTypePayRates || []).find(
                                        (rate) => rate.contractUnionWorkTypeId === workType.id
                                    ) || { payRate: 0 };

                                    return (
                                        <FormGroup key={workType.id}>
                                            <FormLabel
                                                htmlFor={`payRate-${workType.id}`}
                                                text={`Pay Rate for ${workType.description || workType.name}`}
                                                required
                                            />
                                            <NumericInput
                                                id={`payRate-${workType.id}`}
                                                name={`payRate-${workType.id}`}
                                                value={payRate?.payRate || 0}
                                                onChange={(e) =>
                                                    this.handlePayRateChange(workType.id, e.target.value)
                                                }
                                                type="number"
                                                step="0.01"
                                                placeholder="Enter Pay Rate"
                                                required
                                            />
                                        </FormGroup>
                                    );
                                })}
                            </Col>
                        </GroupedRow>

                        <SubHeading>Pay Schedule</SubHeading>
                        <GroupedRow className="justify-content-center">
                            <Col xs={9}>
                                <FormGroup>
                                    <FormCheckbox
                                        id="countyGroup.alwaysHasSaturdayOvertime"
                                        name="countyGroup.alwaysHasSaturdayOvertime"
                                        checked={countyGroup.alwaysHasSaturdayOvertime || false}
                                        labelText="Employees working in this local always receive Saturday OT"
                                        onChange={this.handlers.change}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormCheckbox
                                        id="countyGroup.hasUnionPayrollSchedule"
                                        name="countyGroup.hasUnionPayrollSchedule"
                                        checked={countyGroup.hasUnionPayrollSchedule || false}
                                        labelText="This local has its own OT schedule that overrides contracts"
                                        onChange={this.handlers.change}
                                    />
                                </FormGroup>

                                <Collapse isOpen={countyGroup.hasUnionPayrollSchedule}>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="contractScheduleTypeId"
                                            text="Schedule"
                                            required
                                        />
                                        <ValidatedSelect
                                            id="contractScheduleTypeId"
                                            name="countyGroup.contractScheduleTypeId"
                                            options={contractScheduleTypes}
                                            required
                                            value={(
                                                contractScheduleTypes ?? []
                                            ).find(
                                                (x) => countyGroup.contractScheduleTypeId
                                                    === x.value,
                                            ) ?? ''}
                                            onChange={
                                                this.handlers.select
                                            }
                                        />
                                    </FormGroup>
                                </Collapse>
                            </Col>
                        </GroupedRow>
                    </Collapse>
                    <FlexCenterRow className="mb-3">
                        <Button
                            size="sm"
                            type="submit"
                            color="primary"
                            name="countyGroupForm"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faSave} />
                            {newCountyGroup
                                ? 'Save New County Group'
                                : 'Save'}
                        </Button>
                    </FlexCenterRow>
                </AppPageForm >
            </>
        );
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return <Progress />;
        }

        return this.renderForm();
    }
}
export default withRouter(USCountyGroupForm);
