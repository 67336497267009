import {
    faPencilAlt,
    faCheck,
    faTruck,
    faToolbox,
    faCommentDots,
    faCheckCircle,
    faExclamationTriangle,
    faCircleNotch,
    faComments,
    faHardHat,
    faUser,
    faEyeLowVision, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Container,
    FormGroup,
    Popover,
    PopoverBody,
    PopoverHeader,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from 'reactstrap';
import cls from 'classnames';
import CommonContext, { ApiRoutes, LocalizationKeys as l } from '../Common';
import {
    CollapseUnderlineHeader,
    FlexColumnCenter,
    FlexBetweenRow,
    FlexColumnStart,
    FlexCenterRow,
    FlexEndRow,
    FlexStartRow,
    FormLabel,
    SmallButton,
    toasty,
} from '../common/forms/FormElements';

import { util } from '../Util';
import './TimesheetForm.scss';
import TimesheetItem from './TimesheetItem';
import ClientReview from './ClientReview';
import EmployeeReview from './EmployeeReview';
import authService from '../api-authorization/AuthorizeService';
import { RejectionNote, ClientCancelled, TimesheetStatus } from './Timesheet';
import { JobDetails } from '../job/Job';
import {
    ContractTimesheetResponsibilityType,
    TimesheetResponsibilityType,
} from '../tenant/Tenant';
import { CountyLabel } from '../uscounties/CountyLabel';
import FileList from '../common/forms/FileList';

export const ConfirmationStatus = {
    Unfilled: 1,
    Scheduled: 2,
    NoResponse: 3,
    Confirmed: 4,
    CancelPending: 5,
    ThresholdCancel: 6,
};

class TimesheetForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.timesheetItemRef = React.createRef();
        this.timesheetClientReviewRef = React.createRef();
        this.timesheetEmployeeReviewRef = React.createRef();

        this.state = {
            assignment: null,
            confirmedAssignments: [],
            invalidChargeTypeIds: [],
            jobDetailsOpen: false,
            loading: true,
            notFound: false,
            timesheetOpen: false,
            timesheetClientReviewOpen: false,
            timesheet: {},
            timesheetId: null,
            showLinkedTimesheetModal: false,
            isSavingLinkedTimesheet: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onAddTimesheet = async () => {
        const { timesheet } = this.state;
        this.setState({ isSavingLinkedTimesheet: true });

        const parentTimesheet = timesheet.timesheets.find(
            (x) => x.linkedTimesheetId == null,
        );
        const result = await util.fetch.post(
            ApiRoutes.timesheet.createLinkedTimesheet(parentTimesheet.id),
        );

        if (result) {
            toasty.success('Timesheet added.');
        } else {
            let error = 'Error adding timesheet.';
            if ((result ?? {}).error) {
                error = result.error;
            }
            toasty.error(error);
        }

        this.setState({
            isSavingLinkedTimesheet: false,
            showLinkedTimesheetModal: false,
        });
        await this.refresh();
    };

    onTimesheetClientReviewClosed = async () => {
        await this.context.setFormOpened(false);

        await this.refresh();
    };

    onTimesheetEmployeeReviewClosed = async () => {
        await this.context.setFormOpened(false);

        await this.refresh();
    };

    onTimesheetItemClosed = async () => {
        await this.context.setFormOpened(false);

        await this.refresh();
    };

    async openTimesheet(id) {
        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetItemRef.current.open(id);
        }
    }

    async openTimesheetClientReview(id) {
    // This should only open up once all billable charges on TS have time or NA.
    // It can also be opened up to view once its been filled out to review if needed(basically same as above).

        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetClientReviewRef.current.open(id);
        }
    }

    async openTimesheetEmployeeReview(id) {
        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetEmployeeReviewRef.current.open(id);
        }
    }

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            const timesheetId = this.props.match.params.id;
            const assignmentId = this.props.match.params.assignmentId || 0;
            const strings = await util.l10n.getStrings([l.crewLead]);
            this.setState({ strings });

            if (!timesheetId) {
                this.setState({ notFound: true, loading: false });
                return;
            }

            // Timesheet
            const timesheet = await util.fetch.js(
                ApiRoutes.timesheet.overview(timesheetId),
            );
            if (!timesheet) {
                this.setState({ notFound: true, loading: false });
                return;
            }

            timesheet.clientReviewPopoverOpen = false;
            timesheet.crewReviewPopoverOpen = false;

            // Assignment
            let enforceCrewLeadTS = false;
            let assignment = null;

            if (assignmentId > 0) {
                assignment = await util.fetch.js(
                    ApiRoutes.jobAssignment.byId(assignmentId),
                );
                if (assignment) {
                    enforceCrewLeadTS = this.context.tenant.tenantSettings
                        .timesheetResponsibilityType
                            === TimesheetResponsibilityType.CrewLead
                        || (this.context.tenant.tenantSettings
                            .timesheetResponsibilityType
                            === TimesheetResponsibilityType.Contract
                            && assignment.contractTimesheetResponsibilityTypeId
                                === ContractTimesheetResponsibilityType.CrewLead);
                }
            }

            this.setState({
                timesheet,
                assignment,
                jobDetailsOpen: true,
                timesheetId,
                loading: false,
                enforceCrewLeadTimesheet: enforceCrewLeadTS,
            });
        }
    };

    refresh = async () => {
        const { timesheetId } = this.state;
        const timesheet = await util.fetch.js(
            ApiRoutes.timesheet.overview(timesheetId),
        );

        if (!timesheet) this.setState({ notFound: true, loading: false });

        // create review items for the signature grids

        this.setState({
            timesheet,
            jobDetailsOpen: true,
            loading: false,
        });
    };

    toggleLinkedTimesheetModal = () => {
        const { showLinkedTimesheetModal } = this.state;
        this.setState({ showLinkedTimesheetModal: !showLinkedTimesheetModal });
    };

    render() {
        const {
            timesheet,
            assignment,
            strings,
            confirmedAssignments,
            jobDetailsOpen,
            loading,
            notFound,
            showLinkedTimesheetModal,
            isSavingLinkedTimesheet,
        } = { ...this.state };

        const confirmed = (assignment ?? {}).confirmationStatusId
            === ConfirmationStatus.Confirmed;
        const crewLead = strings ? strings[l.crewLead] : '';

        if (!!loading || !(this.context.tenant ?? {}).tenantSettings) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <Spinner />
                </div>
            );
        }
        const { tenantSettings } = { ...this.context.tenant };

        const allEquipment = assignment?.equipment ?? [];

        // What equipment does the confirming employee own of this list?
        const ownedEquipment = allEquipment.filter(
            (x) => x.equipmentEmployeeAssignmentId
                    === assignment.assignedEmployeeId,
        );
        const ownedEquipmentIds = ownedEquipment.map(
            (x) => x.assignedEquipmentId,
        );

        // Of the equipment, which have been assigned to any of the equipment owned by the confirming employee?
        const dependentEquipment = allEquipment.filter((x) => ownedEquipmentIds.includes(x.equipmentAssignedEquipmentId));
        const dependentEquipmentIds = dependentEquipment.map(
            (x) => x.assignedEquipmentId,
        );

        const showContractDescriptionInTimesheetView = Boolean(
            tenantSettings
                    && tenantSettings.showContractDescriptionInTimesheetView,
        );

        const jobDetailsHeader = showContractDescriptionInTimesheetView
                    && timesheet
                    && timesheet.contractDescription
            ? `Job Details - ${timesheet.contractDescription}`
            : 'Job Details';

        return (
            <>
                <div
                    className={cls('confirmationContainer h-100 pt-2', {
                        'site-form-disabled': !!this.context.formIsOpen,
                    })}
                >
                    <Container size="sm">
                        {!!notFound && (
                            <h4 className="text-center">
                Unable to locate the requested resource.
                            </h4>
                        )}
                        {!!timesheet && (
                            <>
                                {(timesheet ?? {}).isClientCancelled && (
                                    <FlexColumnCenter className="pb-3">
                                        <ClientCancelled />
                                    </FlexColumnCenter>
                                )}
                                {(timesheet ?? {}).timesheetStatusId
                                        === TimesheetStatus.Rejected && (
                                    <FlexColumnCenter className="pb-3">
                                        <RejectionNote
                                            note={
                                                timesheet.lastRejectionNote
                                            }
                                        />
                                    </FlexColumnCenter>
                                )}
                                <CollapseUnderlineHeader
                                    headerText={jobDetailsHeader}
                                    isOpen={jobDetailsOpen}
                                    toggleCollapse={() => this.setState({
                                        jobDetailsOpen: !jobDetailsOpen,
                                    })}
                                >
                                    <JobDetails
                                        customerName={timesheet?.customerName}
                                        subcontractorName={timesheet?.subcontractorName}
                                        foremanName={timesheet?.foremanName}
                                        foremanPhone={timesheet?.foremanPhone}
                                        jobNotes={timesheet?.jobNotes}
                                        jobNumber={timesheet?.jobNumber}
                                        jobStartTime={timesheet?.jobStartTime}
                                        lastRejectionNote={timesheet?.lastRejectionNote}
                                        workOrderNumber={timesheet?.workOrderNumber}
                                        isEmergency={timesheet?.isEmergency}
                                        isTimesheetForm
                                        isTimesheet
                                        isPayroll={false}
                                        isBilling={false}
                                        tenantSettings={tenantSettings}
                                        jobName={timesheet?.contractDescription}
                                    />

                                    <div className="locationDetails">
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="meetingLocation"
                                                text="Meeting Location"
                                                className="bottom-border"
                                            />
                                            <FlexStartRow>
                                                {Boolean(timesheet?.meetingLocationUrlFormatted) && (
                                                    <a
                                                        rel="noopener noreferrer"
                                                        className="text-white mr-2 btn btn-sm btn-success p-1"
                                                        title="Open in Google Maps"
                                                        href={util.getMapUrl(timesheet.meetingLocationUrlFormatted)}
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-map-marked-alt" />
                                                    </a>
                                                )}
                                                <span
                                                    id="meetingLocation"
                                                    name="meetingLocation"
                                                >
                                                    {timesheet.meetingLocation ?? '-'}
                                                </span>
                                            </FlexStartRow>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="jobLocation"
                                                text="Job Location"
                                                className="bottom-border"
                                            />
                                            <FlexStartRow>
                                                {Boolean(timesheet?.jobLocationUrlFormatted) && (
                                                    <a
                                                        rel="noopener noreferrer"
                                                        className="text-white mr-2 btn btn-sm btn-success p-1"
                                                        title="Open in Google Maps"
                                                        href={util.getMapUrl(timesheet.jobLocationUrlFormatted)}
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-map-marked-alt" />
                                                    </a>
                                                )}
                                                <span
                                                    id="jobLocation"
                                                    name="jobLocation"
                                                >
                                                    {timesheet.jobLocation ?? '-'}
                                                </span>
                                            </FlexStartRow>
                                        </FormGroup>

                                        {Boolean(timesheet?.usCounty) && (
                                            <FormGroup>
                                                <FormLabel
                                                    htmlFor="usCounty"
                                                    text="County"
                                                    className="bottom-border"
                                                />
                                                <FlexStartRow>
                                                    <div>
                                                        <CountyLabel {...timesheet.usCounty} />
                                                    </div>
                                                </FlexStartRow>
                                            </FormGroup>    
                                        )}
                                    </div>
                                </CollapseUnderlineHeader>
                                {!!tenantSettings.jobUploadsEnabled
                                            && !!(timesheet.jobUploads ?? [])
                                                .length && (
                                    <div className="mt-3">
                                        <FormGroup>
                                            <FormLabel
                                                text="Job Attachments"
                                                className="bottom-border"
                                            />
                                            <FileList jobId={timesheet.jobId} files={timesheet.jobUploads ?? []} />
                                        </FormGroup>
                                
                                    </div>
                                )}

                                {assignment != null
                                        && assignment.confirmationStatusId
                                            !== 5 && (
                                    <div className="crewAndEquipmentDetails">
                                        <FormGroup>
                                            <FormLabel
                                                text="Crew And Equipment"
                                                className="bottom-border"
                                            />
                                        </FormGroup>
                                        {!!confirmed
                                                    && !!assignment.crewSMS && (
                                            <FlexCenterRow>
                                                <a
                                                    className="mb-2 p-1 btn btn-success btn-sm groupSmsLink text-white"
                                                    href={`sms:${assignment.crewSMS}`}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faComments
                                                        }
                                                        size="lg"
                                                        className="mr-2"
                                                    />
                                                        Send Group SMS
                                                        to Crew
                                                </a>
                                            </FlexCenterRow>
                                        )}

                                        <div className="crewAndEquipmentDetails table-responsive">
                                            <table className="table table-sm assignmentsTable">
                                                <tbody>
                                                    {!!assignment.crew && (
                                                        <>
                                                            {assignment.crew.map(
                                                                (c) => {
                                                                    const ownedAssignment = c.assignedEmployeeId
                                                                                === assignment.assignedEmployeeId;

                                                                    return (
                                                                        <tr
                                                                            className={cls(
                                                                                'assignmentRow',
                                                                                {
                                                                                    owned: !!ownedAssignment,
                                                                                },
                                                                            )}
                                                                            key={
                                                                                c.id
                                                                            }
                                                                        >
                                                                            <td className="iconCell">
                                                                                <FontAwesomeIcon
                                                                                    className={cls(
                                                                                        {
                                                                                            'text-success font-weight-bold':
                                                                                                        !!ownedAssignment,
                                                                                            'text-muted':
                                                                                                        !ownedAssignment,
                                                                                            'fa-hard-hat-golden':
                                                                                                        !!c.isCrewLead,
                                                                                        },
                                                                                    )}
                                                                                    icon={
                                                                                        c.isCrewLead
                                                                                            ? faHardHat
                                                                                            : faUser
                                                                                    }
                                                                                    title={
                                                                                        c.isCrewLead
                                                                                            ? crewLead
                                                                                            : c.assignedEmployeeGroup
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <FlexBetweenRow>
                                                                                    <small
                                                                                        className={cls(
                                                                                            {
                                                                                                'text-success font-weight-bold':
                                                                                                            c.assignedEmployeeId
                                                                                                            === assignment.assignedEmployeeId,
                                                                                            },
                                                                                        )}
                                                                                    >
                                                                                        {`${
                                                                                            c.assignedEmployeeName
                                                                                        } ${
                                                                                            c.isCrewLead
                                                                                                ? `(${crewLead})`
                                                                                                : ''
                                                                                        }`}
                                                                                    </small>
                                                                                    {!!confirmed
                                                                                                && !!c.assignedEmployeePhone && (
                                                                                        <a
                                                                                            title={`Send SMS to ${c.assignedEmployeeName}`}
                                                                                            className="ml-2 p-1 btn btn-sm btn-success"
                                                                                            href={`sms://+1${c.assignedEmployeePhone}`}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faCommentDots
                                                                                                }
                                                                                                size="lg"
                                                                                            />
                                                                                        </a>
                                                                                    )}
                                                                                </FlexBetweenRow>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {!confirmed
                                                                                            && c.confirmationStatusId
                                                                                                === ConfirmationStatus.Confirmed && (
                                                                                    <FontAwesomeIcon
                                                                                        style={{
                                                                                            opacity:
                                                                                                            ownedAssignment
                                                                                                                ? 1
                                                                                                                : 0.5,
                                                                                        }}
                                                                                        size="lg"
                                                                                        title="Employee confirmed"
                                                                                        className="icon-check checked"
                                                                                        icon={
                                                                                            faCheckCircle
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                },
                                                            )}
                                                        </>
                                                    )}
                                                    {!!assignment.equipment && (
                                                        <>
                                                            {assignment.equipment.map(
                                                                (e) => {
                                                                    const icon = e.assignedEquipmentIsVehicle ? faTruck : faToolbox;

                                                                    const ownedAssignment = e.equipmentEmployeeAssignmentId
                                                                                    === assignment.assignedEmployeeId
                                                                                || dependentEquipmentIds.includes(
                                                                                    e.assignedEquipmentId,
                                                                                );

                                                                    return (
                                                                        <tr
                                                                            className={cls(
                                                                                'assignmentRow',
                                                                                {
                                                                                    owned: !!ownedAssignment,
                                                                                },
                                                                            )}
                                                                            key={
                                                                                e.id
                                                                            }
                                                                        >
                                                                            <td className="iconCell">
                                                                                <FontAwesomeIcon
                                                                                    className={cls(
                                                                                        {
                                                                                            'text-success font-weight-bold':
                                                                                                        !!ownedAssignment,
                                                                                            'text-muted':
                                                                                                        !ownedAssignment,
                                                                                        },
                                                                                    )}
                                                                                    icon={
                                                                                        icon
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <FlexColumnStart>
                                                                                    <small
                                                                                        className={cls(
                                                                                            {
                                                                                                'text-success font-weight-bold':
                                                                                                            !!ownedAssignment,
                                                                                            },
                                                                                        )}
                                                                                    >
                                                                                        {`${e.assignedEquipmentType} ${e.assignedEquipmentName}`}
                                                                                    </small>
                                                                                    {e.equipmentEmployeeAssignmentName && (
                                                                                        <small>{`(Assigned to: ${e.equipmentEmployeeAssignmentName})`}</small>
                                                                                    )}
                                                                                    {e.equipmentAssignedEquipmentName && (
                                                                                        <small>{`(Assigned to: ${e.equipmentAssignedEquipmentType} ${e.equipmentAssignedEquipmentName})`}</small>
                                                                                    )}
                                                                                </FlexColumnStart>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                {!confirmed
                                                                                            && e.confirmationStatusId
                                                                                                === ConfirmationStatus.Confirmed && (
                                                                                    <FontAwesomeIcon
                                                                                        style={{
                                                                                            opacity:
                                                                                                            ownedAssignment
                                                                                                                ? 1
                                                                                                                : 0.7,
                                                                                        }}
                                                                                        size="lg"
                                                                                        title="Equipment confirmed"
                                                                                        className="icon-check checked"
                                                                                        icon={
                                                                                            faCheckCircle
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                {!confirmed
                                                                                            && e.confirmationStatusId
                                                                                                !== ConfirmationStatus.Confirmed
                                                                                            && ownedAssignment && (
                                                                                    <FontAwesomeIcon
                                                                                        style={{
                                                                                            opacity:
                                                                                                            ownedAssignment
                                                                                                                ? 1
                                                                                                                : 0.7,
                                                                                        }}
                                                                                        size="lg"
                                                                                        className={cls(
                                                                                            'cursor-pointer icon-check',
                                                                                            {
                                                                                                checked:
                                                                                                                confirmedAssignments.indexOf(
                                                                                                                    e.id,
                                                                                                                )
                                                                                                                > -1,
                                                                                            },
                                                                                        )}
                                                                                        title={
                                                                                            confirmedAssignments.indexOf(
                                                                                                e.id,
                                                                                            )
                                                                                                        > -1
                                                                                                ? 'Confirmed'
                                                                                                : 'Click to confirm'
                                                                                        }
                                                                                        icon={
                                                                                            faCheckCircle
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                },
                                                            )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                {timesheet.allowLinkedWorkOrders && (
                                    <>
                                        <FlexEndRow>
                                            <SmallButton
                                                type="button"
                                                className="ml-1"
                                                title=""
                                                onClick={
                                                    this
                                                        .toggleLinkedTimesheetModal
                                                }
                                            >
                                                <i className="fa fa-plus-circle fa-lg mr-1" />
                      Add Timesheet
                                            </SmallButton>
                                        </FlexEndRow>
                                        <Modal
                                            isOpen={
                                                showLinkedTimesheetModal
                                            }
                                            toggle={
                                                this
                                                    .toggleLinkedTimesheetModal
                                            }
                                        >
                                            <ModalHeader
                                                toggle={
                                                    this
                                                        .toggleLinkedTimesheetModal
                                                }
                                            >
                      Add Timesheet
                                            </ModalHeader>
                                            <ModalBody>
                                                <FormGroup>
                                                    <p className="text-danger">
                          NOTE: This action
                          adds another
                          timesheet to the job
                          and should only be
                          used if more than
                          one timesheet is
                          needed. For example:
                          If you need to
                          submit separate
                          timesheets for
                          different customer
                          work orders. If you
                          do NOT need an
                          additional
                          timesheet, please
                          select "Cancel" and
                          complete any
                          existing timesheets.
                                                    </p>
                                                </FormGroup>
                                            </ModalBody>
                                            <ModalFooter>
                                                {isSavingLinkedTimesheet && (
                                                    <FontAwesomeIcon
                                                        icon={faCircleNotch}
                                                        className="fa-spin mr-2"
                                                        size="sm"
                                                    />
                                                )}
                                                <Button
                                                    color="success"
                                                    disabled={
                                                        isSavingLinkedTimesheet
                                                    }
                                                    onClick={
                                                        this.onAddTimesheet
                                                    }
                                                >
                        Ok
                                                </Button>
                                                {' '}
                                                <Button
                                                    color="danger"
                                                    disabled={
                                                        isSavingLinkedTimesheet
                                                    }
                                                    onClick={
                                                        this
                                                            .toggleLinkedTimesheetModal
                                                    }
                                                >
                        Cancel
                                                </Button>
                                                {' '}
                                            </ModalFooter>
                                        </Modal>
                                    </>
                                )}
                                <div className="table-responsive">
                                    <table
                                        id="timesheetsTable"
                                        className="table table-sm"
                                    >
                                        <thead>
                                            <tr>
                                                <th>TIMESHEET</th>
                                                {/* <th className="text-center"> */}
                                                {/*    <FlexColumnCenter> */}
                                                {/*        <span>Action</span> */}
                                                {/*    </FlexColumnCenter> */}
                                                {/* </th> */}
                                                <th className="client">
                                                    <FlexColumnCenter>
                                                        <span>Client</span>
                                                        <span>Sig</span>
                                                    </FlexColumnCenter>
                                                </th>
                                                <th className="crew">
                                                    <FlexColumnCenter>
                                                        <span>Crew</span>
                                                        <span>Sig</span>
                                                    </FlexColumnCenter>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!(timesheet.timesheets ?? [])
                                                .length ? (
                                                    <tr>
                                                        <td
                                                            colSpan="4"
                                                            className="text-center"
                                                        >
                              No time sheets
                              found.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    timesheet.timesheets.map(
                                                        (timesheet, i) => {
                                                            const done = !!timesheet.clientReviewComplete
                                                                && !!timesheet.employeeReviewComplete;
                                                            const isDeleted = timesheet.statusId === TimesheetStatus.Deleted;

                                                            return (
                                                                <tr
                                                                    key={
                                                                        timesheet.id
                                                                    }
                                                                    className={`timesheet-row ${isDeleted ? 'timesheet-deleted bg-light' : ''}`}
                                                                >
                                                                    <td className="d-flex flex-row align-items-start">
                                                                        {timesheet.isComplete ? (
                                                                            <>
                                                                                <FontAwesomeIcon
                                                                                    id={`timesheetStatusLink_${timesheet.id}`}
                                                                                    icon={
                                                                                        faCheckCircle
                                                                                    }
                                                                                    className="mt-1 cursor-pointer mr-1 text-success"
                                                                                />
                                                                                <Popover
                                                                                    trigger="focus"
                                                                                    placement="right"
                                                                                    isOpen={
                                                                                        timesheet.timesheetPopoverOpen
                                                                                    }
                                                                                    target={`timesheetStatusLink_${timesheet.id}`}
                                                                                    toggle={() => this.setState(
                                                                                        (
                                                                                            state,
                                                                                        ) => (
                                                                                            (state.timesheet.timesheets[
                                                                                                i
                                                                                            ].timesheetPopoverOpen = !state
                                                                                                .timesheet
                                                                                                .timesheets[
                                                                                                    i
                                                                                                ]
                                                                                                .timesheetPopoverOpen),
                                                                                            state
                                                                                        ),
                                                                                    )}
                                                                                >
                                                                                    <PopoverHeader className="success">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faCheckCircle
                                                                                            }
                                                                                            className="mr-1 text-success"
                                                                                        />
                                            Timesheet
                                            Submitted
                                                                                    </PopoverHeader>
                                                                                    <PopoverBody>
                                            This
                                            timesheet
                                            has
                                            been
                                            submitted.
                                                                                    </PopoverBody>
                                                                                </Popover>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <FontAwesomeIcon
                                                                                    id={`timesheetStatusLink_${timesheet.id}`}
                                                                                    icon={isDeleted ? faEyeLowVision : faExclamationTriangle}
                                                                                    className={`mt-1 cursor-pointer mr-1 ${isDeleted ? 'text-muted' : 'text-danger'}`}
                                                                                />
                                                                                <Popover
                                                                                    trigger="focus"
                                                                                    placement="right"
                                                                                    isOpen={
                                                                                        timesheet.timesheetPopoverOpen
                                                                                    }
                                                                                    target={`timesheetStatusLink_${timesheet.id}`}
                                                                                    toggle={() => this.setState(
                                                                                        (
                                                                                            state,
                                                                                        ) => (
                                                                                            (state.timesheet.timesheets[
                                                                                                i
                                                                                            ].timesheetPopoverOpen = !state
                                                                                                .timesheet
                                                                                                .timesheets[
                                                                                                    i
                                                                                                ]
                                                                                                .timesheetPopoverOpen),
                                                                                            state
                                                                                        ),
                                                                                    )}
                                                                                >
                                                                                    <PopoverHeader className="danger">
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faExclamationTriangle
                                                                                            }
                                                                                            className="mr-1 text-danger"
                                                                                        />
                                            Timesheet
                                            Incomplete
                                                                                    </PopoverHeader>
                                                                                    <PopoverBody>
                                            Ensure
                                            all
                                            timesheet
                                            records
                                            are
                                            filled
                                            out
                                            and
                                            submitted.
                                                                                    </PopoverBody>
                                                                                </Popover>
                                                                            </>
                                                                        )}
                                                                        <FlexColumnCenter>
                                                                            <Button
                                                                                color="link"
                                                                                className={`site-link ${isDeleted ? 'text-muted' : ''}`}
                                                                                onClick={() => this.openTimesheet(
                                                                                    timesheet.id,
                                                                                )}
                                                                            >
                                                                                {`${
                                                                                    timesheet.customerName
                                                                                }${
                                                                                    timesheet.subcontractorName
                                                                                        ? ` - ${timesheet.subcontractorName}`
                                                                                        : ''
                                                                                } - ${
                                                                                    timesheet.jobNumber
                                                                                }`}
                                                                            </Button>
                                                                            {!!timesheet.workOrderNumber && (
                                                                                <small>
                                                                                    <span className="font-weight-bold mr-1">
                                          WO
                                          #:
                                                                                    </span>
                                                                                    {
                                                                                        timesheet.workOrderNumber
                                                                                    }
                                                                                </small>
                                                                            )}
                                                                            {isDeleted && (
                                                                                <span className="font-italic text-muted">
                                                                                    This timesheet has been deleted and cannot
                                                                                    be modified.
                                                                                </span>
                                                                            )}
                                                                        </FlexColumnCenter>
                                                                    </td>
                                                                    {/* <td className="actions text-center"> */}
                                                                    {/*    <span className="site-link cursor-pointer" */}
                                                                    {/*        onClick={() => this.openTimesheet(timesheet.id)} */}
                                                                    {/*    > */}
                                                                    {/*        {timesheet.timesheetBillableDetailsComplete ? 'View' : 'Edit'} */}
                                                                    {/*    </span> */}
                                                                    {/* </td> */}
                                                                    <td className="client">
                                                                        {timesheet.timesheetBillableDetailsComplete
                                                                        && !timesheet.clientReviewComplete ? (
                                                                                <Button
                                                                                    color="link"
                                                                                    id={`timesheetLink_${timesheet.id}`}
                                                                                    className="site-link text-center"
                                                                                    title="Client Review Details"
                                                                                    onClick={() => this.openTimesheetClientReview(
                                                                                        timesheet.id,
                                                                                    )}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faPencilAlt
                                                                                        }
                                                                                        size="lg"
                                                                                        className="mr-2"
                                                                                    />
                                                                                </Button>
                                                                            ) : (
                                                                                <>
                                                                                    <FontAwesomeIcon
                                                                                        id={`clientReviewIcon_${timesheet.id}`}
                                                                                        className={
                                                                                            timesheet.clientReviewComplete
                                                                                                ? 'text-success cursor-pointer'
                                                                                                : `cursor-pointer ${isDeleted ? 'text-muted' : 'text-danger'}`
                                                                                        }
                                                                                        title={
                                                                                            timesheet.clientReviewComplete
                                                                                                ? 'Complete - Click to Review'
                                                                                                : 'Complete the timesheet in order to do the Client Review.'
                                                                                        }
                                                                                        icon={
                                                                                            timesheet.clientReviewComplete
                                                                                                ? faCheck
                                                                                                : faExclamationTriangle
                                                                                        }
                                                                                        onClick={() => (timesheet.clientReviewComplete
                                                                                            ? this.openTimesheetClientReview(
                                                                                                timesheet.id,
                                                                                            )
                                                                                            : undefined)}
                                                                                        size="lg"
                                                                                        container="body"
                                                                                    />
                                                                                    {!timesheet.clientReviewComplete && (
                                                                                        <Popover
                                                                                            trigger="focus"
                                                                                            placement="left"
                                                                                            isOpen={
                                                                                                timesheet.clientPopoverOpen
                                                                                            }
                                                                                            target={`clientReviewIcon_${timesheet.id}`}
                                                                                            toggle={() => this.setState(
                                                                                                (
                                                                                                    state,
                                                                                                ) => (
                                                                                                    (state.timesheet.timesheets[
                                                                                                        i
                                                                                                    ].clientPopoverOpen = !state
                                                                                                        .timesheet
                                                                                                        .timesheets[
                                                                                                            i
                                                                                                        ]
                                                                                                        .clientPopoverOpen),
                                                                                                    state
                                                                                                ),
                                                                                            )}
                                                                                        >
                                                                                            <PopoverHeader className="danger">
                                                                                                <FontAwesomeIcon
                                                                                                    icon={
                                                                                                        faExclamationTriangle
                                                                                                    }
                                                                                                    className="mr-1 text-danger"
                                                                                                />
                                              Previous
                                              Steps
                                              Incomplete
                                                                                            </PopoverHeader>
                                                                                            <PopoverBody>
                                              Ensure
                                              the
                                              timesheet
                                              is
                                              filled
                                              out
                                              and
                                              submitted
                                              prior
                                              to
                                              client
                                              review.
                                                                                            </PopoverBody>
                                                                                        </Popover>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                    </td>
                                                                    <td className="crew">
                                                                        {!!timesheet.timesheetDetailsComplete
                                                                        && !timesheet.employeeReviewComplete ? (
                                                                                <Button
                                                                                    color="link"
                                                                                    className="site-link text-center"
                                                                                    title="Employee Review Details"
                                                                                    onClick={() => this.openTimesheetEmployeeReview(
                                                                                        timesheet.id,
                                                                                    )}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faPencilAlt
                                                                                        }
                                                                                        size="lg"
                                                                                        className="mr-2"
                                                                                    />
                                                                                </Button>
                                                                            ) : (
                                                                                <>
                                                                                    <FontAwesomeIcon
                                                                                        id={`empReviewIcon_${timesheet.id}`} 
                                                                                        className={
                                                                                            done
                                                                                                ? 'text-success'
                                                                                                : `cursor-pointer ${isDeleted ? 'text-muted' : 'text-danger'}`
                                                                                        }
                                                                                        title={
                                                                                            done
                                                                                                ? 'Complete'
                                                                                                : 'Complete both the timesheet and Client Review in order to do the Crew Review.'
                                                                                        }
                                                                                        icon={
                                                                                            done
                                                                                                ? faCheck
                                                                                                : faExclamationTriangle
                                                                                        }
                                                                                        size="lg"
                                                                                        container="body"
                                                                                    />
                                                                                    <Popover
                                                                                        trigger="focus"
                                                                                        placement="left"
                                                                                        isOpen={
                                                                                            timesheet.employeePopoverOpen
                                                                                        }
                                                                                        target={`empReviewIcon_${timesheet.id}`}
                                                                                        toggle={() => this.setState(
                                                                                            (
                                                                                                state,
                                                                                            ) => (
                                                                                                (state.timesheet.timesheets[
                                                                                                    i
                                                                                                ].employeePopoverOpen = !state
                                                                                                    .timesheet
                                                                                                    .timesheets[
                                                                                                        i
                                                                                                    ]
                                                                                                    .employeePopoverOpen),
                                                                                                state
                                                                                            ),
                                                                                        )}
                                                                                    >
                                                                                        <PopoverHeader
                                                                                            className={
                                                                                                done
                                                                                                    ? 'success'
                                                                                                    : 'danger'
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    done
                                                                                                        ? faCheck
                                                                                                        : faExclamationTriangle
                                                                                                }
                                                                                                className={`mr-1 ${
                                                                                                    done
                                                                                                        ? 'text-success'
                                                                                                        : 'text-danger'
                                                                                                }`}
                                                                                            />
                                                                                            {done
                                                                                                ? 'Complete'
                                                                                                : 'Previous Steps Incomplete'}
                                                                                        </PopoverHeader>
                                                                                        <PopoverBody>
                                                                                            {done
                                                                                                ? 'This timesheet is completed.'
                                                                                                : 'Ensure the timesheet is filled out and the client review is complete prior to employee review.'}
                                                                                        </PopoverBody>
                                                                                    </Popover>
                                                                                </>
                                                                            )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        },
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </Container>
                </div>
                <TimesheetItem
                    ref={this.timesheetItemRef}
                    show={this.state.timesheetOpen}
                    toggleShow={(open) => this.setState({ timesheetOpen: open })}
                    onClose={this.onTimesheetItemClosed}
                />
                <ClientReview
                    tenantSettings={tenantSettings}
                    ref={this.timesheetClientReviewRef}
                    show={this.state.timesheetClientReviewOpen}
                    toggleShow={(open) => this.setState({ timesheetClientReviewOpen: open })}
                    onClose={this.onTimesheetClientReviewClosed}
                />
                <EmployeeReview
                    tenantSettings={tenantSettings}
                    ref={this.timesheetEmployeeReviewRef}
                    show={this.state.timesheetEmployeeReviewOpen}
                    toggleShow={(open) => this.setState({ timesheetEmployeeReviewOpen: open })}
                    onClose={this.onTimesheetEmployeeReviewClosed}
                />
            </>
        );
    }
}

export default withRouter(TimesheetForm);
