import React from 'react';
import { Prompt } from 'react-router-dom';
import { faCheckCircle, faScrewdriver } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';
import { BaseFormViewModel } from '../common/ViewModel';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import {
    FormCheckbox,
    FormLabel, onFieldChange, onReactSelectChanged, ToastMessage, ValidationErrorMessage, SubHeading,
} from '../common/forms/FormElements';
import { EquipmentType } from './Equipment';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { EquipmentTypePayrollIncentiveGrid } from './EquipmentTypePayrollIncentiveGrid';

export class EquipmentTypeForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const initialEquipmentType = this.props.equipmentType ?? new EquipmentType();
        const stateBase = {
            equipmentType: initialEquipmentType,
            originalData: {
                ...util.object.clone(initialEquipmentType),
                payrollIncentives: initialEquipmentType.payrollIncentives ?? [], // Ensure it's an array
            },
            payrollIncentives: initialEquipmentType.payrollIncentives ?? [],
            groups: [],
            locations: [],
            show: false,
            statuses: [],
            requiredEquipment: [],
            afadTypes: [],
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    onChange = onFieldChange.bind(this);

    onClose(response) {
        const hasUnsavedChanges =
        !isEqual(this.state.originalData, {
            ...this.state.equipmentType,
            payrollIncentives: this.state.payrollIncentives,
        });

        if (hasUnsavedChanges) {
            if (!window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
                return;
            }
        }

        this.setState({ show: false });
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async (e) => {
        const { equipmentType } = this.state;
        const response = await util.fetch.delete(ApiRoutes.equipmentType.delete(equipmentType.id));
        if (response) this.onClose(response);
    };

    onSelectChange = onReactSelectChanged.bind(this);

    onSubmit = async (e) => {
        const { equipmentType, payrollIncentives } = this.state;

        // Clear any fluent api errors
        this.setState({ errors: {}, saving: true });

        // Prepare the payload for submission
        const payload = {
            ...equipmentType,
            payrollIncentives: payrollIncentives.map((incentive) => ({
                ...incentive,
                id: incentive.id || null,
            })),
        };

        // Is this POST or PUT?
        const url = equipmentType.id
            ? ApiRoutes.equipmentType.update(equipmentType.id)
            : ApiRoutes.equipmentType.create();
        const fetchMethod = equipmentType.id ? util.fetch.put : util.fetch.post;

        try {
            // Send the payload to the API
            const response = await fetchMethod(url, payload);
    
            if (response) {
                // Show success notification
                toast.success(
                    <ToastMessage
                        icon={faCheckCircle}
                        header="Save Successful"
                        message={`Equipment Type [${equipmentType.description}] saved.`}
                    />
                );
    
                // Reset the form state and close the form
                this.setState({ show: false, saving: false });
                this.context.setFormOpened(false);
                this.resetForm();
    
                // Notify the parent component of successful save
                this.props.onSaveCallback(response);
            }
        } catch (err) {
            // Handle errors (e.g., validation or server errors)
            this.handleSaveError(err);
        } finally {
            // Reset saving state
            this.setState({ saving: false });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);


    getSelectedAFADType = () => {
        const { afadTypes, equipmentType } = this.state;

        return (afadTypes ?? []).find(
            (s) => s.value === equipmentType.equipmentTypeAFADTypeId,
        ) ?? 1;
    };

    open = async (id) => {
        this.resetForm();
        this.setState({ show: true, loading: true });
        this.context.setFormOpened(true);

        let [equipmentType, requiredEquipmentOptions] = await Promise.all([
            (id ? util.fetch.js(ApiRoutes.equipmentType.byId(id)) : new EquipmentType()),
            util.fetch.js(ApiRoutes.equipmentType.all()),
        ]);

        await this.populateState();

        if (equipmentType.id) {
            // Cannot assign dependent type as itself
            requiredEquipmentOptions = (requiredEquipmentOptions ?? [])
                .filter((x) => x.id !== equipmentType.id);
        }

        requiredEquipmentOptions = requiredEquipmentOptions.map((x) => ({ value: x.id, label: x.description }));

        // Fetch payroll incentives for this equipment type
        const payrollIncentives = equipmentType.payrollIncentives.map((incentive) => ({
            id: incentive.id ?? null,
            amount: incentive.amount ?? 0,
            applicationUserGroupId: incentive.applicationUserGroupId ?? null,
            usStateId: incentive.usStateId ?? null,
            editing: false, // Set editing to false initially
        }));

        this.setState({
            equipmentType,
            originalData: {
                ...util.object.clone(equipmentType),
                payrollIncentives,
            },
            requiredEquipment: requiredEquipmentOptions,
            payrollIncentives,
            loading: false,
        });
    };

    async populateState() {
        const [groups, afadTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.employeeGroups()),
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypeAFADTypes()),
        ]);

        let usStates = [];

        if (this.context?.tenant?.tenantSettings?.tenantCountryId) {
            usStates = await util.fetch.js(
                ApiRoutes.typeAheads.USStatesbyCountryId(this.context.tenant.tenantSettings.tenantCountryId)
            );
        }

        this.setState((state) => ({
            groups,
            afadTypes,
            usStates,
        }));
    }

    resetForm() {
        this.setState({ formValidated: false });
    }
    
    handleIncentivesChange = (updatedIncentives) => {
        this.setState({ payrollIncentives: updatedIncentives });
    };

    render() {
        const {
            requiredEquipment, groups, formValidated, validationMessage,
            afadTypes, originalData, equipmentType, payrollIncentives,
        } = this.state;

        const selectedAFADType = this.getSelectedAFADType();
    
        const hasUnsavedChanges = !isEqual(originalData, {
            ...equipmentType,
            payrollIncentives: payrollIncentives,
        });

        if (!this.context?.tenant?.tenantSettings) {
            return null;
        }

        // TODO - Pay incentives are currently only supported in union payroll
        const enablePayIncentives = this.context.tenant.tenantSettings.enforceContractUnionWork;

        return (
            <>
                <Prompt
                    when={hasUnsavedChanges}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />
                <SlideForm
                    loading={this.state.loading}
                    show={this.state.show}
                    id="equipmentTypeForm"
                    formIcon={faScrewdriver}
                    formTitle={!!parseInt(equipmentType.id ?? 0) > 0 ? 'Edit Equipment Type' : 'Add Equipment Type'}
                    ref={this.formRef}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    onSubmit={this.onSubmit}
                    onClose={this.onClose}
                    onSave={this.onSave}
                    onDelete={this.onDelete}
                    errors={this.state.errors}
                    onClearErrors={this.onClearErrors}
                    validationMessage={validationMessage}
                    size="col-xl-5"
                >

                    <FormGroup>
                        <FormLabel
                            htmlFor="description"
                            text="Description"
                            required
                        />
                        <Input
                            id="description"
                            name="equipmentType.description"
                            value={equipmentType.description ?? ''}
                            onChange={this.onChange}
                            placeholder="Enter description"
                            type="text"
                            required
                        />
                        <ValidationErrorMessage>Description is required.</ValidationErrorMessage>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel
                            id="groupsLabel"
                            htmlFor="groups"
                            text="Required User Groups"
                            helpMessage="Select any user groups that are required for this type of equipment."
                            required={!(equipmentType.requiredEquipment ?? []).length}
                        />
                        <ValidatedSelect
                            id="groups"
                            name="equipmentType.groups"
                            isMulti
                            options={groups}
                            value={(groups ?? []).filter((x) => (equipmentType.groups ?? []).includes(x.value)) ?? ''}
                            onChange={this.onSelectChange}
                            validationMessage="At least one is required: required user group or required equipment."
                            required={!(equipmentType.requiredEquipment ?? []).length}
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel
                            id="employeeAssignableQuantityLabel"
                            htmlFor="employeeAssignableQuantity"
                            text="Employee Assignable Quantity"
                            required={!!(equipmentType.groups ?? []).length}
                            helpMessage="Enter the amount that can be assigned to any of the selected user groups."
                        />
                        <Input
                            id="employeeAssignableQuantity"
                            name="equipmentType.employeeAssignableQuantity"
                            disabled={!(equipmentType.groups ?? []).length}
                            value={equipmentType.employeeAssignableQuantity ?? ''}
                            onChange={this.onChange}
                            placeholder="Enter Quantity"
                            type="number"
                            step="1"
                            required={!!(equipmentType.groups ?? []).length}
                        />
                        <ValidationErrorMessage>This quantity is required.</ValidationErrorMessage>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel
                            id="requiredEquipmentLabel"
                            htmlFor="requiredEquipment"
                            text="Required Equipment"
                            required={!(equipmentType.groups ?? []).length}
                            helpMessage="Select other equipment types that will be required in addition to this equipment when it is assigned."
                        />
                        <Select
                            id="requiredEquipment"
                            name="equipmentType.requiredEquipment"
                            isMulti
                            options={requiredEquipment}
                            value={(requiredEquipment ?? []).filter((x) => (equipmentType.requiredEquipment ?? []).includes(x.value)) ?? ''}
                            onChange={this.onSelectChange}
                            required={!(equipmentType.groups ?? []).length}
                            validationMessage="At least one is required: required user group or required equipment."
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel
                            htmlFor="equipmentAssignableQuantityLabel"
                            text="Equipment Assignable Quantity"
                            required={!!(equipmentType.requiredEquipment ?? []).length}
                            helpMessage="Enter the amount that can be assigned to any of the selected equipment."
                        />
                        <Input
                            id="equipmentAssignableQuantity"
                            name="equipmentType.equipmentAssignableQuantity"
                            disabled={!(equipmentType.requiredEquipment ?? []).length}
                            value={equipmentType.equipmentAssignableQuantity ?? ''}
                            onChange={this.onChange}
                            placeholder="Enter Quantity"
                            type="number"
                            step="1"
                            required={!!(equipmentType.requiredEquipment ?? []).length}
                        />
                        <ValidationErrorMessage>This quantity is required.</ValidationErrorMessage>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel
                            id="afadTypeLabel"
                            htmlFor="equipmentTypeAFADTypeId"
                            text="Is this a type of AFAD?"
                            required
                        />
                        <ValidatedSelect
                            id="equipmentTypeAFADTypeId"
                            name="equipmentType.equipmentTypeAFADTypeId"
                            options={afadTypes}
                            required
                            value={selectedAFADType}
                            onChange={this.onSelectChange}
                            validationMessage="You must choose what type of equipment this is."
                        />
                    </FormGroup>

                    {!selectedAFADType.isAFAD && (
                        <FormGroup>
                            <FormCheckbox
                                className="mt-1"
                                id="isVehicle"
                                name="equipmentType.isVehicle"
                                onChange={this.onChange}
                                checked={equipmentType.isVehicle}
                                labelText="This is a vehicle"
                            />
                        </FormGroup>
                    )}

                    {Boolean(enablePayIncentives) && (
                        <Row className="mt-3">
                            <Col>
                                <SubHeading>Pay Incentives</SubHeading>
                                <EquipmentTypePayrollIncentiveGrid
                                    incentives={this.state.payrollIncentives}
                                    onIncentivesChange={this.handleIncentivesChange}
                                    usStates={this.state.usStates}
                                    userGroups={this.state.groups.filter(group => 
                                        (this.state.equipmentType.groups ?? []).includes(group.value)
                                    )}
                                />

                            </Col>
                        </Row>
                    )}
                </SlideForm>
            </>
        );
    }
}
