import { Button, ButtonGroup } from 'reactstrap';
import { YesNoOptions } from '../../Common';
import cls from 'classnames';
import { FormLabel } from '../forms/FormElements';
import { TipAlert } from '../TipAlert';

export const YesNoButtons = ({
    name,
    value,
    label = null,
    required = false,
    id = null,
    onClick = () => { },
    error = false,
    options = YesNoOptions,
    disabled = false,
    TipComponentProps = null,
}) => (
    <>
        {Boolean(label) && (
            <FormLabel
                htmlFor={id || name}
                text={label}
                required={required}
                disabled={disabled}
            />
        )}

        {Boolean(TipComponentProps) && (
            <TipAlert
                {...TipComponentProps}
            />
        )}

        <ButtonGroup
            id={id || name}
            name={name}
            disabled={disabled}
        >
            {options.map((t) => {
                const selected = t.value === value;

                return (
                    <Button
                        key={t.value}
                        size="sm"
                        outline={!selected}
                        color="secondary"
                        className={cls('p-2', selected && 'active', error && 'invalid')}
                        onClick={() => onClick(t.value)}
                        disabled={disabled}
                    >
                        {t.label}
                    </Button>
                );
            })}
        </ButtonGroup>
    </>
);