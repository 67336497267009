import React, { Fragment } from 'react';
import { isEmpty } from 'lodash-es';
import cls from 'classnames';
import {
    faTruck, faSave, faTrashAlt, faPlus, faEdit, faEye, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash-es';
import {
    Button,
    Col,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
} from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { Prompt, withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    AppPageForm,
    FlexCenterRow,
    FlexColumnStart,
    FlexStartRow,
    FormCircularProgress,
    FormDivider,
    FormGroupColumn,
    FormCheckbox,
    GroupedRow,
    onFieldChange,
    onReactSelectChanged,
    SmallOutlineButton,
    SubHeading,
    toasty,
    FormLabel, SmallButton,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import FileUpload from '../common/forms/FileUpload';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    SupportedFileExtensions,
    Weekdays,
    ContactTypes,
} from '../Common';
import { Job } from './Job';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import AddressFormNew from '../address/AddressFormNew';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { Address } from '../address/Address';
import OrganizationContactForm from '../organization/OrganizationContactForm';
import { OrganizationContact } from '../organization/Organization';
import { JobOverride } from './JobOverride';
import JobOverrideModal from './JobOverrideModal';
import { CountyLabel } from '../uscounties/CountyLabel';


class JobForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.addressFormRef = React.createRef();
        this.contactFormRef = React.createRef();

        const stateBase = {
            job: new Job(),
            dispatchLocations: [],
            weeks: [],
            usCounties: [],
            addressEditType: null, // from object const
            selectedAddressType: '',
            addressFormTitle: '',
            showTimesheetOverview: false,
            companyContactsOptions: [],
            projectManagerContactOptions: [],
            tableRequired: {},
            meetingLocationRequired: {},
            jobLocationRequired: {},
            pageModified: false,
            selectedContact: null,

            customerSubcontractors: [],
            showContactsForm: false,
            showAddSetupCharge: false,
            showAddPermitFees: false,

            // Property on the job to update with the newly created/edited contact.
            // job.requestedById or formenId.
            selectedContactIdProp: null,

            // Job override options
            showJobOverrideModal: false,
            currentJobOverride: null,
            // Add Upload Session ID
            uploadSessionId: `temp-${uuid()}`,

            edit: {
                dayStatuses: {},
                initialResources: null,
                allowRequestedBy: true,
                allowWeekOfDate: true,
                allowForemen: true,
                allowDispatchOfficeLocation: true,
                allowStartTime: true,
                allowWorkOrderNumber: true,
                // Needs special checks - what you can do is more nuanced.
                allowResources: true,
                allowMeetingLocation: true,
                allowJobLocation: true,
                // Currently will always be allowed.
                allowNotes: true,
                allowDelete: false,
                allowSubcontractor: true,
                allowProjectManagers: true,
                // Local tracking of resource errors.
                resourceErrors: {},
            },

            resourceTableError: '',
            showDeleteModal: false,
            contactFormIsReadOnly: false,
            allowContactTypeEdit: false,
            projectInformationInvalid: false,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeResourceDay = this.onChangeResourceDay.bind(this);
        this.onChangeNonResourceDay = this.onChangeNonResourceDay.bind(this);
        this.onOrganizationContactSaved = this.onOrganizationContactSaved.bind(this);
        this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    }

    // #region METHODS
    componentDidMount() {
        return this.populateState();
    }

    setPageModified = (b) => this.setState({ pageModified: b });

    // #region CONTACTS
    onAddContact = (prop) => {
        const { job } = { ...this.state };
        this.context.setFormOpened(true);
        this.contactFormRef.current.resetForm();
        this.setState({
            selectedContact: {
                ...new OrganizationContact(),
                id: null /* new record */,
                companyId:
                    job.contract
                        .companyId /* this will be used to attach the new contact to the location. */,
            },
            selectedContactIdProp: prop,
            showContactsForm: true,
            allowContactTypeEdit: true,
            pageModified: true,
        });
    };

    onAddLocation = (type) => {
        const { job } = this.state;
        const selectedAddress = new Address(job.contract.countryId, job.contract.countryCode);
        
        const addressFormTitle = `New ${type === 'meetingLocation' ? 'Meeting' : 'Job'
        } Location`;
        this.setState({
            selectedAddressType: type,
            addressFormTitle,
        });

        // dont show duplicate location if ones filled out already
        let show = true;
        if (job.meetingLocation || job.jobLocation) show = false;

        this.addressFormRef.current.open(
            selectedAddress,
            addressFormTitle,
            show,
            `Same as ${type === 'meetingLocation' ? 'Job Location' : 'Meeting Location'
            }`,
        );
    };

    onAddPermitFees = () => {
        this.setState({ showAddPermitFees: false });
    };

    onAddPermitFeesClick = () => {
        this.setState({ showAddPermitFees: true });
    };

    onAddSetupCharge = () => {
        this.setState({ showAddSetupCharge: false });
    };

    onAddSetupChargeClick = () => {
        this.setState({ showAddSetupCharge: true });
    };
    onChange = onFieldChange;
    onChangeNonResourceDay = (nonResource, e) => {
        // prolly do something here
        const { job } = this.state;
        const updated = job.nonResources.map((r) => {
            if (r.id === nonResource.id) {
                return { ...r, [e.target.name]: e.target.checked };
            }
            return { ...r };
        });

        job.nonResources = updated;

        this.setState({ job, pageModified: true });
    };

    onChangeResourceDay = (resource, e) => {
        const { job, edit } = { ...this.state };

        let val = parseInt(e.target.value);

        if (isNaN(val)) {
            val = 0;
        }

        if (val >= 100) {
            toasty.error('Resource assignment cannot exceed 100.');
            return;
        }

        const dayName = e.target.name;
        const { resourceName } = resource;

        // Note: e.target.name === "monday"
        //      resource.resourceName === "Work Truck"
        const msg = this.getResourceEditValidationMessage(
            dayName,
            resourceName,
            val,
        );

        // if we're changing "Flaggers (Planning)"", associate any errors with "Flaggers"
        const nonPlanningResourceName = this.getNonPlanningResourceName(resourceName);

        // Probably a better way of doing this.
        const msgKey = `${nonPlanningResourceName}_${dayName}`;

        if (msg) {
            toasty.error('Resource Update', msg);

            // Flag an error on the resource for the day.
            edit.resourceErrors[msgKey] = msg;
        } else {
            // Noop if it doesn't exist.
            delete edit.resourceErrors[msgKey];
        }

        job.resources = job.resources.map((r) => {
            if (r.id === resource.id) {
                const res = { ...r, [dayName]: `${val}` };
                return res;
            }
            return { ...r };
        });

        job.resources = job.resources.map((r) => {
            if (
                r.isPlanningOnly
                && !this.doesAfadAssignmentExistForDay(job, dayName)
            ) {
                const planningResource = { ...r, [dayName]: '0' };
                return planningResource;
            }

            return { ...r };
        });

        this.setState({ job, edit, pageModified: true });
        this.hydrateSetupCharges();
    };

    async onDeleteConfirmed() {
        const { job } = this.state;
        job.isDeleted = true;

        this.setState({ job });

        await this.saveJob();

        this.setState({ showDeleteModal: false });
    }

    onEditContact = async (prop) => {
        const { job } = { ...this.state };
        const selectedContact = await util.fetch.js(
            ApiRoutes.locationContact.byId(job[prop]),
        );
        this.contactFormRef.current.resetForm();

        await this.setState({
            selectedContact: { ...selectedContact },
            showContactsForm: true,
            selectedContactIdProp: prop,
            allowContactTypeEdit: false,
            pageModified: true,
        });
    };

    onEditLocation = (type) => {
        const { job } = { ...this.state };
        const selectedAddress = { ...job[type] };
        const addressFormTitle = `Edit ${type === 'meetingLocation' ? 'Meeting' : 'Job'
        } Location`;

        this.setState({
            selectedAddress,
            selectedAddressType: type,
            addressFormTitle,
            pageModified: true,
        });

        this.addressFormRef.current.open(selectedAddress, addressFormTitle);
    };

    onOrganizationContactSaved = async () => {
        await this.setState({ contactFormIsReadOnly: true });

        const { selectedContact, selectedContactIdProp, job } = {
            ...this.state,
        };
        const isNew = parseInt(selectedContact.id ?? 0) <= 0;
        const result = await util.fetch.andGetResponse(
            isNew ? util.fetch.types.post : util.fetch.types.put,
            isNew
                ? ApiRoutes.locationContact.create()
                : ApiRoutes.locationContact.update(selectedContact.id),
            { ...selectedContact },
            'Error Saving Contact',
        );

        if (result) {
            toasty.success('Contact saved.');

            // Make the contact the selected one - add returns just the Id, update returns object with id property.
            job[selectedContactIdProp] = result.id ?? result;

            // Refresh the contacts.
            const companyContacts = await this.getCompanyContactsOptions(
                job.contract.companyId,
            );

            // Update state.
            this.setState({
                ...companyContacts,
                job: { ...job },
                showContactsForm: false,
                saving: false,
            });
        } else {
            this.setState({ saving: false });
        }

        await this.setState({ contactFormIsReadOnly: false });
    };
    // #endregion

    onPermitFeesApplyChange = () => {
        const { job } = this.state;

        job.permitFeesApply = !job.permitFeesApply;

        this.setState({ job });
    };

    onPreviewDrop = (files) => {
        this.setState({
            files: this.state.files.concat(files),
        });
    };

    onRemoveAttachment = async (file) => {
        const { job } = { ...this.state };
        const url = ApiRoutes.job.attachment(job.id || this.state.uploadSessionId);
    
        // Instantly remove file from UI
        this.setState(prevState => ({
            job: {
                ...prevState.job,
                uploads: prevState.job.uploads.filter(f => f.name !== file.name),
            },
        }));
    
        try {
            const response = await util.fetch.delete(url, file , util.fetch.format.json);

            if (response) {
                toasty.success('Attachment removed.');
                // Fetch updated list to confirm the removal
                this.getAttachments();
            } else {
                const errorData = await response.json();
                toasty.error(errorData.message || 'Failed to remove the attachment. Try refreshing.');
            }
        } catch (error) {
            toasty.error('Could not delete attachment due to a server error.');
        }
    };    
    
    onDownloadAttachment = async (file) => {
        const { job } = { ...this.state };
        if (!file) return;

        const url = ApiRoutes.job.attachment(job.id || this.state.uploadSessionId); 
        util.fetch.downloadFile(url, file, file.name);
    };

    onSaveAddress = (address) => {
        let {
            job,
            selectedAddressType,
            meetingLocationRequired,
            jobLocationRequired,
        } = this.state;

        job[selectedAddressType] = { ...address };

        if (address.duplicate) {
            // if the other is blank fill it in.
            if (selectedAddressType === 'jobLocation' && !job.meetingLocation) {
                const mAddress = util.object.clone(address);
                mAddress.id = uuid();

                job.meetingLocation = mAddress;
            }

            if (selectedAddressType === 'meetingLocation' && !job.jobLocation) {
                const jAddress = util.object.clone(address);
                jAddress.id = uuid();

                job.jobLocation = jAddress;
            }
        }

        if (job.meetingLocation) meetingLocationRequired = {};

        if (job.jobLocation) jobLocationRequired = {};

        this.setState({
            job,
            meetingLocationRequired,
            jobLocationRequired,
        });
    };

    onSelectChanged = onReactSelectChanged;

    onSubmit = async (e) => {
        const { job, newLocationContacts } = { ...this.state };

        if (!this.validateSetupCharges()) {
            return false;
        }

        if (!this.onValidate()) return false;

        if (this.state.saving) return false;

        this.setState({ saving: true });

        // Revert uuid
        job.meetingLocation.id = job.meetingLocation.id.constructor === String
            ? null
            : job.meetingLocation.id;
        job.jobLocation.id = job.jobLocation.id.constructor === String
            ? null
            : job.jobLocation.id;
        job.resources = job.resources.map((r) => {
            r.id = r.id.constructor === String ? null : r.id;
            return r;
        });

        job.overrides = job.overrides?.map((o) => ({
            ...o,
            weekOfDate: job.weekOfDate,
            job: {
                weekOfDate: job.weekOfDate,
            },
        }));

        // Include new contacts
        job.newLocationContacts = newLocationContacts;

        // If job ID doesn't exist, pass TempSessionId
        if (!job.id) {
            job.tempSessionId = this.state.uploadSessionId; // Assign a new session if needed
        }

        this.setState({
            originalJob: util.object.clone(job),
            pageModified: false,
        });

        // Clear any fluent api errors
        this.clearErrors();
        job.id ? this.saveJob() : this.createJob();
    };

    onValidate = () => {
        let {
            job,
            tableRequired,
            meetingLocationRequired,
            jobLocationRequired,
            edit,
            resourceTableError,
        } = this.state;

        let valid = true;

        tableRequired = {};
        meetingLocationRequired = {};
        jobLocationRequired = {};

        const requiredStyle = {
            border: '1px solid',
            borderRadius: '0.25rem',
            borderColor: '#ed1c24',
        };

        // The var sum below is unused?
        // Resources
        // at least 1 day on the entire grid needs to be 1 or more.  So sum entire grid and greater than 0
        resourceTableError = '';
        // let sum = 0;
        // (job.resources ?? []).forEach(x => {
        //    sum += parseInt(x.sunday) + parseInt(x.monday) + parseInt(x.tuesday) + parseInt(x.wednesday) + parseInt(x.thursday) + parseInt(x.friday) + parseInt(x.saturday);
        // });

        // Bypass this per CW
        // if (sum === 0) {
        //    valid = false;

        //    resourceTableError += "At least 1 resource is required for a job. ";

        //    tableRequired = requiredStyle;
        // }

        // Resources - added after "At least 1 resource" check.
        // Only applicable to job edits - not new jobs.
        const resourceErrorKeys = Object.keys(edit.resourceErrors);
        if (resourceErrorKeys.length > 0) {
            valid = false;

            resourceErrorKeys.forEach(
                (key) => (resourceTableError += `${edit.resourceErrors[key]} `),
            );

            tableRequired = requiredStyle;
        }

        // Locations
        if (!job.meetingLocation) {
            valid = false;
            meetingLocationRequired = requiredStyle;
        }

        if (!job.jobLocation) {
            valid = false;
            jobLocationRequired = requiredStyle;
        }

        this.setState({
            formValidated: valid,
            saving: valid,
            tableRequired,
            meetingLocationRequired,
            jobLocationRequired,
            resourceTableError,
        });

        return valid;
    };

    getAttachments = async () => {
        const { job } = { ...this.state };
        const jobIdOrSessionId = job.id ? job.id : this.state.uploadSessionId;
        const attachments = await util.fetch.get(
            ApiRoutes.job.getAllAttachments(jobIdOrSessionId),
        );
        job.uploads = attachments ?? [];
        this.setState((prevState) => ({
            job: {
                ...prevState.job,
                uploads: attachments ?? [],
            }
        }));
    };
    // #endregion

    getCompanyContactsOptions = async (
        companyId,
        constrainContactTypes,
        requestedById,
        foremanId,
        projectManagerId
    ) => {
        const companyContacts = await util.fetch.js(
            ApiRoutes.company.contacts(companyId),
        );

        // TODO: Define and filter server-side.
        const descs = OrganizationContactForm.filteredContactTypes;
        const projectManagerFiltered = [];
        const contactsFiltered = companyContacts.filter((c) => {
            if (!c.isActive) return false;

            // Automatically include if we're not constraining contact types.
            // Include if the contact was already set as the "requested by" or foreman person.
            const include = !constrainContactTypes
                || c.id === requestedById
                || c.id === foremanId
                || c.id === projectManagerId
                || (c.locationContactTypes
                    && c.locationContactTypes.some((t) => descs.some((d) => t.description === d)));

            if (include && c.locationContactTypes.some((t) => t.id === ContactTypes.ProjectManager)) {
                projectManagerFiltered.push(c);
            }
            return include;
        });

        const contactsMapped = contactsFiltered.map((x) => ({
            label: x.contactName,
            value: x.id,
        }));
        const projectManagersMapped = projectManagerFiltered.map((x) => ({
            label: x.contactName,
            value: x.id,
        }));

        // const contactsMapped = [...companyContacts.filter((c) => c.isActive).map(x => { return { label: x.contactName, value: x.id } })];
        return { companyContactsOptions: contactsMapped, projectManagerContactOptions: projectManagersMapped };
    };

    getExistingOverrideDay = (day) => {
        const { job } = { ...this.state };

        const overrideDay = Weekdays[day.getDay()].name;
        const existingOverride = (job.overrides ?? []).find((override) => override.overrideDay === overrideDay && !override.isDeleted);

        return existingOverride;
    };

    getNonPlanningResourceName(resourceName) {
        return resourceName.startsWith('Flagger')
            ? resourceName.split(' ')[0]
            : null;
    }

    /**
     * For cases where we have paired planning/resource versions of job resources
     * (as in the case of of "Flaggers" and "Flaggers (Planning)"), find the
     * paired version of the resource and extract its resource
     * count for the given day.
     * Planning resources are enabled by the EnableRequestedPersonnel tenant setting.
     * @param {*} resourceName
     * @param {*} day
     * @returns
     */
    getPairedResourceCount(resourceName, day) {
        const { job, tenantSettings } = this.state;

        if (!tenantSettings || !tenantSettings.enableRequestedPersonnel) {
            return null;
        }

        const nonPlanningResourceName = this.getNonPlanningResourceName(resourceName);

        if (nonPlanningResourceName == null) {
            return null;
        }

        const isNonPlanningResource = resourceName == nonPlanningResourceName;

        const alternateResource = job.resources.find(
            (r) => r.resourceName !== resourceName
                && r.resourceName.startsWith(nonPlanningResourceName),
        );

        if (
            !alternateResource
            // if we're editing Flaggers and Flaggers (Planning) is disabled because
            // an AFAD resource hasn't been assigned for the day, a zero value
            // on Flaggers (Planning) is not an issue
            || (isNonPlanningResource
                && !this.doesAfadAssignmentExistForDay(job, day))
        ) {
            return null;
        }

        return alternateResource[day];
    }

    getResourceEditValidationMessage(day, resourceName, val) {
        // You want a null message - means everything is OK.
        let msg = null;

        const resourceStatus = this.getResourceStatus(day, resourceName);

        if (resourceStatus && resourceStatus.statusCounts) {
            const maxStatus = resourceStatus.maxConfirmationStatusId;

            // If nothing has been assigned, you can edit whatever you'd like.
            if (maxStatus < 2) return null;

            // If the max status is "Scheduled", you cannot edit the value to go below assigned resources.
            // If the max status is 3 or 4, you can only add resources - number must be greater than the
            // counts of all statuses.
            const minCheckStatus = maxStatus === 2 ? 2 : 1;

            const threshold = Object.keys(resourceStatus.statusCounts).reduce(
                (acc, currentStatus) => {
                    // Set an upper limit of 4 in case we add future statuses.
                    if (currentStatus >= minCheckStatus && currentStatus <= 4) acc += resourceStatus.statusCounts[currentStatus];

                    return acc;
                },
                0,
            );

            if (val < threshold) {
                const du = `${day[0].toUpperCase()}${day.substring(1)}`;

                switch (maxStatus) {
                case 2:
                    msg = `"${resourceName}" must be at least ${threshold} on ${du} because of existing assignments.`;
                    break;
                case 3:
                case 4:
                    msg = `"${resourceName}" must be at least ${threshold} on ${du} because notifications have already been sent.`;
                    break;
                default:
                    break;
                }
            }
        }

        if (!msg) {
            const pairedResourceCount = this.getPairedResourceCount(
                resourceName,
                day,
            );

            if (pairedResourceCount !== null) {
                const uppercaseDay = `${day[0].toUpperCase()}${day.substring(
                    1,
                )}`;

                const isPlanning = resourceName.includes('Planning');

                if (isPlanning && Number(pairedResourceCount) > Number(val)) {
                    msg = `"${resourceName}" is fewer than the currently defined ${pairedResourceCount} resources on ${uppercaseDay}.`;
                } else if (
                    !isPlanning
                    && Number(pairedResourceCount) < Number(val)
                ) {
                    msg = `"${resourceName}" must be less than or equal to ${pairedResourceCount} on ${uppercaseDay} because of the configured planning count.`;
                }
            }
        }

        return msg;
    }

    getResourceStatus(day, resourceName) {
        let resourceStatus = null;

        const { edit } = this.state;

        if (
            edit.dayStatuses
            && edit.dayStatuses[day]
            && edit.dayStatuses[day].resourceStatuses
            && edit.dayStatuses[day].resourceStatuses[resourceName]
        ) {
            resourceStatus = edit.dayStatuses[day].resourceStatuses[resourceName];
        }

        return resourceStatus;
    }

    getWeekValuesArrayOrdered = (jobWeek) => {
        // Safari sometimes reads this in the wrong format - it should always be stored as YYYY-MM-DD
        const formats = ['YYYY-MM-DD', 'L-d-YYYY'];
        let startDate;

        formats.forEach((format) => {
            const date = moment(jobWeek, format);

            if (date.isValid()) {
                startDate = date.toDate();
            }
        });

        const dates = [];
        dates.push(startDate);
        for (let i = 1; i < 7; i++) {
            dates.push(
                moment(dates[i - 1])
                    .add(1, 'day')
                    .toDate(),
            );
        }
        return dates;
    };

    clearErrors = () => this.setState({ errors: {} });

    createJob = async () => {
        const { job } = this.state;

        const response = await util.fetch.post(
            ApiRoutes.job.create(),
            job,
            util.fetch.format.none,
        );
        this.processSaveResponse(response);
    };

    doesAfadAssignmentExistForDay = (job, dayName) => {
        if (!job.resources) {
            return false;
        }

        return job.resources.some(
            (r) => this.resourceIsAfad(r) && r[dayName] && r[dayName] > 0,
        );
    };

    handleClickResourceDay = (day) => {
        const override = this.getExistingOverrideDay(day) || new JobOverride(day);

        this.setState({
            showJobOverrideModal: true,
            currentJobOverride: {
                ...override,
                job: this.state.job,
            },
        });
    };

    handleCloseJobOverride = (data) => {
        if (data) {
            const { job } = { ...this.state };
            const overrides = (job.overrides ?? []);

            // Remove circular references
            data.job = {
                weekOfDate: data.job.weekOfDate,
            };

            const existingOverride = overrides.findIndex((override) => override.overrideDay === data.overrideDay);

            if (existingOverride === -1) {
                overrides.push(data);
            } else {
                overrides[existingOverride] = data;
            }

            this.setState({
                showJobOverrideModal: false,
                currentJobOverride: null,
                job: {
                    ...job,
                    overrides,
                },
            });
        } else {
            this.setState({
                showJobOverrideModal: false,
                currentJobOverride: null,
            });
        }
    };

    handleSaveError = handleFormSaveError;

    hydrateSetupCharges = () => {
        const { job } = { ...this.state };

        job.resources = job.resources.map((r) => {
            const flaggersRow = job.resources.filter((r) => r.isPlanningOnly == false && r.resourceName == 'Flaggers')[0];

            if (r.setupChargeTypeId > 0 && (r.isNew == true || this.resourceHasAnyAssignments(r))) {
                r.monday = flaggersRow.monday > 0 ? 1 : 0;
                r.tuesday = flaggersRow.tuesday > 0 ? 1 : 0;
                r.wednesday = flaggersRow.wednesday > 0 ? 1 : 0;
                r.thursday = flaggersRow.thursday > 0 ? 1 : 0;
                r.friday = flaggersRow.friday > 0 ? 1 : 0;
                r.saturday = flaggersRow.saturday > 0 ? 1 : 0;
                r.sunday = flaggersRow.sunday > 0 ? 1 : 0;
            }

            return { ...r };
        });

        this.setState({ job });
    };

    isRequestedPersonnelSectionAvailable = () => {
        const { job } = this.state;

        return (
            job.resources
            && job.resources.some((r) => r.isPlanningOnly)
            && job.resources.some(
                (r) => this.resourceIsAfad(r) && this.resourceHasAnyAssignments(r),
            )
        );
    };

    notifyError = (message) => toasty.error(
        'Save Unsuccessful',
        <FlexColumnStart>
            <span>There was a server error when saving:</span>
            {!!message && (
                <span className="pt-2 pb-2 font-weight-bold">{`${message}`}</span>
            )}
            <span>
                Please try your request again or contact support for
                assistance.
            </span>
        </FlexColumnStart>,
    );

    notifySuccess = () => toasty.success('Job Saved', 'Job saved successfully.');

    overrideDayIsDisabled = (day) => {
        const now = moment();

        const selectedDay = typeof day === 'string' ? moment(day, moment.ISO_8601) : moment(day);

        // Restrict overrides to 7PM the previous day
        selectedDay.subtract(5, 'hours');

        return now.isAfter(selectedDay);
    };

    populateState = async () => {
        let { showTimesheetOverview, edit } = { ...this.state };

        // Route params
        // Can route in from a contract page, or from job search, which can pull job by its ID
        // possibly add a way to select contract on here that job will assign to?
        const jobId = this.props.match.params.id; // {job/1}
        const { contractId } = this.props.match.params; // {newJob/1}


        // Promises
        const pDispatchLocs = util.fetch.js(
            ApiRoutes.typeAheads.dispatchLocations(),
        );
        const pJobManage = jobId
            ? util.fetch.js(ApiRoutes.job.byId(jobId))
            : { job: new Job(), edit: {}, constrainContactTypes: true };

        const pContract = jobId
            ? null
            : util.fetch.js(ApiRoutes.contract.byId(contractId)); // if new job, get the contract
        const pResources = jobId
            ? null
            : util.fetch.js(ApiRoutes.job.getResources(contractId)); // if new job, get the resources
        const pWeeks = util.fetch.js(ApiRoutes.job.weeks());


        let [
            dispatchLocations,
            jobManage,
            contract,
            resources,
            weeks,
            usCounties,
            tenantSettings,
        ] = await Promise.all([
            pDispatchLocs,
            pJobManage,
            pContract,
            pResources,
            pWeeks,
            util.fetch.js(ApiRoutes.USCounties.all()),
            util.fetch.js(ApiRoutes.tenant.settings()),
        ]);

        const { job } = jobManage;

        if (!jobId) {
            job.contract = contract;

            // If it's a new job and there's only 1 dispatch location, set it.
            if (dispatchLocations.length === 1) job.dispatchOfficeLocationId = dispatchLocations[0].value;

            if (job.contract) {
                dispatchLocations = dispatchLocations.filter((x) => (contract.dispatchLocations ?? []).includes(x.value));
                job.contractId = job.contract.id; // may need to gut this later
                job.description = job.contract.description;
            }
        } else {
            showTimesheetOverview = job.resources.filter((x) => x.typeName === 'None').length > 0;
        }

        if (resources) {
            job.resources = resources.map((r) => {
                if (r.id === 0) r.id = uuid();
                if (r.id === 0 && r.setupChargeTypeId > 0 && !r.isPermit) r.isNew = true;
                return r;
            });
            showTimesheetOverview = resources.filter((x) => x.typeName === 'None').length > 0;
        }

        // Week selector setup
        if (!!(job.id ?? 0) > 0) {
            /** The job exists and we don't really support week start changes, so clear it out, set the existing job's week
                as the only week in the array, and lock it down. */
            weeks = [];
            weeks.push({ label: job.weekOfDate, value: job.weekOfDate });
        } else {
            // Get the calculated weeks from the tenant settings, and select the first by default
            weeks = weeks.map((x) => ({ label: x.startDisplay, value: x.startDisplay }));
            // if its monday, we allow one week in past, so select currect index 1, else index 0(for not monday)
            if (new Date().getDay() === 1) {
                job.weekOfDate = weeks[1].value;
            } else {
                job.weekOfDate = weeks[0].value;
            }
        }

        const companyContacts = await this.getCompanyContactsOptions(
            job.contract.companyId,
            jobManage.constrainContactTypes,
            job.requestedById,
            job.foremenId,
            job.projectManagerId
        );

        // Copy updated edit permissions.
        edit = Object.assign(edit, jobManage.edit);
        if (job.resources) edit.initialResources = { ...job.resources };

        const customerId = job.contract.companyId;
        const customerSubcontractors = await util.fetch.js(
            ApiRoutes.typeAheads.subcontractors(customerId),
        );

        const originalJob = util.object.clone(job);

        this.setState((state) => ({
            weeks: [...weeks],
            loading: false,
            dispatchLocations: [...dispatchLocations],
            customerSubcontractors: [...customerSubcontractors],
            job: { ...job },
            edit: { ...edit },
            showTimesheetOverview,
            ...companyContacts,
            usCounties,
            tenantSettings,
            originalJob,
        }));
    };

    processSaveResponse = async (response) => {
        if (response.ok) {
            this.resetForm();
            this.notifySuccess();
            this.props.history.push(`${AppNavPaths.Dispatch}`);
        } else {
            let message = '';
            try {
                const responseData = await response.json();
                message = responseData.message;
            } catch (ex) {
                console.warn(ex);
            } finally {
                this.notifyError(message);
                this.resetForm();
            }
        }
    };

    removeSetupCharge = (r) => {
        r.isNew = false;

        r.monday = 0;
        r.tuesday = 0;
        r.wednesday = 0;
        r.thursday = 0;
        r.friday = 0;
        r.saturday = 0;
        r.sunday = 0;

        this.hydrateSetupCharges();
    };

    resetForm = () => this.setState({ formValidated: false, saving: false, originalJob: util.object.clone(this.state.job) });

    resourceHasAnyAssignments = (resource) => Boolean(
        resource
        && (resource.monday > 0
            || resource.tuesday > 0
            || resource.wednesday > 0
            || resource.thursday > 0
            || resource.friday > 0
            || resource.saturday > 0
            || resource.sunday > 0),
    );

    resourceIsAfad = (resource) => Boolean(
        resource && resource.isAFAD,
    );

    saveJob = async () => {
        const { job } = { ...this.state };

        const response = await util.fetch.put(
            ApiRoutes.job.update(job.id),
            job,
            util.fetch.format.none,
        );

        this.setPageModified(false);
        this.processSaveResponse(response);
    };

    validateSetupCharges = () => {
        const { job } = this.state;

        if (job.contract.setupChargesRequired) {
            const setupCharges = job.resources.filter((r) => r.setupChargeTypeId > 0);
            let hasSetupCharge = false;

            for (let i = 0; i < setupCharges.length; i++) {
                if (this.resourceHasAnyAssignments(setupCharges[i])) {
                    hasSetupCharge = true;
                    break;
                }
            }

            if (!hasSetupCharge) {
                toasty.error('At least one setup charge is required.');
                return false;
            }
        }

        return true;
    };

    renderResourceHeader = (weekdays, isPlanningOnly) => (
        <thead>
            <tr>
                <th>Resource</th>
                <th>Type</th>

                {weekdays.map((day) => {
                    const existing = this.getExistingOverrideDay(day);
                    const overrideDisabled = this.overrideDayIsDisabled(day);

                    if (isPlanningOnly || (overrideDisabled && !existing) || !this.state.tenantSettings?.tenantSettings.enableJobOverrides) {
                        return (
                            <th
                                key={day}
                                className="resource-weekday-col text-center"
                            >
                                <div className="d-flex flex-column">
                                    <span>
                                        {Weekdays[day.getDay()].abbreviation}
                                    </span>

                                    <span>{moment(day).format('M/D')}</span>
                                </div>
                            </th>
                        );
                    }

                    return (
                        <th
                            key={day}
                            className="resource-weekday-col text-center"
                        >
                            <Button color="link" size="sm" className={`d-flex align-items-center flex-column site-link ${existing && 'text-info'}`} role="button" onClick={() => this.handleClickResourceDay(day)}>
                                <span>
                                    {Weekdays[day.getDay()].abbreviation}
                                </span>

                                <span>{moment(day).format('M/D')}</span>

                                <FontAwesomeIcon
                                    icon={overrideDisabled ? faEye : faEdit}
                                    size="sm"
                                    className="ml-1"
                                />
                            </Button>
                        </th>
                    );
                })}
                <th />
            </tr>
        </thead>
    );

    renderResources = (weekdays, isPlanningOnly) => {
        const { job, edit } = { ...this.state };

        if (!job.resources || !(weekdays ?? []).length) {
            return '';
        }

        return job.resources
            .filter((r) => r.isPlanningOnly == Boolean(isPlanningOnly)
                && (r.setupChargeTypeId == null
                    || (r.setupChargeTypeId > 0 && (r.monday > 0 || r.tuesday > 0 || r.wednesday > 0 || r.thursday > 0 || r.friday > 0 || r.saturday > 0 || r.sunday > 0))
                    || (r.setupChargeTypeId > 0 && r.isNew == true)))
            .map((resource, i) => (
                <tr key={resource.resourceName}>
                    <td>{resource.resourceName}</td>
                    <td>{resource.typeName}</td>
                    {weekdays.map((day) => {
                        const dayObj = Weekdays[day.getDay()];
                        const dayName = dayObj.name.toLowerCase();
                        const dayNameHasTimesheet = `${dayName}HasTimesheet`;

                        const resourceIsDisabled = resource[dayNameHasTimesheet] === true
                            || (isPlanningOnly
                                && !this.doesAfadAssignmentExistForDay(
                                    job,
                                    dayName,
                                ));

                        return (
                            <td key={day} className="resource-weekday-column">
                                <input
                                    id={`r${dayName}`}
                                    name={`${dayName}`}
                                    className="w-100"
                                    required
                                    value={resource[dayName]}
                                    onChange={this.onChangeResourceDay.bind(
                                        this,
                                        resource,
                                    )}
                                    readOnly={!edit.allowResources || resource.setupChargeTypeId > 0}
                                    disabled={resourceIsDisabled}
                                    title={
                                        resource[dayNameHasTimesheet] === true
                                            ? 'Approved Timesheet Exists'
                                            : ''
                                    }
                                />
                            </td>
                        );
                    })}
                    <td style={{
                        textAlign: 'center', paddingLeft: '0px', paddingRight: '0px', paddingTop: '7px',
                    }}
                    >
                        {resource.setupChargeTypeId > 0
                            && (
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    title="Remove setup charge"
                                    className="text-danger cursor-pointer"
                                    onClick={() => this.removeSetupCharge(resource)}
                                />
                            )}

                    </td>
                </tr>
            ));
    };

    render() {
        const {
            loading,
            job,
            errors,
            formValidated,
            dispatchLocations,
            saving,
            weeks,
            companyContactsOptions,
            tableRequired,
            meetingLocationRequired,
            jobLocationRequired,
            selectedContact,
            showContactsForm,
            edit,
            resourceTableError,
            showDeleteModal,
            customerSubcontractors,
            contactFormIsReadOnly,
            allowContactTypeEdit,
            tenantSettings,
            showAddSetupCharge,
            showJobOverrideModal,
            currentJobOverride,
            showAddPermitFees,
            pageModified,
            originalJob,
            projectManagerContactOptions,
        } = this.state;

        const requestedPersonnelSectionAvailable = this.isRequestedPersonnelSectionAvailable();

        const supportedFiles = SupportedFileExtensions.image
            .concat(SupportedFileExtensions.document)
            .join(',');

        const showConfirmationPrompt = (pageModified || !isEqual(job, originalJob));
        const existing = !!job.id;

        const unusedSetupChargeTypes = job.resources.filter((r) => r.setupChargeTypeId > 0 && !r.isPermit);
        const unusedPermitFees = job.resources.filter((r) => r.setupChargeTypeId > 0 && r.isPermit);

        if (!!loading || !tenantSettings) {
            return <FormCircularProgress />;
        }
        const { jobUploadsEnabled } = {
            ...this.context.tenant.tenantSettings,
        };

        const weekdays = this.getWeekValuesArrayOrdered(job.weekOfDate);

        return (
            <>
                <Prompt
                    when={!!showConfirmationPrompt}
                    message="You have unsaved changes, are you sure you want to leave?"
                />

                <AppPageForm
                    formId="jobForm"
                    formHeadingIcon={faTruck}
                    formHeading={
                        !existing
                            ? 'New Job'
                            : `Edit Job: ${(job.contract ?? {}).number}${job.contractSequence
                                ? `-${job.contractSequence}`
                                : ''
                            }`
                    }
                    formName="jobForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    isValid={this.isFormValid}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    loading={this.state.loading}
                >
                    <SubHeading>Contract Details</SubHeading>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    className="mb-1"
                                    htmlFor="contractNumber"
                                    text="Contract Number"
                                />
                                <input
                                    id="contractNumber"
                                    name="contractNumber"
                                    readOnly
                                    className="form-control-plaintext"
                                    defaultValue={
                                        (job.contract ?? {}).number
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    className="mb-1"
                                    htmlFor="contractDescription"
                                    text="Description"
                                />
                                <input
                                    id="contractDescription"
                                    name="contractDescription"
                                    readOnly
                                    className="form-control-plaintext"
                                    defaultValue={
                                        (job.contract ?? {}).description
                                    }
                                />
                            </FormGroup>
                        </FormGroupColumn>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    className="mb-1"
                                    htmlFor="customerName"
                                    text="Customer Name"
                                />
                                <input
                                    id="customerName"
                                    name="customerName"
                                    readOnly
                                    className="form-control-plaintext"
                                    defaultValue={
                                        (job.contract ?? {}).customerName
                                    }
                                />
                            </FormGroup>
                            {(job.contract ?? {}).operationsCenterName && (
                                <FormGroup>
                                    <FormLabel
                                        className="mb-1"
                                        htmlFor="operationsCenterName"
                                        text="Operations Center"
                                    />
                                    <input
                                        id="operationsCenterName"
                                        name="operationsCenterName"
                                        readOnly
                                        className="form-control-plaintext"
                                        defaultValue={
                                            (job.contract ?? {})
                                                .operationsCenterName
                                        }
                                    />
                                </FormGroup>
                            )}
                            
                            {(job.contract ?? {}).isUnionWork === true && (
                                <FormGroup>
                                    <FormLabel
                                        className="mb-1"
                                        htmlFor="schedule"
                                        text="Schedule"
                                    />
                                    <input
                                        id="schedule"
                                        name="schedule"
                                        readOnly
                                        className="form-control-plaintext"
                                        defaultValue={
                                            (job.contract ?? {})
                                                .contractScheduleTypeName
                                        }
                                    />
                                </FormGroup>
                            )}
                        </FormGroupColumn>
                    </GroupedRow>
                    <SubHeading>Setup</SubHeading>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                    <label
                                        htmlFor="weekOfDate"
                                        className="control-label required"
                                    >
                                        Week Of
                                    </label>
                                </div>

                                <ValidatedSelect
                                    id="weekOfDate"
                                    name="job.weekOfDate"
                                    options={weeks}
                                    value={
                                        weeks.find(
                                            (x) => x.value === job.weekOfDate,
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    validationMessage="A week start selection is required."
                                    isDisabled={!edit.allowWeekOfDate}
                                />
                            </FormGroup>
                        </FormGroupColumn>
                        <FormGroupColumn>
                            <FormGroup className="relative">
                                <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                    <label
                                        htmlFor="requestedBy"
                                        className="control-label required"
                                    >
                                        Requested By
                                    </label>
                                    {!job.hasAssignments && (
                                        <SmallButton
                                            color="outline-primary"
                                            className="floating ml-auto mr-0"
                                            onClick={() => this.onAddContact(
                                                'requestedById',
                                            )}
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                                            Add Contact
                                        </SmallButton>
                                    )}
                                </div>
                                <ValidatedSelect
                                    id="requestedBy"
                                    name="job.requestedById"
                                    options={companyContactsOptions}
                                    value={
                                        (companyContactsOptions ?? []).find(
                                            (x) => x.value
                                                === job.requestedById,
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    required
                                    validationMessage="A requested by selection is required."
                                    isDisabled={!edit.allowRequestedBy}
                                />
                                {!!job?.requestedById && (
                                    <div className="text-right pr-3">
                                        <SmallButton
                                            color="link"
                                            className="site-link w-auto text-right"
                                            onClick={() => this.onEditContact(
                                                'requestedById',
                                            )}
                                        >
                                            Edit Requestor Details
                                        </SmallButton>
                                    </div>
                                )}
                            </FormGroup>
                        </FormGroupColumn>
                    </GroupedRow>
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                    <label
                                        htmlFor="dispatchOfficeLocation"
                                        className="control-label required"
                                    >
                                        Dispatching
                                    </label>
                                </div>
                                <ValidatedSelect
                                    id="dispatchOfficeLocation"
                                    name="job.dispatchOfficeLocationId"
                                    options={dispatchLocations}
                                    value={
                                        (dispatchLocations ?? []).find(
                                            (x) => x.value
                                                === job.dispatchOfficeLocationId,
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    required
                                    validationMessage="A dispatch selection is required."
                                    isDisabled={!edit.allowJobLocation}
                                />
                            </FormGroup>
                        </FormGroupColumn>
                        <FormGroupColumn>
                            <FormGroup className="relative">
                                <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                    <label
                                        htmlFor="foremenId"
                                        className="control-label required"
                                    >
                                        Foreman
                                    </label>
                                    {!job.hasAssignments && (
                                        <SmallButton
                                            color="outline-primary"
                                            className="floating ml-auto mr-0"
                                            onClick={() => this.onAddContact(
                                                'foremenId',
                                            )}
                                        >
                                            <i className="fa fa-plus-circle fa-md mr-2" />
                                            Add Contact
                                        </SmallButton>
                                    )}
                                </div>
                                <ValidatedSelect
                                    id="foremen"
                                    name="job.foremenId"
                                    options={companyContactsOptions}
                                    value={
                                        (companyContactsOptions ?? []).find(
                                            (x) => x.value === job.foremenId,
                                        ) ?? ''
                                    }
                                    onChange={this.onSelectChanged}
                                    required
                                    validationMessage="A foremen selection is required."
                                    isDisabled={!edit.allowForemen}
                                />
                                {!!job?.foremenId && (
                                    <div className="w-100 text-right pr-3">
                                        <SmallButton
                                            color="link"
                                            className="site-link text-right"
                                            onClick={() => this.onEditContact(
                                                'foremenId',
                                            )}
                                        >
                                            Edit Foreman Details
                                        </SmallButton>
                                    </div>
                                )}
                            </FormGroup>
                            {(job.contract ?? {}).subcontractorsApply && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="subcontractor"
                                        text="Subcontractor"
                                        required={
                                            !!job.contract
                                                .subcontractorsApply
                                        }
                                    />
                                    <ValidatedSelect
                                        id="subcontractor"
                                        name="job.subcontractorCompanyId"
                                        options={customerSubcontractors}
                                        value={
                                            (
                                                customerSubcontractors ?? []
                                            ).find(
                                                (x) => x.value
                                                    === job.subcontractorCompanyId,
                                            ) ?? ''
                                        }
                                        onChange={(selection) => {
                                            const { value } = selection;
                                            this.setState(
                                                (state) => (
                                                    (state.job.subcontractorCompanyId = value),
                                                    state
                                                ),
                                            );
                                        }}
                                        required
                                        validationMessage="A subcontractor selection is required."
                                    />
                                </FormGroup>
                            )}
                        </FormGroupColumn>
                    </GroupedRow>
                    {(job.contract ?? {}).requireProjectInformation && (
                        <>
                            <GroupedRow>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="projectName"
                                            text="Project Name"
                                            required
                                        />
                                        <Input
                                            id="projectName"
                                            name="job.projectName"
                                            className="form-control"
                                            defaultValue={
                                                job.projectName ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                        />
                                        <small
                                            className="text-danger invalid-feedback"
                                        >
                                            Project Name is required.
                                        </small>
                                    </FormGroup>
                                </FormGroupColumn>
                                <FormGroupColumn>
                                    <FormGroup className="relative">
                                        <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                            <FormLabel
                                                htmlFor="projectManager"
                                                text="Project Manager"
                                                required
                                            />
                                            <SmallButton
                                                color="outline-primary"
                                                className="floating ml-auto mr-0"
                                                onClick={() => this.onAddContact(
                                                    'projectManagerId',
                                                )}
                                            >
                                                <i className="fa fa-plus-circle fa-md mr-2" />
                                                Add Contact
                                            </SmallButton>
                                        </div>
                                        <ValidatedSelect
                                            id="projectManager"
                                            name="job.projectManagerId"
                                            options={projectManagerContactOptions}
                                            value={
                                                (companyContactsOptions ?? []).find(
                                                    (x) => x.value === job.projectManagerId,
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                            required
                                            validationMessage="A Project Manager selection is required."
                                            isDisabled={!edit.allowProjectManagers}
                                        />
                                        {!!job?.projectManagerId && (
                                            <div className="w-100 text-right pr-3">
                                                <SmallButton
                                                    color="link"
                                                    className="site-link text-right"
                                                    onClick={() => this.onEditContact(
                                                        'projectManagerId',
                                                    )}
                                                >
                                                    Edit Project Manager Details
                                                </SmallButton>
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormGroupColumn>
                            </GroupedRow>
                        </>
                    )}
                    <FormDivider />
                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="startTime"
                                    text="Start Time"
                                    required
                                />
                                <input
                                    id="startTime"
                                    required
                                    type="time"
                                    className="form-control"
                                    name="job.startTime"
                                    defaultValue={job.startTime ?? ''}
                                    onChange={this.onChange}
                                    readOnly={!edit.allowStartTime}
                                />
                                <small className="invalid-feedback text-danger">
                                    Start Time is required.
                                </small>
                            </FormGroup>
                        </FormGroupColumn>
                        {job.contract.workOrderTypeId !== 3 && (
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="workOrderNumber"
                                        text="Work Order"
                                    />
                                    <input
                                        id="workOrderNumber"
                                        name="job.workOrderNumber"
                                        className="form-control"
                                        defaultValue={
                                            job.workOrderNumber ?? ''
                                        }
                                        onChange={this.onChange}
                                        readOnly={
                                            !edit.allowWorkOrderNumber
                                        }
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                        )}
                    </GroupedRow>

                    <GroupedRow>
                        <Col>
                            <FormGroup className="mb-0">
                                <FormLabel
                                    text="Resources"
                                    required
                                />
                                <div style={tableRequired}>
                                    <table
                                        className={cls(
                                            'table table-sm table-bordered',
                                            {
                                                'border-top-0':
                                                    this.props.noTopBorder,
                                            },
                                        )}
                                    >
                                        {this.renderResourceHeader(
                                            weekdays,
                                        )}
                                        <tbody>
                                            {!(job.resources ?? [])
                                                .length ? (
                                                    <tr key="emptyRow">
                                                        <td
                                                            colSpan="9"
                                                            className="text-center"
                                                        >
                                                            <small>
                                                            No resources
                                                            found for this
                                                            job.
                                                            </small>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    this.renderResources(
                                                        weekdays,
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                                {!isEmpty(tableRequired) && (
                                    <small className="text-danger">
                                        {resourceTableError}
                                    </small>
                                )}
                            </FormGroup>
                        </Col>
                    </GroupedRow>

                    {requestedPersonnelSectionAvailable && (
                        <GroupedRow>
                            <Col>
                                <FormGroup className="mb-0">
                                    <FormLabel
                                        text="Requested Personnel"
                                        required
                                    />
                                    <div style={tableRequired}>
                                        <table
                                            className={cls(
                                                'table table-sm table-bordered',
                                                {
                                                    'border-top-0':
                                                        this.props
                                                            .noTopBorder,
                                                },
                                            )}
                                        >
                                            {this.renderResourceHeader(
                                                weekdays,
                                                true,
                                            )}
                                            <tbody>
                                                {this.renderResources(
                                                    weekdays,
                                                    true,
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </FormGroup>
                            </Col>
                        </GroupedRow>
                    )}

                    {job.contract.setupChargesRequired
                        && (
                            <GroupedRow>
                                <FormGroup>
                                    <div style={{ marginLeft: '30px' }}>
                                        <Button
                                            size="sm"
                                            color="primary"
                                            name="jobForm"
                                            onClick={this.onAddSetupChargeClick}
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faPlus}
                                            />
                                            Add Setup Charge
                                        </Button>
                                    </div>
                                </FormGroup>
                            </GroupedRow>
                        )}

                    {job.contract.permitFeesApply
                        && (
                            <FormGroup style={{ marginBottom: '5px' }}>
                                <div style={{ marginLeft: '30px' }}>
                                    <FormCheckbox
                                        id="permitFeesApply"
                                        name="permitFeesApply"
                                        checked={
                                            job.permitFeesApply ?? ''
                                        }
                                        onChange={this.onPermitFeesApplyChange}
                                        labelText="Permit Fees Apply"
                                    />
                                </div>
                            </FormGroup>
                        )}

                    {job.contract.permitFeesApply && job.permitFeesApply
                        && (
                            <FormGroup>
                                <div style={{ marginLeft: '60px' }}>
                                    <Button
                                        size="sm"
                                        color="secondary"
                                        name="jobForm"
                                        onClick={this.onAddPermitFeesClick}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faPlus}
                                        />
                                        Add Permit Fees...
                                    </Button>
                                </div>
                            </FormGroup>
                        )}

                    <GroupedRow>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="meetingLocation"
                                    text="Meeting Location"
                                    required
                                />
                                {job.meetingLocation ? (
                                    <FlexStartRow>
                                        <SmallOutlineButton
                                            onClick={() => {
                                                this.onEditLocation(
                                                    'meetingLocation',
                                                );
                                            }}
                                            text="Edit"
                                            disabled={
                                                !edit.allowMeetingLocation
                                            }
                                        />

                                        <span className="form-control-plaintext">
                                            {job.meetingLocation.address1}
                                            {' '}

                                            {job.meetingLocation.city}
                                            {', '}

                                            {job.meetingLocation.state}
                                            {', '}

                                            {job.meetingLocation.zip}
                                            {' '}

                                            (<CountyLabel {...util.getCounty(this.state.usCounties, job.meetingLocation) || {}} />)
                                        </span>
                                    </FlexStartRow>
                                ) : (
                                    <Button
                                        type="button"
                                        color="secondary"
                                        size="sm"
                                        style={meetingLocationRequired}
                                        onClick={() => this.onAddLocation(
                                            'meetingLocation',
                                        )}
                                    >
                                        Add Meeting Location
                                    </Button>
                                )}
                                {!isEmpty(meetingLocationRequired) && (
                                    <small className="text-danger">
                                        Meeting location is required.
                                    </small>
                                )}
                            </FormGroup>
                        </FormGroupColumn>
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="jobLocation"
                                    text="Job Location"
                                    required
                                />
                                {job.jobLocation ? (
                                    <FlexStartRow>
                                        <SmallOutlineButton
                                            onClick={() => {
                                                this.onEditLocation(
                                                    'jobLocation',
                                                );
                                            }}
                                            text="Edit"
                                            disabled={
                                                !edit.allowJobLocation
                                            }
                                        />

                                        <span className="form-control-plaintext">
                                            {job.jobLocation.address1}
                                            {' '}

                                            {job.jobLocation.city}
                                            {', '}

                                            {job.jobLocation.state}
                                            {', '}

                                            {job.jobLocation.zip}
                                            {' '}

                                            (<CountyLabel {...util.getCounty(this.state.usCounties, job.jobLocation) || {}} />)
                                        </span>
                                    </FlexStartRow>
                                ) : (
                                    <Button
                                        type="button"
                                        color="secondary"
                                        size="sm"
                                        style={jobLocationRequired}
                                        onClick={() => {
                                            this.onAddLocation(
                                                'jobLocation',
                                            );
                                        }}
                                    >
                                        Add Job Location
                                    </Button>
                                )}
                                {!isEmpty(jobLocationRequired) && (
                                    <small className="text-danger">
                                        Job location is required.
                                    </small>
                                )}
                            </FormGroup>
                        </FormGroupColumn>
                    </GroupedRow>
                    <GroupedRow>
                        <Col>
                            <FormGroup>
                                <FormLabel htmlFor="notes" text="Notes" />
                                <textarea
                                    id="notes"
                                    name="job.notes"
                                    className="form-control"
                                    defaultValue={job.notes ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter notes to field staff here."
                                    type="text"
                                    maxLength="500"
                                    rows="5"
                                    readOnly={!edit.allowNotes}
                                />
                            </FormGroup>
                        </Col>
                    </GroupedRow>
                    {!!jobUploadsEnabled && (
                        <GroupedRow>
                            <Col>
                                <FormGroup>
                                    <FormLabel  
                                        text={
                                            <>
                                                Attach Files
                                                <small className="text-muted d-block mt-1">
                                                    Max 40MB | Supported: {supportedFiles}
                                                </small>
                                            </>
                                        }   
                                    />
                                    <FileUpload
                                        id="jobUploader"
                                        accept={supportedFiles}
                                        maxNameLength={150}
                                        url={ApiRoutes.job.postAttachments(
                                            job.id || this.state.uploadSessionId
                                        )}
                                        onUploadComplete={() => this.getAttachments()}
                                        files={job.uploads || []}
                                        onDownload={this.onDownloadAttachment}
                                        onDelete={this.onRemoveAttachment}
                                    />
                                </FormGroup>
                            </Col>
                        </GroupedRow>
                    )}
                    <FlexCenterRow>
                        <Button
                            size="sm"
                            type="submit"
                            color="primary"
                            name="jobForm"
                        >
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave}
                            />
                            {!existing
                                ? 'Save New Job'
                                : 'Save Job Details'}
                        </Button>
                    </FlexCenterRow>
                    <FlexCenterRow className="mb-3 mt-4">
                        {edit.allowDelete && (
                            <Button
                                size="sm"
                                type="button"
                                color="danger"
                                name="jobForm"
                                onClick={() => this.setState({ showDeleteModal: true })}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTrashAlt}
                                />
                                Delete Job
                            </Button>
                        )}
                    </FlexCenterRow>
                </AppPageForm>
                <AddressFormNew
                    id="jobAddressForm"
                    ref={this.addressFormRef}
                    contractUsesPrevailingWage={job.contract.prevailingWage}
                    countryId={job.contract.countryId}
                    countryCode={job.contract.countryCode}
                    onSaveCallback={(address) => this.onSaveAddress(address)}
                />
                <OrganizationContactForm
                    ref={this.contactFormRef}
                    show={showContactsForm}
                    readOnly={contactFormIsReadOnly}
                    allowContactTypeEdit={allowContactTypeEdit}
                    contact={selectedContact}
                    onClose={() => {
                        this.setState({ showContactsForm: false });
                    }}
                    onChange={(e) => {
                        const { selectedContact } = { ...this.state };
                        selectedContact[e.target.name] = e.target.value;
                        this.setState({
                            newContact: { ...selectedContact },
                        });
                    }}
                    onCheckedChanged={(e) => {
                        const { selectedContact } = { ...this.state };
                        selectedContact[e.target.name] = e.target.checked;
                        this.setState({
                            newContact: { ...selectedContact },
                        });
                    }}
                    onContactTypeChanged={(items) => {
                        const { selectedContact } = this.state;
                        selectedContact.contactTypes = items.map(
                            (x) => x.value,
                        );
                        this.setState({
                            newContact: { ...selectedContact },
                        });
                    }}
                    onSaveCallback={this.onOrganizationContactSaved}
                />
                <Modal isOpen={showDeleteModal}>
                    <ModalHeader>Delete Job</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this job?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.onDeleteConfirmed}
                        >
                            Ok
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => this.setState({ showDeleteModal: false })}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={showAddSetupCharge}>
                    <ModalHeader>Add Setup Charge</ModalHeader>
                    <ModalBody>
                        <table className="table table-sm table-bordered table-striped payroll-table">
                            <thead className="text-muted">
                                <tr>
                                    <th style={{ width: '50px' }} />
                                    <th className="invoiceHourlyCharge">
                                        Charge Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!unusedSetupChargeTypes?.length ? (
                                    <tr>
                                        <td
                                            className="text-center"
                                        >
                                            No Setup Charges Available
                                        </td>
                                    </tr>
                                ) : (

                                    unusedSetupChargeTypes?.map(
                                        (
                                            charge,
                                            charge_index,
                                        ) => {
                                            const id = `${charge.id}|${uuid()}`;

                                            return (
                                                <tr
                                                    key={id}
                                                >
                                                    <td style={{ textAlign: 'center' }}>

                                                        <input
                                                            id={id}
                                                            name={id}
                                                            type="checkbox"
                                                            checked={charge.isNew == true}
                                                            onChange={(x) => {
                                                                charge.isNew = x.currentTarget.checked;

                                                                this.setState({
                                                                    charge,
                                                                });

                                                                this.hydrateSetupCharges();
                                                            }}
                                                        />

                                                    </td>
                                                    <td className="invoiceHourlyResource">
                                                        <label htmlFor={id}>
                                                            {
                                                                charge.resourceName
                                                            }
                                                        </label>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )
                                )}
                            </tbody>
                        </table>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.onAddSetupCharge}
                        >
                            Done
                        </Button>
                    </ModalFooter>
                </Modal>

                {Boolean(currentJobOverride) && (
                    <JobOverrideModal
                        edit={edit}
                        open={showJobOverrideModal}
                        jobOverride={currentJobOverride}
                        setFormOpened={this.context.setFormOpened}
                        onClose={this.handleCloseJobOverride}
                        isReadOnly={this.overrideDayIsDisabled(currentJobOverride.overrideDate)}
                        setOverride={this.setPageModified}
                    />
                )}

                <Modal isOpen={showAddPermitFees}>
                    <ModalHeader>Add Permit Fees</ModalHeader>
                    <ModalBody>
                        <table className="table table-sm table-bordered table-striped payroll-table">
                            <thead className="text-muted">
                                <tr>
                                    <th style={{ width: '50px' }} />
                                    <th className="invoiceHourlyCharge">
                                        Charge Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!unusedSetupChargeTypes?.length ? (
                                    <tr>
                                        <td
                                            className="text-center"
                                        >
                                            No Permit Fees Available
                                        </td>
                                    </tr>
                                ) : (

                                    unusedPermitFees?.map(
                                        (
                                            charge,
                                            charge_index,
                                        ) => (
                                            <tr
                                                key={`${charge.id
                                                }|${uuid()}`}
                                            >
                                                <td style={{ textAlign: 'center' }}>

                                                    <input
                                                        type="checkbox"
                                                        checked={charge.isNew == true}
                                                        onChange={(x) => {
                                                            charge.isNew = x.currentTarget.checked;

                                                            this.setState({
                                                                charge,
                                                            });

                                                            this.hydrateSetupCharges();
                                                        }}
                                                    />

                                                </td>
                                                <td className="invoiceHourlyResource">
                                                    {
                                                        charge.resourceName
                                                    }
                                                </td>

                                            </tr>
                                        ),
                                    )
                                )}
                            </tbody>
                        </table>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.onAddPermitFees}
                        >
                            Done
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
export default withRouter(JobForm);
