import React, { useEffect, useState, useMemo } from 'react';
import { util } from '../Util';
import { ApiRoutes } from '../Common';
import { useParams } from 'react-router-dom';
import { AppPageForm, FormLabel, } from '../common/forms/FormElements';
import { Row, Col, FormGroup, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';
import CustomCircularProgress from '../common/CustomCircularProgress';

const CrewTimesheet = () => {
    const { timesheetId, employeeId } = useParams();
    const [timesheet, setTimesheet] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTimesheetDetails = async () => {
            setLoading(true);
            try {
                const response = await util.fetch.js(ApiRoutes.timesheet.crewDetails(timesheetId, employeeId));
                if (response) {
                    setTimesheet(response);
                } else {
                    console.error('Failed to fetch timesheet details: Invalid response.');
                }
            } catch (error) {
                console.error('Error fetching timesheet details:', error);
            }
            setLoading(false);
        };

        fetchTimesheetDetails();
    }, [timesheetId, employeeId]);

    const totalHours = useMemo(() => {
        if (timesheet.length === 0 || !timesheet.timesheetDetails) {
            return 0;
        }
        return timesheet.timesheetDetails.reduce((total, detail) => total + detail.value, 0);
    }, [timesheet]);

    if (loading) {
        return (
            <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                <CustomCircularProgress
                    color="secondary"
                    variant="indeterminate"
                    label="Getting timesheet..."
                />
            </div>
        );
    }

    if (timesheet.length === 0) {
        return <div>No timesheet data available.</div>;
    }

    return (
        <AppPageForm
            formShown={true}
            formId="employeeTimesheetForm"
            formHeadingIcon="faUserClock"
            formHeading="Crew Timesheet"
            formName="employeeTimesheetForm"
            viewOnly={true}
        >
            <Row className="self-dispatch-timesheet-content">
                <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto pt-2">
                    <FormGroup>
                        <FormLabel text="Status" />
                        <p className="form-control-plaintext">
                            Submitted on {new Date().toLocaleString()}
                        </p>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel text="Employee" />
                        <p className="form-control-plaintext">
                            {timesheet.timesheetDetails[0]?.employeeFullName}
                        </p>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel text="Date" />
                        <p className="form-control-plaintext">
                            {moment(timesheet.timesheetDetails[0]?.date).format('MM/DD/YYYY')}
                        </p>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel text="Timesheet Details" />
                        <ListGroup>
                            {timesheet.timesheetDetails.map((detail, index) => (
                                <ListGroupItem key={index} className="d-flex flex-row align-items-center">
                                    <div className="flex-fill">
                                        <strong>{detail.chargeTypeName}</strong>
                                        <p className="mb-0 text-muted">
                                            {detail.start} - {detail.end} ({detail.value} hours)
                                        </p>
                                    </div>
                                    <div>
                                        <p className="text-muted mb-0">
                                            Notes: {detail.notes || 'N/A'}
                                        </p>
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel text="Total" />
                        <p className="form-control-plaintext">
                            {totalHours.toFixed(2)} hours
                        </p>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel text="Employee Signature" />
                        {timesheet.timesheetReviews[0].reviewSignature?.signatureData ? (
                            <div className="border p-4 text-center">
                                <img
                                    src={timesheet.timesheetReviews[0].reviewSignature.signatureData}
                                    alt="Employee Signature"
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            </div>
                        ) : (
                            <p className="form-control-plaintext">No signature available</p>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </AppPageForm>
    );
};

export default CrewTimesheet;
