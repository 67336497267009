import { Alert } from 'reactstrap';
import './index.scss';

export const TipAlert = ({ containerClassName = '', label, tipText = 'Tip' }) => (
    <div className={containerClassName}>
        <Alert
            className="tip-alert"
            color="warning"
        >
            <small>
                {Boolean(tipText) && (
                    <>
                        <span className="tip-text">{tipText}</span>
                        <span>{': '}</span>
                    </>
                )}

                {label}
            </small>
        </Alert>
    </div>
);