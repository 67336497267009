import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ApiRoutes } from '../../Common';
import { util } from '../../Util';

const FileList = ({ files = [], jobId }) => {
    /**Handle File Download */
    const handleDownload = (file) => {
        if (!file || !jobId) return;

        const url = ApiRoutes.job.attachment(jobId); 
        util.fetch.downloadFile(url, file, file.name);
    };

    return (
        <div>
            {files.length === 0 ? (
                <p className="text-muted">No files available.</p>
            ) : (
                <>
                    {/* Desktop View: Links */}
                    <ul className="list-unstyled d-none d-md-block">
                        {files.map((file) => (
                            <li key={file.id || file.name} className="mb-2">
                                <Button
                                    color="link"
                                    className="site-link w-auto"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDownload(file);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                                    {file.name}
                                </Button>
                            </li>
                        ))}
                    </ul>
                    
                    {/* Mobile View: Compact Cards */}
                    <div className="d-block d-md-none">
                        {files.map((file) => (
                            <Card
                                key={file.id || file.name}
                                className="m-2 shadow-sm cursor-pointer p-2 d-flex"
                                onClick={() => handleDownload(file)}
                            >
                                <CardBody className="d-flex text-danger align-items-center p-2">
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="text-danger mr-3"
                                        size="lg"
                                    />
                                    <span className="small text-truncate">{file.name}</span>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default FileList;
