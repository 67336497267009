import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource, createGridOptions, DataGrid, indexCellRenderer, LinkCellRenderer, TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { EquipmentTypeForm } from './EquipmentTypeForm';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import { util } from '../Util';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';

class EquipmentTypeIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.eqTypeForm = React.createRef();

        this.state = {
            loading: true,
            roles: [],
            showEquipmentTypeForm: false,
        };

        this.onAddEquipmentType = this.onAddEquipmentType.bind(this);
        this.onEditEquipmentType = this.onEditEquipmentType.bind(this);
        this.onEquipmentTypeFormClosed = this.onEquipmentTypeFormClosed.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onAddEquipmentType = () => {
        this.eqTypeForm.current.open();
    };

    onEditEquipmentType = (id) => {
        this.eqTypeForm.current.open(id);
    };

    onEquipmentTypeFormClosed() {
        this.setState({ showEquipmentTypeForm: false });
    }

    onEquipmentTypeSaveCallback = (response) => {
        this.setState({ showEquipmentTypeForm: false });
        this.context.setFormOpened(false);
        // Refresh grid
        this.state.gridApi.setDatasource(this.state.dataSource);
    };

    async populateState() {
        const [groups, equipmentTypes] = await Promise.all([
            util.fetch.js(ApiRoutes.auth.groups()),
            util.fetch.js(ApiRoutes.equipmentType.all()),
        ]);

        // Get grid defaults and handlers
        const gridOptions = createGridOptions(this);

        // Specify any custom components, filtering
        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        const groupFilterParams = {
            suppressFilterButton: true,
            options: groups,
            optionsLabel: 'groupName',
            optionsValue: 'id',
        };
        const equipmentFilterParams = {
            suppressFilterButton: true,
            options: equipmentTypes,
            optionsLabel: 'description',
            optionsValue: 'id',
        };

        // Provide column definitions
        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 75,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Description',
                sortable: true,
                headerName: 'Description',
                field: 'description',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditEquipmentType,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this Equipment Type',
                },
            },
            {
                colId: 'Group',
                sortable: false,
                headerName: 'Eligible Roles',
                field: 'eligibleRoles',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: groupFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: groupFilterParams,
            },
            {
                colId: 'EquipmentType',
                sortable: false,
                headerName: 'Eligible Equipment',
                field: 'eligibleEquipment',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: equipmentFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: equipmentFilterParams,
            },
        ];

        // Create datasource.  Is reused and rebound on refresh from the state.
        const dataSource = createDataSource(ApiRoutes.equipmentType.search(), gridOptions);

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    render() {
        if (this.state.loading) return (<Progress />);

        const { rowData, gridOptions } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon icon={faScrewdriver} className="mr-2 text-muted" />
                    <span>Equipment Types</span>
                </PageHeading>
                <DataGridToolbar
                    entity="equipmenttype"
                    gridApi={this.state.gridApi}
                    dataSource={this.state.dataSource}
                    onAdd={this.onAddEquipmentType}
                    addLabel="Add Equipment Type"
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={this.state.gridStatus}
                />
                <EquipmentTypeForm
                    ref={this.eqTypeForm}
                    equipmentType={this.state.selectedEquipmentType}
                    onClose={this.onEquipmentTypeFormClosed}
                    show={this.state.showEquipmentTypeForm}
                    onSaveCallback={this.onEquipmentTypeSaveCallback}
                />
            </PageWrap>
        );
    }
}
export default withRouter(EquipmentTypeIndex);
