import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Button, FormGroup, Input, Progress } from 'reactstrap';
import { Prompt, useParams, useHistory } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { toast } from 'react-toastify';
import {
    faSave,
    faCheckCircle,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonContext, { ApiRoutes, AppNavPaths, ServiceResponseResult } from '../Common';
import {
    AppPageForm,
    FlexCenterRow,
    FormLabel,
    SubHeading,
    ToastMessage,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { util } from '../Util';
import NumericInput from '../common/forms/NumericInput';

const USStateForm = () => {
    const { id } = useParams(); // Get `id` from route params
    const history = useHistory(); // Navigation
    const formRef = useRef();
    const context = useContext(CommonContext);

    const [stateForm, setStateForm] = useState({
        id: null,
        name: '',
        abbreviation: '',
        perDiemRate: 0,
        workRate: 0,
    });

    const [originalData, setOriginalData] = useState(null); // For detecting unsaved changes
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [formValidated, setFormValidated] = useState(false); // To track validation status
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const populateState = async () => {
            try {
                setLoading(true);
                if (id) {
                    setIsEditMode(true);
                    const stateData = await util.fetch.js(ApiRoutes.USStates.getById(id));
                    setStateForm({
                        id: stateData.id,
                        name: stateData.name,
                        abbreviation: stateData.abbreviation,
                        perDiemRate: parseFloat(stateData.perDiemRate) || 0,
                        workRate: parseFloat(stateData.workRate) || 0,
                    });
                    setOriginalData({
                        id: stateData.id,
                        name: stateData.name,
                        abbreviation: stateData.abbreviation,
                        perDiemRate: parseFloat(stateData.perDiemRate) || 0,
                        workRate: parseFloat(stateData.workRate) || 0,
                    });
                } else {
                    setIsEditMode(false);
                    const initialData = {
                        id: null,
                        name: '',
                        abbreviation: '',
                        perDiemRate: 0,
                        workRate: 0,
                    };
                    setStateForm(initialData);
                    setOriginalData(initialData);
                }
            } catch (error) {
                console.error('Error populating state form:', error);
                toast.error(
                    <ToastMessage
                        icon={faExclamationTriangle}
                        header="Error"
                        message="Failed to load state data."
                    />
                );
            } finally {
                setLoading(false);
            }
        };

        void populateState();
    }, [id]);

    const onClearErrors = () => setErrors({});

    const validateForm = useCallback(() => {
        const newErrors = {};

        if (stateForm.perDiemRate == null) newErrors.perDiemRate = 'Per-diem rate is required.';
        if (stateForm.workRate == null) newErrors.workRate = 'Work rate is required.';

        setErrors(newErrors);
        setFormValidated(Object.keys(newErrors).length === 0);
        return Object.keys(newErrors).length === 0;
    }, [stateForm]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setStateForm((prev) => ({
            ...prev,
            [name]: parseFloat(value),
        }));
    }, []);

    const handleSubmit = useCallback(async () => {
        onClearErrors(); // Clear any previous errors

        if (!validateForm()) {
            toast.error(
                <ToastMessage
                    icon={faExclamationTriangle}
                    header="Validation Error"
                    message="Please fix the validation errors."
                />
            );
            return;
        }

        setSaving(true);

        try {
            let response;
            let updatedData = { ...stateForm };

            if (isEditMode) {
                // Update request (PUT)
                response = await util.fetch.put(ApiRoutes.USStates.update(stateForm.id), updatedData);
            } else {
                // Create request (POST)
                const { id, ...createPayload } = updatedData; // Remove `id` from payload for create
                response = await util.fetch.post(ApiRoutes.USStates.create(), createPayload);
            }

            if (response && response.result === ServiceResponseResult.Ok) {
                toast.success(
                    <ToastMessage
                        icon={faCheckCircle}
                        header="Success"
                        message="State saved successfully."
                    />
                );
                // Ensure `originalData` is updated so it matches the saved form state
                setOriginalData(updatedData);
                history.push(AppNavPaths.USStateIndex); // Navigate back to the index page
            } else {
                toast.error(
                    <ToastMessage
                        icon={faExclamationTriangle}
                        header="Error"
                        message={response?.message || 'An unknown error occurred.'}
                    />
                );
            }
        } catch (error) {
            console.error('Error saving state:', error);
            toast.error(
                <ToastMessage
                    icon={faExclamationTriangle}
                    header="Error"
                    message="Failed to save state. Please try again."
                />
            );
        } finally {
            setSaving(false);
        }
    }, [history, isEditMode, stateForm, validateForm]);

    if (loading) {
        return <Progress />;
    }

    return (
        <>
            <Prompt
                when={!saving && !isEqual(originalData, stateForm)}
                message="You have unsaved changes. Are you sure you want to leave?"
            />
            <AppPageForm
                formShown={context.formIsOpen}
                formId="stateForm"
                formRef={formRef}
                onSubmit={handleSubmit}
                formHeadingIcon={faSave}
                formHeading={isEditMode ? 'Edit US State' : 'Create US State'}
                formName="stateForm"
                saving={saving}
                setIsValidated={(value) => setFormValidated(value)}
                isValidated={formValidated}
                errors={errors}
                loading={loading}
            >
                <SubHeading first>State Details</SubHeading>
                <FormGroup>
                    <FormLabel htmlFor="name" text="State Name" />
                    <Input
                        id="name"
                        name="name"
                        className="form-control-plaintext bg-white"
                        readOnly
                        value={stateForm.name}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="abbreviation" text="Abbreviation" />
                    <Input
                        id="abbreviation"
                        name="abbreviation"
                        className="form-control-plaintext bg-white"
                        readOnly
                        value={stateForm.abbreviation}
                    />
                    {errors.abbreviation && (
                        <ValidationErrorMessage>{errors.abbreviation}</ValidationErrorMessage>
                    )}
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="perDiemRate" text="Per-Diem Rate" required />
                    <NumericInput
                        className="form-control form-control-sm"
                        name="perDiemRate"
                        onChange={handleInputChange}
                        value={stateForm.perDiemRate || ''}
                        min={0}
                        max={500}
                        type="number"
                        required
                        step={0.01}
                    />
                    {errors.perDiemRate && (
                        <ValidationErrorMessage>{errors.perDiemRate}</ValidationErrorMessage>
                    )}
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="workRate" text="Non-Union Work Rate" required />
                    <NumericInput
                        className="form-control form-control-sm"
                        name="workRate"
                        onChange={handleInputChange}
                        value={stateForm.workRate || ''}
                        min={0}
                        max={500}
                        type="number"
                        required
                        step={0.01}
                    />
                    {errors.workRate && (
                        <ValidationErrorMessage>{errors.workRate}</ValidationErrorMessage>
                    )}
                </FormGroup>

                <FlexCenterRow className="mt-3">
                    <Button color="primary" type="submit" disabled={saving}>
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                        {isEditMode ? 'Update State' : 'Create State'}
                    </Button>
                </FlexCenterRow>
            </AppPageForm>
        </>
    );
};

export default USStateForm;
