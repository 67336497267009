import { v4 as uuid } from 'uuid';

export class Address {
    constructor(countryId = null, countryCode = '') {
        if (countryId) {
            this.countryId = countryId;
        }
        if (countryCode) {
            this.countryCode = countryCode;
        }
    }

    id = uuid();

    name = '';

    address1 = '';

    address2 = '';

    city = '';

    state = '';

    zip = '';

    countryCode = '';

    longitude = null;

    latitude = null;

    type = {};

    addressFlat = '';

    duplicate = false;

    usCountyId = null;

    countryId = null;

    stateId = null;

    addressLine = function () {
        return `${this.address1}${this.address2 ? ` ${this.address2}` : ''} ${this.city}, ${this.state} ${this.zip}`;
    };
}