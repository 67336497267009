import React from 'react';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import {
    AppPageForm, FormLabel, onFieldChange, toasty, FormCheckbox,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { ExecutionHistory } from './common/ExecutionHistory';

class BaseAddChargesToTimesheet {
    timesheetId = '';
    isSurcharge = false;
    chargeTypeId = '';
    basedOnChargeTypeId = '';
}

class AddChargeTypeToTimesheetEquipment extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            addTimesheetCharges: new BaseAddChargesToTimesheet(),
            isValidated: false,
            selectedTimesheetOption: null,
            selectedChargeTypeOption: null,
            selectedBasedChargeTypeOption: null,
            chargeTypeEmpty: false,
            basedChargeTypeList: [],
        };

        this.onChange = this.onChange.bind(this);
    }

    onBaseChargeTypeChanged = (selectedOption) => {
        this.setState((prevState) => ({
            selectedBasedChargeTypeOption: selectedOption,
            addTimesheetCharges: {
                ...prevState.addTimesheetCharges,
                basedOnChargeTypeId: selectedOption ? selectedOption.value : null,
            },
        }));
    };

    onChange = onFieldChange;

    onChargeTypeChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            addTimesheetCharges: {
                ...prevState.addTimesheetCharges,
                chargeTypeId: selectedOption.value,
                isSurcharge: selectedOption.isSurcharge,
            },
            selectedChargeTypeOption: selectedOption,
        }));
    };

    onTimesheetChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            addTimesheetCharges: {
                ...prevState.addTimesheetCharges,
                timesheetId: selectedOption.value
            },
            selectedTimesheetOption: selectedOption,
        }));
    };

    onSubmit = async () => {
        const { addTimesheetCharges } = { ...this.state };
        const chargeTypeEmpty = !this.state.selectedChargeTypeOption;
        if (chargeTypeEmpty) {
            this.setState({ isValidated: true });
            toasty.error('Please fill in all required fields.');
            return;
        }

        this.setState({ saving: true });

        try {
            await util.fetch.post(ApiRoutes.adminTool.addChargeTypeToTimesheetEquipment(), addTimesheetCharges);

            this.setState({
                addTimesheetCharges: {
                    ...this.state.addTimesheetCharges,
                    timesheetId: '',
                    isSurcharge: false,
                    chargeTypeId: '',
                    basedOnChargeTypeId: '',
                },
                isValidated: false,
                selectedTimesheetOption: null,
                selectedChargeTypeOption: null,
                selectedBasedChargeTypeOption: null,
            });

            toasty.success('Add Charges to Timesheet Succeed');

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error('Error: Add Charges to Timesheet Failed. Please Check Form Inputs');
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    onSurchargeChanged = (e) => {
        const include = e.target.checked;
        const { addTimesheetCharges } = { ...this.state };
        addTimesheetCharges.isSurcharge = include;
        this.setState({ addTimesheetCharges });
    };

    onTimesheetNumberBlur = async (event) => {
        const { selectedTimesheetOption } = this.state;
        const timesheetId = selectedTimesheetOption ? selectedTimesheetOption.value : '';

        try {
            const basedChargeTypes = await util.fetch.js(
                ApiRoutes.chargeType.byTimesheetId(timesheetId),
            );

            if (!basedChargeTypes || basedChargeTypes.length === 0) {
                this.setState({
                    basedChargeTypeList: [],
                });
                return;
            }

            const basedChargeTypeOptions = basedChargeTypes.map((item) => ({
                value: item.id,
                label: item.description,
            }));

            this.setState({
                basedChargeTypeList: basedChargeTypeOptions
            });
        } catch (err) {
            this.setState({
                basedChargeTypeList: [],
            });
        }
    };

    getChargeType = async (chargeTypeId) => {
        const chargeType = await util.fetch.js(
            ApiRoutes.chargeType.byId(chargeTypeId),
        );
        return chargeType;
    };

    loadChargeTypeOptions = (inputValue, callback) => {
        fetch(ApiRoutes.typeAheads.chargeTypeSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                const options = data.map((item) => ({
                    ...item,
                    label: `${item.label} (${item.isBillable ? 'Billable' : 'Not Billable'})`,
                }));

                callback(options);
            });
    };

    loadTimesheetOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.timesheetNumberSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.customer}] - ${item.status}${item.isDeleted ? ' (Deleted)' : ''}`,
                    value: item.value,
                    isDeleted: item.isDeleted
                }));
            })
            .catch((error) => {
                console.error('Error fetching timesheet options:', error);
                return [];
            });
    };

    render() {
        const {
            loading, saving, isValidated,
            addTimesheetCharges, basedChargeTypeList, selectedBasedChargeTypeOption,
        } = this.state;

        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Add Charge Type to Timesheet (Equipment)"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNum" text="Timesheet Number (From)" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadTimesheetOptions}
                                        placeholder="Type Timesheet Number Here"
                                        id="timesheetNumber"
                                        value={this.state.selectedTimesheetOption}
                                        onChange={this.onTimesheetChanged}
                                        onBlur={this.onTimesheetNumberBlur}
                                        cacheOptions
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Timesheet Number is Required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="chargeType" text="Charge Type" required />
                                    <div className="d-flex flex-column">
                                        <AsyncSelect
                                            classNamePrefix="react-async"
                                            className="smallReactAsync"
                                            loadOptions={this.loadChargeTypeOptions}
                                            placeholder="Type Charge Type Here"
                                            id="chargeType"
                                            value={this.state.selectedChargeTypeOption}
                                            onChange={this.onChargeTypeChanged}
                                            cacheOptions
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="baseChargeType" text="Based on Charge Type" required />
                                    <ValidatedSelect
                                        id="baseChargeType"
                                        name="baseChargeType"
                                        required
                                        options={basedChargeTypeList}
                                        value={selectedBasedChargeTypeOption}
                                        onChange={this.onBaseChargeTypeChanged}
                                        validationMessage="A Based Charge Type selection is required."
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormCheckbox
                                        labelClass="text-danger text-small"
                                        className="mt-3 pl-1 mb-3"
                                        id="includeSurcharge"
                                        onChange={this.onSurchargeChanged}
                                        checked={addTimesheetCharges.isSurcharge}
                                        labelText="Include Surcharge"
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>  
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <List type="unstyled">
                            {record.input.timesheetNumber && (
                                <li>
                                    <strong>Timesheet:</strong>
                                    {' '}
                                    {record.input.timesheetNumber}
                                </li>
                            )}
                            {record.input.chargeType && (
                                <li>
                                    <strong>New Charge Type:</strong>
                                    {' '}
                                    {record.input.chargeType}
                                </li>
                            )}
                            {record.input.basedOnChargeType && (
                                <li>
                                    <strong>Based Charge Type:</strong>
                                    {' '}
                                    {record.input.basedOnChargeType}
                                </li>
                            )}
                            {record.input.isSurcharge !== undefined && (
                                <li>
                                    <strong>Surcharge:</strong>
                                    {' '}
                                    {record.input.isSurcharge ? 'Yes' : 'No'}
                                </li>
                            )}
                            {record.output?.billingId && (
                                <li>
                                    <strong>New Billing Details Created (ID):</strong>
                                    {' '}
                                    {record.output.billingId}
                                </li>
                            )}
                            {record.output?.billingMessage && (
                                <li>
                                    <strong>Billing Error:</strong>
                                    {' '}
                                    {record.output.billingMessage}
                                </li>
                            )}
                        </List>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(AddChargeTypeToTimesheetEquipment);
