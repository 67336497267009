import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../../Common';
import { util } from '../../Util';
import {
    AppPageForm, FormLabel, onFieldChange, SmallButton, toasty,
} from '../../common/forms/FormElements';
import ValidatedSelect from '../../common/forms/ValidatedSelect';
import { ExecutionHistory } from '../common/ExecutionHistory';
import { Upload } from './Upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const importOptions = [
    { value: 'VEHICLES', label: 'Vehicles' },
    { value: 'EMPLOYEES', label: 'Employees' },
    { value: 'CUSTOMERS', label: 'Customers' },
    { value: 'POSITIONS', label: 'Positions' },
];

class ImportCustomerDataViewModel {
    tenantId = null;
    mode = '';
}

class ImportCustomerData extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.uploadsRef = React.createRef();

        this.state = {
            formValidated: false,
            saving: false,
            importData: new ImportCustomerDataViewModel(),
            isValidated: false,
            selectedImportOption: null,
            selectedFile: null,
            tenants: [],
            selectedTenant: null,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        return this.populateState();
    }

    populateState = async () => {
        const [tenants] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.tenants()),
        ]);

        this.setState({
            tenants,
        });
    };

    onChange = onFieldChange;

    onSubmit = async () => {
        const { importData, selectedFile } = this.state;

        this.setState({ saving: true });

        try {
            var response = await util.fetch.postFile(
                ApiRoutes.adminTool.importCustomerData(),
                {
                    ...importData,
                    file: selectedFile,
                }
            );

            this.setState({
                importData: new ImportCustomerDataViewModel(),
                selectedImportOption: null,
                selectedTenant: null,
                isValidated: false,
            });

            if (response.status === 400) {
                toasty.error('Error importing customer data. Please check your upload file and try again.');
            } else {
                toasty.success('Customer data imported successfully!');

                // Refresh the table after successful submission
                await this.props.refreshUsageHistory();
            }
        } catch (err) {
            toasty.error('Customer data failed to upload');

            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    onImportOptionChanged = (selectedOption) => {
        this.setState((prevState) => ({
            selectedImportOption: selectedOption,
            importData: {
                ...prevState.importData,
                mode: selectedOption ? selectedOption.value : null,
            },
        }));
    };

    onTenantChanged = (selectedOption) => {
        this.setState((prevState) => ({
            selectedTenant: selectedOption,
            importData: {
                ...prevState.importData,
                tenantId: selectedOption ? selectedOption.value : null,
            },
        }));
    };

    render() {
        const {
            saving,
            isValidated,
            selectedImportOption,
            tenants,
            selectedTenant,
        } = this.state;

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Import Customer Data"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col className="bg-light border pt-2" xs="12">
                            <div>
                                <FormGroup>
                                    <FormLabel htmlFor="selectedTenant" text="Select a tenant" required />
                                    <ValidatedSelect
                                        id="selectedTenant"
                                        name="selectedTenant"
                                        required
                                        options={tenants}
                                        value={selectedTenant}
                                        onChange={this.onTenantChanged}
                                        validationMessage="Select a tenant to update"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel htmlFor="importOption" text="What kind of data are you importing?" required />
                                    <ValidatedSelect
                                        id="importOption"
                                        name="importOption"
                                        required
                                        options={importOptions}
                                        value={selectedImportOption}
                                        onChange={this.onImportOptionChanged}
                                        validationMessage="Select import behaviour"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel text="Attach File (.csv)" required />
                                    <Upload
                                        onDrop={([file]) => this.setState({ selectedFile: file })}
                                    />
                                </FormGroup>

                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={saving}
                                    >
                                        {!saving && <span>Submit</span>}
                                        {saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>
                                                    Submitting, please wait...
                                                </span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <List type="unstyled">
                            {record.input.TenantId && (
                                <li>
                                    <strong>Tenant ID:</strong>
                                    {' '}
                                    {record.input.TenantId}
                                </li>
                            )}

                            {record.input.Mode && (
                                <li>
                                    <strong>Import Type:</strong>
                                    {' '}
                                    {record.input.Mode}
                                </li>
                            )}

                            {record.input.FileName && (
                                <li>
                                    <strong>File:</strong>

                                    <SmallButton
                                        onClick={() => {
                                            const url = ApiRoutes.adminTool.getImportFile(record.id);
                                            util.fetch.downloadFile(url, null, record.input.FileName);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faDownload} color="text-primary" className="mr-1" />
                                        {record.input.FileName}
                                    </SmallButton>

                                </li>
                            )}

                            {Boolean(record.output.Succeeded) && (
                                <li>
                                    <strong>Succeded:</strong>

                                    {' '}
                                    {record.output.Succeeded}
                                </li>
                            )}

                            {Boolean(record.output.Failed) && (
                                <li>
                                    <strong>Failed:</strong>

                                    {' '}
                                    {record.output.Failed}
                                </li>
                            )}
                        </List>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(ImportCustomerData);
