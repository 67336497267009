import React, { Fragment } from 'react';
import { Alert, FormGroup, Input } from 'reactstrap';
import cls from 'classnames';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import { FormLabel } from '../common/forms/FormElements';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { CountyLabel } from '../uscounties/CountyLabel';

export default class AddressInput extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.address1Input = React.createRef();
        this.state = {
            usStates: [],
            countries: [],
            zipPattern: '',
        };
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }
 
    componentDidUpdate(prevProps) {
        // Detect changes to the countryId prop
        if (prevProps.address?.countryId !== this.props.address?.countryId) {
            this.populateState();
        }
    }

    async populateState() {
        if (this.props.showCountryInput) {
            const [countries] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.countries()),
            ]);

            this.setState({ countries });
        }

        if (this.props.address?.countryId) {
            const [usStates] = await Promise.all([
                util.fetch.js(ApiRoutes.typeAheads.USStatesbyCountryId(this.props.address.countryId)),
            ]);

            this.setState({ usStates, zipPattern: util.getZipPattern(this.props.address.countryId) });
        }
    }

    async handleCountryChange(selection) {
        const country = selection?.id || '';

        this.setState({ zipPattern: '', usStates: [] });

        if (country) {
            const usStates = await util.fetch.js(
                ApiRoutes.typeAheads.USStatesbyCountryId(country)
            );

            this.setState({ zipPattern: util.getZipPattern(country), usStates });
        }

        if (this.props.onCountryChanged) {
            this.props.onCountryChanged(selection);
        }
    }

    render() {
        const { address, namePrefix, isCountyCaptureEnabled, usCounties } = this.props;
        const { zipPattern, usStates, countries } = this.state;
        const prefixName = namePrefix ? `${namePrefix}.` : '';
        const colClass = cls(this.props.className ?? '', {
            'col-6': !this.props.single,
        });
        return (
            <>
                <FormGroup className={colClass}>
                    <FormLabel
                        htmlFor="address1"
                        text="Address Line 1"
                        required
                    />
                    <Input
                        id="address1"
                        name={`${prefixName}address1`}
                        ref={this.address1Input}
                        onFocus={(e) => {
                            e.currentTarget.select();
                        }}
                        value={address.address1 ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Address Line 1"
                        type="text"
                        required
                    />
                    <small className="invalid-feedback text-danger">
                        Address Line 1 is required.
                    </small>
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel htmlFor="address2" text="Address Line 2" />
                    <Input
                        id="address2"
                        name={`${prefixName}address2`}
                        value={address.address2 ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Address Line 2"
                        type="text"
                    />
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel htmlFor="city" text="City" required />
                    <Input
                        id="city"
                        name={`${prefixName}city`}
                        required
                        placeholder="Enter City"
                        value={address.city ?? ''}
                        onChange={this.props.onChange}
                        type="text"
                    />
                    <small className="invalid-feedback text-danger">
                        City is required.
                    </small>
                </FormGroup>

                {Boolean(this.props.showCountryInput) && (
                    <FormGroup className={colClass}>
                        <FormLabel htmlFor="country" text="Country" required />
                        <ValidatedSelect
                            id="country"
                            name={`${prefixName}country`}
                            required
                            options={countries}
                            value={
                                countries.find((s) => s.value === address.countryCode)
                                ?? ''
                            }
                            onChange={this.handleCountryChange}
                            validationMessage="A country selection is required."
                        />
                    </FormGroup>
                )}

                <FormGroup className={colClass}>
                    <FormLabel htmlFor="state" text="State" required />
                    <ValidatedSelect
                        id="state"
                        name={`${prefixName}state`}
                        required
                        options={usStates}
                        value={
                            usStates.find((s) => s.value === address.state)
                            ?? ''
                        }
                        onChange={this.props.onStateChanged}
                        isDisabled={!this.props.address.countryCode && this.props.showCountryInput}
                        validationMessage="A state selection is required."
                    />
                </FormGroup>
                <FormGroup className={colClass}>
                    <FormLabel
                        htmlFor="zip"
                        text="ZIP Code"
                        required
                    />
                    <Input
                        id="zip"
                        name={`${prefixName}zip`}
                        placeholder="Enter ZIP Code"
                        type="text"
                        pattern={zipPattern}
                        value={address.zip ?? ''}
                        onChange={this.props.onChange}
                        required
                    />
                    <small className="invalid-feedback text-danger">
                        ZIP Code is required.
                    </small>
                </FormGroup>

                {isCountyCaptureEnabled && (
                    <FormGroup className={colClass}>
                        <Input
                            type="hidden"
                            name={`${prefixName}usCountyId`}
                            value={address.usCountyId ?? ''}
                        />

                        <FormLabel htmlFor="countyName" text="County" />

                        <Alert color="info">
                            <CountyLabel {...util.getCounty(usCounties, address)} />
                        </Alert>
                    </FormGroup>
                )}
            </>
        );
    }
}
