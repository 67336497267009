import React, { Fragment } from 'react';
import {
    faCheckCircle, faExclamationTriangle, faSave, faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import {
    Button, Input, FormGroup, Col, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';
import {
    AppPageForm, FlexCenterRow, FormDivider, onFieldChange, onReactSelectChanged, SubHeading, ToastMessage,
    FormLabel,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths, ServiceResponseResult } from '../Common';
import { Role } from './Role';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';

class RoleForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            role: new Role(),
            permissions: [],
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        return this.populateState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    onChange = onFieldChange;

    onClearErrors = () => this.setState((state) => ({ errors: {} }));

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onDelete = async (e) => {
        const { role } = this.state;
        const response = await util.fetch.delete(ApiRoutes.role.delete(role.id)).catch(this.onCatchFetchError);
        if (response) this.onClose(response);
    };

    onRoleSaved = (response, redirect) => {
        if (response.result === ServiceResponseResult.Ok) {
            if (redirect) {
                toast.success(<ToastMessage
                    icon={faCheckCircle}
                    header="Save Successful"
                    message="Saved."
                />);
                this.props.history.push(`${AppNavPaths.Role}/${response.data}`);
            } else {
                toast.success(<ToastMessage
                    icon={faCheckCircle}
                    header="Save Successful"
                    message={`[${response.data.displayName}] saved.`}
                />);
                this.props.history.go(0);
            }

        } else {
            toast.error(<ToastMessage
                icon={faExclamationTriangle}
                header="There was a problem saving"
                message={`${response.message}`}
            />);
        }
    };

    onSelectChanged = onReactSelectChanged;

    onSubmit = async (e) => {
        // Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { role } = { ...this.state };

        this.props.location.pathname == AppNavPaths.RoleNew ? this.createRole(role) : this.updateRole(role);
    };

    setSaving = (b) => this.setState({ saving: b });

    createRole = async (role) => {
        const redirect = true;
        const response = await util.fetch.post(ApiRoutes.role.create(), role)
            .catch(this.handleSaveError);

        if (response) {
            this.onRoleSaved(response, redirect);
        }

        this.setSaving(false);

    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    async populateState() {
        const roleId = this.props.match.params.id;

        const [permissions, role] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.permissions()),
            roleId ? util.fetch.js(ApiRoutes.role.byId(roleId)) : new Role(),
        ]);

        const originalData = util.object.clone(role);

        this.setState((state) => ({
            originalData,
            role,
            loading: false,
            permissions,
            saving: false,
        }));
    }

    resetForm = () => this.setState({ formValidated: false });

    updateRole = async (role) => {
        const redirect = false;
        const response = await util.fetch.put(ApiRoutes.role.byId(role.id), role)
            .catch(this.handleSaveError);

        if (response) {
            this.onRoleSaved(response, redirect);
        }
        this.setSaving(false);
    };

    render() {
        const {
            role,
            originalData,
            errors,
            formValidated,
            permissions,
            saving,
        } = this.state;
        const existing = !!role.id;
        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, role)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId="roleForm"
                    formHeadingIcon={faWarehouse}
                    formHeading={!role.id ? 'New Role' : 'Edit Role'}
                    formName="roleForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <SubHeading>Details</SubHeading>
                    <Row className="pt-3">
                        <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto">
                            <FormGroup>
                                <FormLabel
                                    htmlFor="roleName"
                                    text="Name"
                                    required
                                />
                                <Input
                                    id="roleName"
                                    name="role.roleName"
                                    value={role.roleName ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Name is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="type" text="Permissions" required />
                                <ValidatedSelect
                                    id="types"
                                    name="role.permissions"
                                    required
                                    isMulti
                                    options={permissions}
                                    value={(permissions ?? []).filter((x) => (role.permissions ?? []).includes(x.value)) ?? ''}
                                    onChange={this.onSelectChanged}
                                    validationMessage="A user permission is required."
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormDivider />

                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="roleForm"
                        >
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave}
                            />
                            {this.state.saving
                                ? 'Saving...'
                                : (!existing ? 'Save New role' : 'Save')}
                        </Button>
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="button"
                            color="secondary"
                            className="ml-3"
                            onClick={() => this.props.history.push(AppNavPaths.Roles)}
                        >
                            <span className="mr-2 fa fa-long-arrow-alt-left" />
                            Back to Roles
                        </Button>
                    </FlexCenterRow>

                </AppPageForm>
            </>
        );
    }
}
export default withRouter(RoleForm);
