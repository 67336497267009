import React, { Component } from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import NavMenu from './NavMenu';
import 'react-toastify/dist/ReactToastify.css';
import CommonContext, { ApiRoutes } from './Common';
import authService from './api-authorization/AuthorizeService';
import { util } from './Util';
import { ApplyBranding } from './ApplyBranding';
import { HistoryWatcher } from './common/layout/HistoryWatcher';
import { Progress } from 'reactstrap';
import { Login } from './api-authorization/Login';
import { LoginActions } from './api-authorization/ApiAuthorizationConstants';
// import { Redirect } from 'react-router-dom';
// import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            common: {
                isAuthenticated: null,
                pageTitle: '',
                formIsOpen: false, // global bool for when the app has a modal form opened
                setFormOpened: this.setFormOpened, // universal handler to control global bool
                setPageTitle: this.setPageTitle,
                version: props.version,
            },
        };
        this.setFormOpened = this.setFormOpened.bind(this);
        this.setPageTitle = this.setPageTitle.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    setFormOpened = (value) => {
        const { common } = this.state;
        common.formIsOpen = value;
        this.setState((state) => ((state.common = common), state));
    };

    setPageTitle = (value) => {
        const { common } = this.state;
        const title = value;
        common.pageTitle = title;
        this.setState((state) => ((state.common = common), state));
    };

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        // if logged in, get the user details,
        // hydrate the context
        if (isAuthenticated) {
            const [tenant, userProfile] = await Promise.all([
                util.fetch.js(ApiRoutes.tenant.settings()),
                util.fetch.js(ApiRoutes.auth.profile()),
            ]);

            userProfile.isAdmin = (userProfile.roles ?? [])
                .map((x) => x.name)
                .includes('Administrator');

            const common = { ...this.state };

            common.isAuthenticated = isAuthenticated;
            common.user = userProfile;
            common.permissions = this.props.permissions;
            common.checkUserHasPermission = this.props.permissionChecker;
            common.formIsOpen = false;
            common.setFormOpened = this.setFormOpened;
            common.tenant = tenant;

            this.setState({
                isAuthenticated,
                common,
            });
        }
    };
   

    render() {
        const themeValues = (
            (this.state.common.tenant ?? {}).tenantSettings ?? {}
        ).defaultTheme;

        if ((!this.state.common?.user && this.state.common?.isAuthenticated) || this.state.isAuthenticated === null) {
            return <Progress />;
        }

        if (this.state.isAuthenticated === false) {
            return <Login action={LoginActions.Login} />;
        }

        return (
            <CommonContext.Provider value={this.state.common}>
                {themeValues && (
                    <ApplyBranding
                        colors={{
                            primary: themeValues.primaryColor,
                            secondary: themeValues.secondaryColor,
                            success: themeValues.successColor,
                            warning: themeValues.warningColor,
                            danger: themeValues.dangerColor,
                            information: themeValues.infoColor,
                            defaultHighlight: themeValues.defaultHighlightColor,
                            defaultHighlightAccent:
                                themeValues.defaultHighlightAccentColor,
                        }}
                    />
                )}
                <NavMenu />

                <main id="main" className="container-fluid p-0">
                    {this.props.children}
                </main>


                <ToastContainer
                    position="top-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    icon={false}
                />

                <HistoryWatcher />
            </CommonContext.Provider>
        );
    }
}
