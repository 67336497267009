import React from 'react';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    List,
} from 'reactstrap';
import { Spinner } from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { ExecutionHistory } from './common/ExecutionHistory';

class MoveTimesheetToContractVM {
    timesheetIds = [];
    contractId = 0;
    surcharges = [];
    subcontractor = 0;
}

class MoveTimesheetToContract extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            moveTimesheet: new MoveTimesheetToContractVM(),
            selectedTimesheetOption: [],
            selectedContractOption: null,
            selectedSubcontractorOption: null,
            isValidated: false,
            surchargeOptions: []
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onChange = onFieldChange;

    onSubmit = async () => {
        const { moveTimesheet } = { ...this.state };
        moveTimesheet.userId = this.context.user.employeeId;

        this.setState({ saving: true });

        try {
            await util.fetch.post(ApiRoutes.adminTool.moveTimesheetToContract(), moveTimesheet);

            this.setState({
                moveTimesheet: new MoveTimesheetToContractVM(),
                isValidated: false,
                selectedTimesheetOption: [],
                selectedContractOption: null,
                selectedSubContractorOption: null,
                selectedSurchargeOption: [],
            });

            toasty.success('Move Timesheet Succeeded');

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error('Error: Move Timesheet Failed. Please check Timesheet and Contract Number');
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    loadTimesheetOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.timesheetNumberSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.customer}] - ${item.status}${item.isDeleted ? ' (Deleted)' : ''}`,
                    value: item.value,
                    isDeleted: item.isDeleted
                }));
            })
            .catch((error) => {
                console.error('Error fetching timesheet options:', error);
                return [];
            });
    };

    onTimesheetChanged = async (selectedOptions) => {
        const timesheetIds = selectedOptions ? selectedOptions.map(option => Number(option.value)) : [];
        await this.setState((prevState) => ({
            moveTimesheet: {
                ...prevState.moveTimesheet,
                timesheetIds: timesheetIds
            },
            selectedTimesheetOption: selectedOptions,
        }));
    };

    loadContractOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.contractsSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.name}] ${item.status}`,
                    value: item.value
                }));
            })
            .catch((error) => {
                console.error('Error fetching contract options:', error);
                return [];
            });
    };

    onContractChanged = async (selectedOption) => {
        const contractId = selectedOption ? Number(selectedOption.value) : 0;
        await this.setState((prevState) => ({
            moveTimesheet: {
                ...prevState.moveTimesheet,
                contractId: contractId
            },
            selectedContractOption: selectedOption,
        }));
    };

    loadSurchargeOptions = async () => {
        try {
            const data = await util.fetch.js(ApiRoutes.typeAheads.surchargeChargeTypes());
            const options = data.map((item) => ({
                label: item.label,
                value: item.value,
            }));
            this.setState({ surchargeOptions: options });
        } catch (error) {
            console.error('Error fetching Surcharge Charge Type options:', error);
            this.setState({ surchargeOptions: [] });
        }
    };

    onSurchargeChanged = async (selectedOptions) => {
        const surcharges = selectedOptions ? selectedOptions.map(option => Number(option.value)) : [];
        await this.setState((prevState) => ({
            moveTimesheet: {
                ...prevState.moveTimesheet,
                surcharges: surcharges
            },
            selectedSurchargeOption: selectedOptions,
        }));
    };

    loadSubcontractorOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.subcontractorSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.active}]`,
                    value: item.value
                }));
            })
            .catch((error) => {
                console.error('Error fetching Subcontractor options:', error);
                return [];
            });
    };

    onSubcontractorChanged = async (selectedOption) => {
        const companyId = selectedOption ? Number(selectedOption.value) : 0;
        await this.setState((prevState) => ({
            moveTimesheet: {
                ...prevState.moveTimesheet,
                subcontractor: companyId
            },
            selectedSubContractOption: selectedOption,
        }));
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (isAuthenticated) {
            this.setState({ loading: true });
            await this.loadSurchargeOptions();
            this.setState({ loading: false });
        }
    }

    render() {
        const {
            loading, saving, isValidated,
        } = this.state;

        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Move Timesheet To a New Contract"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNum" text="Timesheet Numbers" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadTimesheetOptions}
                                        placeholder="Type Timesheet Numbers Here"
                                        id="timesheetNumber"
                                        value={this.state.selectedTimesheetOption}
                                        onChange={this.onTimesheetChanged}
                                        isMulti
                                    />
                                    <small className="invalid-feedback text-danger">
                                        At least one Timesheet Number is required.
                                    </small>
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="contractNum" text="Contract Number" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadContractOptions}
                                        placeholder="Type Contract Number Here"
                                        id="contractNum"
                                        value={this.state.selectedContractOption}
                                        onChange={this.onContractChanged}
                                        cacheOptions
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="surcharges" text="Surcharges (Disabled)" />
                                    <ValidatedSelect
                                        id="surcharges"
                                        name="surcharges"
                                        options={this.state.surchargeOptions}
                                        placeholder="Select one of more surcharges to add to the timesheet"
                                        value={this.state.selectedSurchargeOption}
                                        onChange={this.onSurchargeChanged}
                                        isMulti
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="subcontractorCompanyId" text="Subcontractor (Optional)" />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadSubcontractorOptions}
                                        placeholder="Enter subcontractor name"
                                        id="subcontractorCompanyId"
                                        value={this.state.selectedSubContractorOption}
                                        onChange={this.onSubcontractorChanged}
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <>
                            <div>
                                <strong>From:</strong>
                                <List type="unstyled">
                                    {record.input.input_timesheet_number && (
                                        <li>
                                            <strong>Timesheets:</strong>
                                            {' '}
                                            {record.input.input_timesheet_number.join(', ')}
                                        </li>
                                    )}
                                    {record.input.to_contract_number && (
                                        <li>
                                            <strong>To Contract:</strong>
                                            {' '}
                                            {record.input.to_contract_number}
                                        </li>
                                    )}
                                    {record.input.surcharges && record.input.surcharges.length > 0 && (
                                        <li>
                                            <strong>Surcharges:</strong>
                                            {' '}
                                            {record.input.surcharges.join(', ')}
                                        </li>
                                    )}
                                    {record.input.subcontractor && (
                                        <li>
                                            <strong>Subcontractor:</strong>
                                            {' '}
                                            {record.input.subcontractor}
                                        </li>
                                    )}
                                </List>
                            </div>


                            <div>
                                <strong>To:</strong>
                                <List type="unstyled">
                                    {record.output.moved_timesheet && (
                                        <li>
                                            <strong>Timesheet Moved:</strong>
                                            {' '}
                                            {record.output.moved_timesheet.join(', ')}
                                        </li>
                                    )}
                                    {Array.isArray(record.output.failed_Timesheet) && record.output.failed_Timesheet.length > 0 && (
                                        <li>
                                            <strong>Timesheet Move Failed:</strong>
                                            {' '}
                                            {record.output.failed_Timesheet.join(', ')}
                                        </li>
                                    )}
                                    {record.input.surcharges && record.input.surcharges.length > 0 ? (
                                        <li>
                                            <strong>Surcharges Added:</strong>
                                            {' '}
                                            {record.input.surcharges.join(', ')}
                                        </li>
                                    ) : (
                                        <li>
                                            <strong>Surcharges:</strong>
                                            {' No surcharges Added'}
                                        </li>
                                    )}
                                    {record.output.subcontractor && (
                                        <li>
                                            <strong>Subcontractor Added:</strong>
                                            {' '}
                                            {record.output.subcontractor}
                                        </li>
                                    )}
                                </List>
                            </div>
                        </>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(MoveTimesheetToContract);
