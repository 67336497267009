import { Table } from 'reactstrap';
import { formatDate } from '../admin.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export const ExecutionHistory = ({ usageHistory, children }) => (
    <>
        <h3 className="mt-4">
            Execution History
        </h3>

        <div className="flex-fill-container">
            <Table striped>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>User</th>
                        <th>Details</th>
                        <th>Execution Date & Time</th>
                        <th className="text-center">Result</th>
                    </tr>
                </thead>

                <tbody>
                    {usageHistory.map((record) => {
                        const errorMessage = record.output?.message;

                        return (
                            <tr key={record.id}>
                                <td>{record.id}</td>

                                <td>{record.userName}</td>

                                <td>
                                    <div>
                                        {children(record)}
                                    </div>

                                    {Boolean(errorMessage) && (
                                        <div>
                                            <strong>Error:</strong>
                                            {' '}
                                            {errorMessage}
                                        </div>
                                    )}
                                </td>

                                <td>{formatDate(record.executionDateTime)}</td>

                                <td className="text-center">
                                    {record.isSucceed ? (
                                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                                    ) : (
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="text-error" />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    </>

);