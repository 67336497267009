import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';

export const Upload = ({ onDrop }) => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        multiple: false,
        accept: {
            'text/csv': ['.csv'],
        },

    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <>
            {acceptedFileItems.length > 0 && (
                <aside>
                    <ul>{acceptedFileItems}</ul>
                </aside>
            )}

            <div {...getRootProps()}>
                <input {...getInputProps()} />

                <Button
                    type="button"
                    color="primary"
                    outline
                >
                    Click to select a file
                </Button>
            </div>
        </>
    );
};