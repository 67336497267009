import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Progress,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Spinner,
    Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faFileAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

const MAX_FILE_SIZE_MB = 40;

const FileUpload = ({ url, accept, files = [], onUploadComplete, onDownload, onDelete }) => {
    const [uploadedFiles, setUploadedFiles] = useState(files);
    const [uploadQueue, setUploadQueue] = useState([]);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [totalProgress, setTotalProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    // Track files that have already been uploaded
    const uploadedIdsRef = useRef(new Set());

    /** Keep `uploadedFiles` updated with parent prop */
    useEffect(() => {
        if (files && JSON.stringify(files) !== JSON.stringify(uploadedFiles)) {
            setUploadedFiles(files);
        }
    }, [files, uploadedFiles]); 

    /** Handle File Drop */
    const onDrop = useCallback((acceptedFiles) => {
        setIsDragging(false);
        setIsUploading(false);
        setErrorMessage(null);

        const existingFileNames = new Set(uploadedFiles.map(f => f.name.toLowerCase()));
        const existingUploadNames = new Set(uploadQueue.map(f => f.name.toLowerCase()));

        let duplicateFiles = [];
        let oversizedFiles = [];
        let validFiles = [];

        acceptedFiles.forEach((file) => {
            const fileName = file.name.toLowerCase();
            const isDuplicate = existingFileNames.has(fileName) || existingUploadNames.has(fileName);
            const isTooLarge = file.size > MAX_FILE_SIZE_MB * 1024 * 1024;
            
            if (isDuplicate) {
                duplicateFiles.push(file.name);
            } else if (isTooLarge) {
                oversizedFiles.push(file.name);
            } else {
                validFiles.push({
                    id: file.name + file.size,
                    file,
                    name: truncateFileName(file.name),
                    size: `${(file.size / 1024).toFixed(1)} KB`,
                    progress: 0,
                    uploaded: false,
                    error: null,
                });
            }
        });

        // Keep error message until user dismisses it
        let errorMessages = [];

        if (duplicateFiles.length > 0) {
            errorMessages.push(`Duplicate Files: ${duplicateFiles.join(', ')}`);
        }
        if (oversizedFiles.length > 0) {
            errorMessages.push(`Oversized Files (> ${MAX_FILE_SIZE_MB}MB): ${oversizedFiles.join(', ')}`);
        }

        if (errorMessages.length > 0) {
            setErrorMessage(errorMessages.join(' | '));
        }

        if (validFiles.length > 0) {
            setUploadQueue((prev) => [...prev, ...validFiles]);
            setIsUploading(true);
        }
    }, [uploadedFiles, uploadQueue]);

    /** Handle Upload Progress */
    const onProgressUpdated = useCallback((e, fileId) => {
        if (e.lengthComputable) {
            setUploadQueue((prevUploads) => {
                const updatedUploads = prevUploads.map((file) =>
                    file.id === fileId ? { ...file, progress: Math.round((e.loaded / e.total) * 100) } : file
                );

                // Calculate total progress (average of all active uploads)
                const total = updatedUploads.reduce((sum, file) => sum + (file.progress || 0), 0);
                const count = updatedUploads.length || 1;
                setTotalProgress(Math.round(total / count));

                return updatedUploads;
            });
        }
    }, []);

    /** Auto-start Uploads */
    useEffect(() => {
        if (uploadQueue.length === 0) {
            setIsUploading(false);
            setTotalProgress(0);
            uploadedIdsRef.current.clear();
            return;
        }

        uploadQueue.forEach((upload) => {
            if (!upload.uploaded && !upload.error && !uploadedIdsRef.current.has(upload.id)) {
                uploadedIdsRef.current.add(upload.id);

                const formData = new FormData();
                formData.append('file', upload.file);

                const xhr = new XMLHttpRequest();
                xhr.open('POST', url, true);
                xhr.upload.onprogress = (e) => onProgressUpdated(e, upload.id);

                xhr.onload = () => {
                    try {
                        if (xhr.status === 200) {
                            if (!xhr.responseText.trim()) { 
                                console.warn('Upload successful but received empty response.'); 
                                setUploadQueue((prev) => prev.filter((file) => file.id !== upload.id));
                                onUploadComplete(null);
                                return;
                            }
                
                            const uploadedFile = JSON.parse(xhr.responseText);
                            setUploadedFiles((prev) => [...prev, uploadedFile]);
                            setUploadQueue((prev) => prev.filter((file) => file.id !== upload.id));
                            onUploadComplete(uploadedFile);
                        } else {
                            console.error('Upload failed with status:', xhr.status, xhr.statusText);
                        }
                    } catch (error) {
                        console.error('Error parsing upload response:', error.message);
                    } finally {
                        setIsUploading(false);
                        setTotalProgress(0);
                    }
                };

                xhr.send(formData);
            }
        });
    }, [uploadQueue, url, onProgressUpdated, onUploadComplete]);

    /** Handle Delete */
    const handleDelete = useCallback(() => {
        if (fileToDelete) {
            onDelete(fileToDelete);
            
            setUploadedFiles((prev) => prev.filter((f) => f.id !== fileToDelete.id));
            setUploadQueue((prev) => prev.filter((f) => f.id !== fileToDelete.id));
            
            uploadedIdsRef.current.delete(fileToDelete.id);
            
            setTotalProgress(0);
            setFileToDelete(null);
        }
    }, [fileToDelete, onDelete]);

    const convertAcceptToMime = useCallback((acceptArray) => {
        const mimeMap = {
            '.gif': 'image/gif',
            '.jpg': 'image/jpeg',
            '.jpeg': 'image/jpeg',
            '.png': 'image/png',
            '.heic': 'image/heic',
            '.webp': 'image/webp',
            '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            '.xls': 'application/vnd.ms-excel',
            '.pdf': 'application/pdf',
            '.txt': 'text/plain',
            '.doc': 'application/msword',
            '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        };
    
        return acceptArray?.reduce((acc, ext) => {
            const trimmedExt = ext.trim();
            const mimeType = mimeMap[trimmedExt];
            if (mimeType) acc[mimeType] = [trimmedExt];
            return acc;
        }, {});
    }, []);

    const normalizeAccept = useCallback((accept) => {
        if (!accept) return [];
    
        if (Array.isArray(accept)) {
            return accept.map(ext => ext.trim().toLowerCase());
        }
    
        return accept.split(',').map(ext => ext.trim().toLowerCase());
    }, []);

    // Convert your accept string before passing it to Dropzone
    const normalizedAccept = normalizeAccept(accept);
    const formattedAccept = convertAcceptToMime(normalizedAccept);
    
    /** Dropzone Setup */
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: formattedAccept,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
    });

    return (
        <div className="file-upload-container">
            {/* Show Error Message Below Dropzone */}
            {errorMessage && (
                <Alert color="danger" toggle={() => setErrorMessage(null)}>
                    {errorMessage} <Button close onClick={() => setErrorMessage(null)} />
                </Alert>
            )}

            {/* Global Upload Progress */}
            {uploadQueue.length > 0 && (
                <div className="mb-3">
                    <Progress value={totalProgress} color="primary" className="global-upload-progress" />
                    <small>{totalProgress}% Uploading...</small>
                </div>
            )}

            <Row className="mt-3 align-items-center">
                {/* Upload Dropzone */}
                <Col md={3} xs={12} className="text-center mb-3 mb-md-0">
                    <div
                        {...getRootProps()}
                        className={`d-flex flex-column align-items-center justify-content-center border border-2 rounded-circle p-4 transition-all cursor-pointer ${
                            isDragging ? 'border-primary' : 'border-secondary border-dashed'
                        }`}
                        style={{
                            width: '160px',
                            height: '160px',
                        }}
                    >
                        <input {...getInputProps()} />
                        {isUploading ? (
                            <Spinner color="primary" />
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faUpload} size="2x" className="mb-2" />
                                <span>Upload Files</span>
                            </>
                        )}
                    </div>
                </Col>

                {/* File List */}
                <Col md={9} xs={12}>
                    {uploadedFiles.map((file) => (
                        <div key={file.id || file.name} className="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div>
                                <Button color="link" className="site-link p-0 text-truncate" onClick={() => onDownload(file)}>
                                    <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                                    {file.name || file.file?.name}
                                </Button>
                            </div>
                            <div>
                                {file.error ? (
                                    <div className="d-flex align-items-center">
                                        <span className="mr-2">{file.error}</span>
                                        <FontAwesomeIcon icon={faTimes} className="text-danger" />
                                    </div>
                                ) : file.progress !== undefined ? (
                                    <Progress value={file.progress} className="file-progress" color={file.progress === 100 ? 'success' : 'info'} />
                                ) : (
                                    <Button color="link" className="site-link p-0" onClick={() => setFileToDelete(file)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={!!fileToDelete} toggle={() => setFileToDelete(null)}>
                <ModalHeader toggle={() => setFileToDelete(null)}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete {fileToDelete?.name}?</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>Yes, Delete</Button>
                    <Button color="secondary" onClick={() => setFileToDelete(null)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

/** Helper Function: Truncate File Name */
const truncateFileName = (filename) => {
    const ext = filename.split('.').pop();
    return filename.length > 17 ? `${filename.substring(0, 14)}...${ext}` : filename;
};

export default FileUpload;
