import React from 'react';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormLabel,
    onFieldChange,
    toasty,
    FormCheckbox,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import './ManageTenantTheme.scss'; 
class TenantViewModel {
    constructor() {
        this.defaultColor = '';
        this.PrimaryColor = '';
        this.SecondaryColor = '';
        this.WarningColor = '';
        this.DangerColor = '';
        this.InfoColor = '';
        this.DefaultHighlightColor = '';
        this.DefaultHighlightAccentColor = '';
        this.SuccessColor = '';
        this.ShowBrandLabel = '';
    }
}

export class ManageTenantTheme extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const initialTenant = this.props.theme ?? new TenantViewModel();

        this.state = {
            theme: initialTenant,
            ...new BaseFormViewModel(),
        };

        this.onChange = this.onChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount() {
        const { show } = this.props;
        if (show) {
            this.open();
        }
    }

    onChange = onFieldChange;

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    onSubmit = async () => {
        const { theme } = this.state;
        this.setState({ errors: {}, saving: true });

        const url = ApiRoutes.tenant.themeById(theme.tenantCompanyId);
        const method = util.fetch.put;
        const response = await method(url, theme).catch(this.handleSaveError);

        if (response) {
            this.onClose();
            toasty.success('Theme Saved', `Theme saved successfully.`);
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (companyId) => {
        this.setState({ loading: true });
        this.resetForm();

        let theme;
        if (companyId) {
            theme = await util.fetch.js(ApiRoutes.tenant.getThemeById(companyId));
        } else {
            theme = new TenantViewModel();
        }

        this.setState({ theme }, () => {
            this.props.toggleShow(true);
            this.context.setFormOpened(true);
            this.setState({ loading: false });
        });
    };

    resetForm() {
        this.setState({
            formValidated: false,
        });
    }

    render() {
        const { formValidated, validationMessage, theme } = this.state;

        const formTitle =
            !!parseInt(theme.tenantCompanyId ?? 0) > 0
                ? 'Edit Company Theme'
                : 'Add Company Theme';

        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id="themeForm"
                formIcon={faBuilding}
                formTitle={formTitle}
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                errors={this.state.errors}
                validationMessage={validationMessage}
            >

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="primaryColor"
                        text="Primary Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="primaryColor"
                            name="theme.primaryColor"
                            value={theme.primaryColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the primary color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.primaryColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                        Primary Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="secondaryColor"
                        text="Secondary Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="secondaryColor"
                            name="theme.secondaryColor"
                            value={theme.secondaryColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the Secondary color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.secondaryColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                       Secondary Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="warningColor"
                        text="Warning Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="warningColor"
                            name="theme.warningColor"
                            value={theme.warningColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the warning color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.warningColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                        Warning Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="dangerColor"
                        text="Danger Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="dangerColor"
                            name="theme.dangerColor"
                            value={theme.dangerColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the Danger color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.dangerColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                        Danger Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="infoColor"
                        text="Info Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="infoColor"
                            name="theme.infoColor"
                            value={theme.infoColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the Info color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.infoColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                       Info Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="defaultHighlightColor"
                        text="Default Highlight Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="defaultHighlightColor"
                            name="theme.defaultHighlightColor"
                            value={theme.defaultHighlightColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the Default Highlight color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.defaultHighlightColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                        Default Highlight Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="defaultHighlightAccentColor"
                        text="Default Highlight Accent Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="defaultHighlightAccentColor"
                            name="theme.defaultHighlightAccentColor"
                            value={theme.defaultHighlightAccentColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the Default Highlight Accent color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor:
                                    theme.defaultHighlightAccentColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                        Default Highlight Accent Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                {/* Success Color */}
                <FormGroup className="form-group-flex">
                    <FormLabel
                        htmlFor="successColor"
                        text="Success Color"
                        required
                    />
                    <div className="color-input-container">
                        <Input
                            id="successColor"
                            name="theme.successColor"
                            value={theme.successColor || ''}
                            onChange={this.onChange}
                            placeholder="Enter the Success color"
                            type="text"
                            required
                        />
                        <div
                            className="color-preview"
                            style={{
                                backgroundColor: theme.successColor || '#ffffff',
                            }}
                        />
                    </div>
                    <ValidationErrorMessage>
                        Success Color is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormCheckbox
                        id="showBrandLabel"
                        name="theme.showBrandLabel"
                        type="checkbox"
                        checked={theme.showBrandLabel}
                        onChange={this.onChange}
                        labelText="Show Brand Label"
                    />
                </FormGroup>
            </SlideForm>
        );
    }
}
