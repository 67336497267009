import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup,
    Button,
    Badge,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Spinner,
} from 'reactstrap';
import * as moment from 'moment';
import { isEqual } from 'lodash-es';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    CollapseUnderlineHeader,
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    FlexStartRow,
    toasty,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { Can } from '../Can';
import { DispatchResourceSearchParams } from '../dispatch/Dispatch';
import { PayrollStatus } from '../payroll/Payroll';
import { PayrollCalculationEdit } from './PayrollCalculationEdit';
import { JobDetails } from '../job/Job';
import { CountyLabel } from '../uscounties/CountyLabel';
import TimePicker from '../common/forms/TimePicker';

export default class PayrollCalculationSlideout extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            originalDetails: '',
            originalAdjustments: JSON.stringify([]),
            payroll: [],
            adjustments: [],
            jobDetailsOpen: true,
            payrollCalculationOpen: true,
            reviewDetailsOpen: true,
            isPrinting: false,
            notesOpen: true,
            showRejectTimesheetModal: false,
            isRejectingTimesheet: false,
            rejectionNotes: '',
            jobAssignmentNotesOpen: true,
            current: {},
            edit: new PayrollCalculationEdit(),
            originalEdit: new PayrollCalculationEdit(),
            payValid: true,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onChange = onFieldChange;

    onClose = () => {
        const { saving, originalEdit, edit } = { ...this.state };
        if (!saving && !isEqual({ ...originalEdit }, { ...edit })) {
            const confirmed = window.confirm(
                'You have unsaved changes, are you sure you want to leave?',
            );
            if (!confirmed) return;
        }

        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onPrint = () => {
    // this.setState({ isPrinting: true });
        window.open(ApiRoutes.report.timesheet(this.state.id), '_self');

    // this.setState({ isPrinting: false });
    };

    onRefresh = (event) => {
        this.open(this.state.id);
    };

    onSelectChange = onReactSelectChanged;

    onSubmit = async () => {
        await this.setState({ saving: true });
        const { edit, payroll } = { ...this.state };
        if (payroll.payrollStatusId == PayrollStatus.Pending) {
            // Shouldnt be allowed to make some null value in start/end but notes required.

            if (!!edit.notes && edit.notes !== '' && edit.notes.length > 0) {
                const data = {
                    isPayrollChargeType: edit.isPayrollChargeType,
                    currentId: edit.currentId,
                    start: edit.isPayrollChargeType ? null : edit.start,
                    end: edit.isPayrollChargeType ? null : edit.end,
                    quantity: parseFloat(edit.quantity),
                    notes: edit.notes,
                    reg: 0.0,
                    rsd: 0.0,
                    ot: 0.0,
                    otsd: 0.0,
                    t15: 0.0,
                    t15SD: 0.0,
                    pr: 0.0,
                    prsd: 0.0,
                    vac: 0.0,
                    brv: 0.0,
                    hol: 0.0,
                    sck: 0.0,
                    prd: 0.0,
                    pw: 0.0,
                    pwot: 0.0,
                    pwt15: 0.0,
                    dt: 0.0,
                    dtsd: 0.0,
                };

                try {
                    await util.fetch.post(
                        ApiRoutes.payrollManagement.edit(),
                        data,
                    );
                    toasty.success('Edit saved.');
                    this.open(payroll.id);
                } catch {
                    toasty.error(
                        'There was an issue saving the edited record.  Please try your request again or contact support for assistance.',
                    );
                } finally {
                    this.setState({ saving: false });
                }
            }
        }

        if (payroll.payrollStatusId == PayrollStatus.Approved) {
            if (
                !!edit.notes
                && edit.notes !== ''
                && edit.notes.length > 0
                && this.payIsValid(edit) === true
            ) {
                const data = {
                    currentId: edit.currentId,
                    start: '',
                    end: '',
                    quantity: 0.0,
                    reg: parseFloat(edit.reg),
                    rsd: parseFloat(edit.rsd),
                    ot: parseFloat(edit.ot),
                    otsd: parseFloat(edit.otsd),
                    t15: parseFloat(edit.t15),
                    t15SD: parseFloat(edit.t15SD),
                    pr: parseFloat(edit.pr),
                    prsd: parseFloat(edit.prsd),
                    pw: parseFloat(edit.pw),
                    pwot: parseFloat(edit.pwot),
                    pwt15: parseFloat(edit.pwt15),
                    notes: edit.notes,
                    vac: parseFloat(edit.vac),
                    brv: parseFloat(edit.brv),
                    hol: parseFloat(edit.hol),
                    sck: parseFloat(edit.sck),
                    prd: parseFloat(edit.prd),
                    dt: parseFloat(edit.dt),
                    dtsd: parseFloat(edit.dtsd),
                };

                try {
                    await util.fetch.post(ApiRoutes.payroll.edit(), data);
                    toasty.success('Edit saved.');
                    this.open(payroll.id);
                } catch {
                    toasty.error(
                        'There was an issue saving the edited record.  Please try your request again or contact support for assistance.',
                    );
                } finally {
                    this.setState({ saving: false });
                }
            }
        }

        this.resetForm();
        this.setState({ saving: false });
    };

    onTimeEntryChanged = (ev) => {
        const { edit } = { ...this.state };
        const val = ev.target.value;
        const { name } = ev.target;
        // We display the options in 12-hour format, but the db has 24.
        const timeValue = ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';
        edit[name] = timeValue;
        this.setState({ edit });
        this.setQuantityForEdit();
    };

    setQuantityForEdit = () => {
        const { edit, payroll } = { ...this.state };

        // Calculate the duration between Start and End.  Factor in if this enters into the next day.
        // Use the job start date/time as opposed to moment defaulting to the current day when getting duration.
        if (!!edit.start && !!edit.end) {
            const time_format = 'HH:mm';
            const date_format = 'YYYY-MM-DD';
            const mStartDate = moment(payroll.jobStartTimeRaw);
            const mEndDate = moment(payroll.jobEndTimeRaw);

            const mStartTime = moment(edit.start, time_format);
            const mEndTime = moment(edit.end, time_format);
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
                mEndDate.add(1, 'day');
            }

            const dur = moment.duration(mEndTime.diff(mStartTime));

            edit.startDate = moment(
                `${mStartDate.format(date_format)} ${mStartTime.format(
                    time_format,
                )}`,
            ).valueOf();
            edit.endDate = moment(
                `${mEndDate.format(date_format)} ${mEndTime.format(
                    time_format,
                )}`,
            ).valueOf();
            edit.quantity = dur.asHours().toFixed(2);

            this.setState({ edit });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (payrollId) => {
    // reset adjustments and validation on the way back in.
        this.props.toggleShow(true);
        this.context.setFormOpened(true);
        await this.setState({
            loading: true,
            adjustments: [],
            formValidated: false,
            rejectionNotes: '',
            showRejectTimesheetModal: false,
        });
        await this.populateState();
        let { edit, originalEdit } = { ...this.state };

        const [payroll] = await Promise.all([
            util.fetch.js(ApiRoutes.payrollManagement.slideout(payrollId)),
        ]);

        const params = new DispatchResourceSearchParams();
        params.dispatchLocation = payroll.dispatchCompanyId;

        this.resetForm();

        // apply filter on payroll
        // if (!!this.props.onlyBillable && this.props.onlyBillable === true) {
        //    payroll.payrollCalculations = payroll.payrollCalculations.filter(x => x.isBillable == true);
        // }
        const current = payroll.payrollCalculations.filter(
            (x) => x.current == true,
        )[0];
        if (current) {
            edit.currentId = current.id;
            edit.start = current.startRaw;
            edit.end = current.endRaw;
            edit.increment = current.increment;
            edit.quantity = current.quantity;
            edit.isPayrollChargeType = current.isPayrollChargeType;
            if (payroll.payrollStatusId == PayrollStatus.Approved) {
                edit.pay = current.pay;
                edit.reg = current.reg;
                edit.rsd = current.rsd;
                edit.ot = current.ot;
                edit.otsd = current.otsd;
                edit.dt = current.dt;
                edit.dtsd = current.dtsd;
                edit.t15 = current.t15;
                edit.t15SD = current.t15SD;
                edit.pr = current.pr;
                edit.prsd = current.prsd;
                edit.pw = current.pw;
                edit.pwot = current.pwot;
                edit.pwt15 = current.pwT15;
                edit.brv = current.brv;
                edit.hol = current.hol;
                edit.prd = current.prd;
                edit.vac = current.vac;
                edit.sck = current.sck;
            }
            originalEdit = { ...edit };
        }

        await this.setState({
            id: payrollId,
            payroll,
            loading: false,
            current,
            originalEdit,
            edit,
        });
    };

    payIsValid = (edit) => {
        const sum = (parseFloat(edit.reg)
        + parseFloat(edit.rsd)
        + parseFloat(edit.ot)
        + parseFloat(edit.otsd)
        + parseFloat(edit.dt)
        + parseFloat(edit.dtsd)
        + parseFloat(edit.t15)
        + parseFloat(edit.t15SD)
        + parseFloat(edit.pr)
        + parseFloat(edit.prsd)
        + parseFloat(edit.pw)
        + parseFloat(edit.pwot)
        + parseFloat(edit.pwt15)
        + parseFloat(edit.brv)
        + parseFloat(edit.vac)
        + parseFloat(edit.hol)
        + parseFloat(edit.sck)
        + parseFloat(edit.prd)
        ).toFixed(2);

        const valid = sum === parseFloat(edit.pay).toFixed(2);

        this.setState({ payValid: valid });

        return valid;
    };

    async populateState() {
        await this.setState({
            edit: new PayrollCalculationEdit(),
        });
    }

    resetForm = () => {
        this.setState({
            formValidated: false,
        });
    };

    render() {
        const {
            payroll,
            formValidated,
            validationMessage,
            jobDetailsOpen,
            loading,
            payrollCalculationOpen,
            saving,
            showRejectTimesheetModal,
            isRejectingTimesheet,
            rejectionNotes,
            edit,
            payValid,
        } = this.state;

        const tenantSettings = ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};

        const jobDetailsHeader = tenantSettings
                && tenantSettings.showContractDescriptionInTimesheetView
                && payroll
                && payroll.jobName
            ? `Job Details - ${payroll.jobName}`
            : 'Job Details';

        return (
            <>
                <SlideForm
                    size="col-xl-12 col-md-12 col-xs-12"
                    loading={loading || !tenantSettings}
                    show={this.props.show}
                    id="payrollSlideout"
                    formIcon={faUserClock}
                    formTitle="Payroll Record"
                    ref={this.formRef}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    className="pb-2 w-100"
                    onSubmit={this.onSubmit}
                    onClose={this.onClose}
                    errors={this.state.errors}
                    onClearErrors={this.onClearErrors}
                    validationMessage={validationMessage}
                    readOnly={!!saving}
                >
                    <CollapseUnderlineHeader
                        headerText={jobDetailsHeader}
                        isOpen={jobDetailsOpen}
                        toggleCollapse={() => this.setState({ jobDetailsOpen: !jobDetailsOpen })}
                    >
                        <JobDetails
                            isSelfDispatching={payroll.isSelfDispatching}
                            isNonFlagging={payroll.isNonFlagging}
                            customerName={payroll?.customerName}
                            foremanName={payroll?.foremanName}
                            jobNotes={payroll?.jobNotes}
                            jobNumber={payroll?.jobNumber}
                            jobStartTime={payroll?.jobStartTime}
                            timesheetId={payroll?.timesheetId}
                            timesheetNumber={payroll?.timesheetNumber}
                            timesheetStatus={payroll?.timesheetStatus}
                            workOrderNumber={payroll?.workOrderNumber}
                            permissions={this.context?.permissions}
                            payrollEmergencyEmployee={
                                payroll?.payrollEmergencyEmployee
                            }
                            isPayrollEmergency={payroll?.isPayrollEmergency}
                            tenantSettings={tenantSettings}
                            isPayroll
                            payrollStatusId={payroll?.payrollStatusId}
                            onRefresh={this.onRefresh}
                            canEmergency={payroll?.canEmergency}
                            isClientCancelled={payroll?.isClientCancelled}
                            jobName={payroll?.jobName}
                        />

                        <div className="locationDetails">
                            <FormGroup>
                                <FormLabel
                                    htmlFor="meetingLocation"
                                    text="Meeting Location"
                                    className="bottom-border"
                                />
                                <FlexStartRow>
                                    {!!(payroll ?? {})
                                        .meetingLocationUrlFormatted && (
                                        <a
                                            rel="noopener noreferrer"
                                            className="text-white mr-2 btn btn-sm btn-success p-1"
                                            title="Open in Google Maps"
                                            href={util.getMapUrl((payroll ?? {}).meetingLocationUrlFormatted)}
                                            target="_blank"
                                        >
                                            <i className="fa fa-map-marked-alt" />
                                        </a>
                                    )}
                                    <span
                                        id="meetingLocation"
                                        name="meetingLocation"
                                    >
                                        {(payroll ?? {}).meetingLocation ?? '-'}
                                    </span>
                                </FlexStartRow>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="jobLocation"
                                    text="Job Location"
                                    className="bottom-border"
                                />
                                <FlexStartRow>
                                    {!!(payroll ?? {})
                                        .jobLocationUrlFormatted && (
                                        <a
                                            rel="noopener noreferrer"
                                            className="text-white mr-2 btn btn-sm btn-success p-1"
                                            title="Open in Google Maps"
                                            href={util.getMapUrl((payroll ?? {}).jobLocationUrlFormatted)}
                                            target="_blank"
                                        >
                                            <i className="fa fa-map-marked-alt" />
                                        </a>
                                    )}
                                    <span id="jobLocation" name="jobLocation">
                                        {(payroll ?? {}).jobLocation ?? '-'}
                                    </span>
                                </FlexStartRow>
                            </FormGroup>

                            {Boolean(payroll?.usCounty) && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="usCounty"
                                        text="County"
                                        className="bottom-border"
                                    />
                                    <FlexStartRow>
                                        <div>
                                            <CountyLabel {...payroll.usCounty} />
                                        </div>
                                    </FlexStartRow>
                                </FormGroup>    
                            )}
                        </div>
                    </CollapseUnderlineHeader>

                    <CollapseUnderlineHeader
                        headerText="Payroll Records"
                        isOpen={payrollCalculationOpen}
                        toggleCollapse={() => this.setState({
                            payrollCalculationOpen: !payrollCalculationOpen,
                        })}
                    >
                        {((payroll.payrollStatusId ?? {})
                            == PayrollStatus.Approved
                            || (payroll.payrollStatusId ?? {})
                                == PayrollStatus.Pending) && (
                            <FormGroup>
                                <FormLabel
                                    htmlFor="meetingLocation"
                                    text="Edit Current Record"
                                    className="bottom-border"
                                />
                                <FlexStartRow>
                                    {(payroll.payrollStatusId ?? {})
                                        == PayrollStatus.Pending && (
                                        <table className="table table-sm table-bordered table-striped adjustment-table">
                                            <thead className="text-muted">
                                                <tr>
                                                    <th className="payrollstart">
                                                        Start
                                                    </th>
                                                    <th className="payrollend">
                                                        End
                                                    </th>
                                                    <th className="payrollquantity">
                                                        Quantity
                                                    </th>
                                                    <th className="payrollnote">
                                                        Notes
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="adjustments-payroll">
                                                    <td className="payrollstart">
                                                        <TimePicker
                                                            required
                                                            disabled={edit.isPayrollChargeType}
                                                            name="start"
                                                            value={edit.start}
                                                            increment={
                                                                edit.increment
                                                            }
                                                            onChange={(ev) => this.onTimeEntryChanged(
                                                                ev,
                                                            )}
                                                        />
                                                    </td>
                                                    <td className="payrollend">
                                                        <TimePicker
                                                            required
                                                            disabled={edit.isPayrollChargeType}                                                            name="end"
                                                            value={edit.end}
                                                            increment={
                                                                edit.increment
                                                            }
                                                            onChange={(ev) => this.onTimeEntryChanged(
                                                                ev,
                                                            )}
                                                        />
                                                    </td>
                                                    {edit.isPayrollChargeType ? (
                                                        <td className="payrollqty">
                                                            <Input
                                                                required
                                                                type="number"
                                                                step={edit.increment}
                                                                name="quantity"
                                                                value={
                                                                    edit.quantity ?? ''
                                                                }
                                                                onChange={(evt) => {
                                                                    const { value } = evt.target;
                                                                    const { edit } = {
                                                                        ...this
                                                                            .state,
                                                                    };
                                                                    edit.quantity = parseFloat(
                                                                        value,
                                                                    ).toFixed(
                                                                        2,
                                                                    );
                                                                    this.setState({
                                                                        edit,
                                                                    });
                                                                }}
                                                                onBlur={(evt) => {
                                                                    const { value } = evt.target;
                                                                    const { edit } = {
                                                                        ...this
                                                                            .state,
                                                                    };
                                                                    edit.quantity = parseFloat(
                                                                        value,
                                                                    ).toFixed(
                                                                        2,
                                                                    );
                                                                    this.setState({
                                                                        edit,
                                                                    });
                                                                }}
                                                            />
                                                        </td>
                                                    ) : (<td className="payrollqty">
                                                        {parseFloat(
                                                            edit.quantity,
                                                        ).toFixed(2)}
                                                    </td>)}  

                                                    <td className="payrollnotes">
                                                        <FormGroup className="mb-0">
                                                            <Input
                                                                required
                                                                type="textarea"
                                                                name="notes"
                                                                value={
                                                                    edit.notes
                                                                    ?? ''
                                                                }
                                                                onChange={(
                                                                    evt,
                                                                ) => {
                                                                    const { value } = evt
                                                                        .target;
                                                                    const {
                                                                        edit,
                                                                    } = {
                                                                        ...this
                                                                            .state,
                                                                    };
                                                                    edit.notes = value;
                                                                    this.setState(
                                                                        {
                                                                            edit,
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                            <small className="invalid-feedback text-danger">
                                                    Adjustment notes
                                                    are required.
                                                            </small>
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                    {(payroll.payrollStatusId ?? {})
                                        == PayrollStatus.Approved && (
                                        <table className="table table-sm table-bordered table-striped adjustment-table">
                                            {payroll.isPayrollTimesheet ? (
                                                <>
                                                    <thead className="text-muted">
                                                        <tr>
                                                            <th className="payrollpay">
                                                                Pay
                                                            </th>
                                                            <th className="payrollbrv">
                                                                BRV
                                                            </th>
                                                            <th className="payrollhol">
                                                                HOL
                                                            </th>
                                                            <th className="payrollsck">
                                                                SCK
                                                            </th>
                                                            <th className="payrollvac">
                                                                VAC
                                                            </th>
                                                            <th className="payrollprd">
                                                                Notes
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="adjustments-payroll">
                                                            <td className="payrollpay">
                                                                <FormGroup className="mb-0">
                                                                    {parseFloat(
                                                                        edit.pay,
                                                                    ).toFixed(2)}
                                                                    {(!!payValid
                                                                    ?? {})
                                                                    == false && (
                                                                        <small className="text-danger">
                                                                            Category sum
                                                                            does not
                                                                            match the
                                                                            Pay.
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </td>
                                                            <td className="payrollbrv">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="brv"
                                                                    value={
                                                                        edit.brv ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.brv = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.brv = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollhol">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="hol"
                                                                    value={
                                                                        edit.hol ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.hol = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.hol = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollsck">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="sck"
                                                                    value={
                                                                        edit.sck ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.sck = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.sck = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollvac">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="vac"
                                                                    value={
                                                                        edit.vac ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.vac = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.vac = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>

                                                            <td className="payrollnotes">
                                                                <FormGroup className="mb-0">
                                                                    <Input
                                                                        required
                                                                        type="textarea"
                                                                        name="notes"
                                                                        value={edit.notes ?? ''}
                                                                        onChange={(
                                                                            evt,
                                                                        ) => {
                                                                            const { value } = evt
                                                                                .target;
                                                                            const {
                                                                                edit,
                                                                            } = {
                                                                                ...this
                                                                                    .state,
                                                                            };
                                                                            edit.notes = value;
                                                                            this.setState(
                                                                                {
                                                                                    edit,
                                                                                },
                                                                            );
                                                                        }}
                                                                    />
                                                                    <small className="invalid-feedback text-danger">
                                                                        Adjustment notes are required.
                                                                    </small>
                                                                </FormGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                            ) : (
                                                <>
                                                    <thead className="text-muted">
                                                        <tr>
                                                            <th className="payrollpay">
                                                                Pay
                                                            </th>
                                                            <th className="payrollreg">
                                                                REG
                                                            </th>
                                                            <th className="payrollrsd">
                                                                RSD
                                                            </th>
                                                            <th className="payrollot">
                                                                OT
                                                            </th>
                                                            <th className="payrollotsd">
                                                                OTSD
                                                            </th>
                                                            <th className="payrollt15">
                                                                T15
                                                            </th>
                                                            <th className="payrollt15SD">
                                                                T15SD
                                                            </th>

                                                            {Boolean(tenantSettings.enableAlternativePayrollBilling) && (
                                                                <>
                                                                    <th className="payrollpr">
                                                                        PR
                                                                    </th>
                                                                    <th className="payrollprsd">
                                                                        PRSD
                                                                    </th>
                                                                </>
                                                            )}

                                                            {Boolean(tenantSettings.enableDoubleTimePayroll) && (
                                                                <>
                                                                    <th className="payrolldt">
                                                                        DT
                                                                    </th>

                                                                    <th className="payrolldtsd">
                                                                        DTSD
                                                                    </th>
                                                                </>
                                                            )}

                                                            {Boolean(tenantSettings.enablePrevailingWageContracts) && (
                                                                <>
                                                                    <th className="payrollpw">
                                                                        PW
                                                                    </th>
                                                                    <th className="payrollpwot">
                                                                        PWOT
                                                                    </th>
                                                                    <th className="payrollpwt15">
                                                                        PWT15
                                                                    </th>
                                                                </>
                                                            )}

                                                            <th className="payrollnote">
                                                                Notes
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="adjustments-payroll">
                                                            <td className="payrollpay">
                                                                <FormGroup className="mb-0">
                                                                    {parseFloat(
                                                                        edit.pay,
                                                                    ).toFixed(2)}
                                                                    {(!!payValid
                                                                ?? {})
                                                                == false && (
                                                                        <small className="text-danger">
                                                                            Category sum
                                                                            does not
                                                                            match the
                                                                            Pay.
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </td>
                                                            <td className="payrollreg">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="reg"
                                                                    value={
                                                                        edit.reg ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.reg = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.reg = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollrsd">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="rsd"
                                                                    value={
                                                                        edit.rsd ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.rsd = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.rsd = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollot">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="ot"
                                                                    value={
                                                                        edit.ot ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.ot = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.ot = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollotsd">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="otsd"
                                                                    value={
                                                                        edit.otsd ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.otsd = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.otsd = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollt15">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="t15"
                                                                    value={
                                                                        edit.t15 ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.t15 = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.t15 = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="payrollt15SD">
                                                                <Input
                                                                    required
                                                                    type="number"
                                                                    step="0.01"
                                                                    name="t15SD"
                                                                    value={
                                                                        edit.t15SD ?? ''
                                                                    }
                                                                    onChange={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.t15SD = value; // parseFloat(value).toFixed(2);
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                    onBlur={(evt) => {
                                                                        const { value } = evt.target;
                                                                        const { edit } = {
                                                                            ...this
                                                                                .state,
                                                                        };
                                                                        edit.t15SD = parseFloat(
                                                                            value,
                                                                        ).toFixed(
                                                                            2,
                                                                        );
                                                                        this.setState({
                                                                            edit,
                                                                        });
                                                                    }}
                                                                />
                                                            </td>

                                                            {Boolean(tenantSettings.enableAlternativePayrollBilling) && (
                                                                <>
                                                                    <td className="payrollpr">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="pr"
                                                                            value={
                                                                                edit.pr ?? ''
                                                                            }
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pr = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pr = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="payrollprsd">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="prsd"
                                                                            value={
                                                                                edit.prsd ?? ''
                                                                            }
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.prsd = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.prsd = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}

                                                            {Boolean(tenantSettings.enableDoubleTimePayroll) && (
                                                                <>
                                                                    <td className="payrolldt">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="dt"
                                                                            value={
                                                                                edit.dt ?? ''
                                                                            }
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.dt = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.dt = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="payrolldtsd">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="dtsd"
                                                                            value={
                                                                                edit.dtsd ?? ''
                                                                            }
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.dtsd = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.dtsd = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}

                                                            {Boolean(tenantSettings.enablePrevailingWageContracts) && (
                                                                <>
                                                                    <td className="payrollpw">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="pw"
                                                                            value={
                                                                                edit.pw ?? ''
                                                                            }
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pw = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pw = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="payrollpwot">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="pwot"
                                                                            value={
                                                                                edit.pwot ?? ''
                                                                            }
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pwot = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pwot = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>

                                                                    <td className="payrollpwt15">
                                                                        <Input
                                                                            required
                                                                            type="number"
                                                                            step="0.01"
                                                                            name="pwt15"
                                                                            value={edit.pwt15 ?? ''}
                                                                            onChange={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pwt15 = value; // parseFloat(value).toFixed(2);
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                            onBlur={(evt) => {
                                                                                const { value } = evt.target;
                                                                                const { edit } = {
                                                                                    ...this
                                                                                        .state,
                                                                                };
                                                                                edit.pwt15 = parseFloat(
                                                                                    value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                );
                                                                                this.setState({
                                                                                    edit,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}

                                                    
                                                            <td className="payrollnotes">
                                                                <FormGroup className="mb-0">
                                                                    <Input
                                                                        required
                                                                        type="textarea"
                                                                        name="notes"
                                                                        value={edit.notes ?? ''}
                                                                        onChange={(
                                                                            evt,
                                                                        ) => {
                                                                            const { value } = evt
                                                                                .target;
                                                                            const {
                                                                                edit,
                                                                            } = {
                                                                                ...this
                                                                                    .state,
                                                                            };
                                                                            edit.notes = value;
                                                                            this.setState(
                                                                                {
                                                                                    edit,
                                                                                },
                                                                            );
                                                                        }}
                                                                    />
                                                                    <small className="invalid-feedback text-danger">
                                                                        Adjustment notes are required.
                                                                    </small>
                                                                </FormGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                            )}
                                            
                                        </table>
                                    )}
                                </FlexStartRow>
                            </FormGroup>
                        )}
                        <div className="payroll">
                            <FormLabel
                                htmlFor="meetingLocation"
                                text="History"
                                className="bottom-border"
                            />
                            {(payroll.payrollStatusId ?? {})
                                == PayrollStatus.Pending && (
                                <table className="table table-sm table-bordered table-striped payroll-table">
                                    <thead className="text-muted">
                                        <tr>
                                            {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                <th className="isadjusted" />
                                            )}
                                            <th className="payrollcharge">
                                        Charge
                                            </th>
                                            <th className="payrollname">
                                        Name
                                            </th>
                                            <th className="payrollnote">
                                        Notes
                                            </th>
                                            <th className="payrollstart">
                                        Start
                                            </th>
                                            <th className="payrollend">End</th>
                                            <th className="payrollqty">
                                        Quantity
                                            </th>
                                            <th className="payrollunits">
                                        Units
                                            </th>
                                            <th className="payrollbillable">
                                        Billable
                                            </th>
                                            <th className="payrollbreak">
                                        Break
                                            </th>
                                            <th className="payrollCurrent">
                                        Current
                                            </th>
                                            {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                <Can do="accept" on="timesheet">
                                                    <th className="payrolldelete" />
                                                </Can>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!(payroll.payrollCalculations ?? [])
                                            .length ? (
                                                <tr>
                                                    <td
                                                        colSpan="5"
                                                        className="text-center"
                                                    >
                                            No payroll found.
                                                    </td>
                                                </tr>
                                            ) : (
                                                payroll.payrollCalculations.map(
                                                    (detail, detail_index) => (
                                                        <tr
                                                            key={detail.id}
                                                            className={`timesheet-payroll-table-row${
                                                                detail.removed
                                                                    ? ' strikeout'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                <td className="isadjusted">
                                                                    {!!detail.isAdjustment && (
                                                                        <Badge className="p-1">
                                                  ADJ
                                                                        </Badge>
                                                                    )}
                                                                </td>
                                                            )}
                                                            <td className="payrollcharge">
                                                                {
                                                                    detail.chargeTypeName
                                                                }
                                                            </td>
                                                            <td className="payrollname">
                                                                {
                                                                    detail.employeeName
                                                                }
                                                            </td>
                                                            <td className="payrollnote">
                                                                {!!detail.note && (
                                                                    <div className="userNote">
                                                                        <div className="noteContent">
                                                                            {
                                                                                detail.note
                                                                            }
                                                                        </div>
                                                                        <div className="noteContentByLine">
                                                                            {`${
                                                                                detail.modifiedBy
                                                                            }, ${moment(
                                                                                detail.modifiedOn,
                                                                            ).fromNow()}`}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="payrollstart">
                                                                {detail.start}
                                                            </td>
                                                            <td className="payrollend">
                                                                {detail.end}
                                                            </td>
                                                            <td className="payrollqty">
                                                                {parseFloat(
                                                                    detail.quantity,
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td className="payrollunits">
                                                                {
                                                                    detail.unitsName
                                                                }
                                                            </td>
                                                            <td className="payrollbillable">
                                                                {
                                                                    detail.billable
                                                                }
                                                            </td>
                                                            <td className="payrollbreak">
                                                                {detail.isBreak
                                                                    ? detail.isPaidBreak
                                                                        ? 'Paid'
                                                                        : 'Unpaid'
                                                                    : ''}
                                                            </td>
                                                            <td className="payrollcurrent">
                                                                {detail.current
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </td>
                                                        </tr>
                                                    ),
                                                )
                                            )}
                                    </tbody>
                                </table>
                            )}
                            {((payroll.payrollStatusId ?? {})
                                == PayrollStatus.Approved
                                || (payroll.payrollStatusId ?? {})
                                    == PayrollStatus.Exported) && (
                                <table className="table table-sm table-bordered table-striped payroll-table">
                                    {payroll.isPayrollTimesheet ? (
                                        <>
                                            <thead className="text-muted">
                                                <tr>
                                                    {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                        <th className="isadjusted" />
                                                    )}
                                                    <th className="payrollcharge">
                                                        Charge
                                                    </th>
                                                    <th className="payrollname">
                                                        Name
                                                    </th>
                                                    <th className="payrollnote">
                                                        Notes
                                                    </th>
                                                    <th className="payrollcharge">
                                                        Charge
                                                    </th>
                                                    <th className="payrollpay">Pay</th>
                                                    <th className="payrollbrv">BRV</th>
                                                    <th className="payrollhol">HOL</th>
                                                    <th className="payrollsck">SCK</th>
                                                    <th className="payrollvac">VAC</th>
                                                    <th className="payrollCurrent">
                                                        Current
                                                    </th>
                                                    {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                        <Can do="accept" on="timesheet">
                                                            <th className="payrolldelete" />
                                                        </Can>
                                                    )}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {!(payroll.payrollCalculations ?? [])
                                                    .length ? (
                                                        <tr>
                                                            <td
                                                                colSpan="5"
                                                                className="text-center"
                                                            >
                                                                No payroll found.
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        payroll.payrollCalculations.map(
                                                            (detail, detail_index) => (
                                                                <tr
                                                                    key={detail.id}
                                                                    className={`timesheet-payroll-table-row${
                                                                        detail.removed
                                                                            ? ' strikeout'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                        <td className="isadjusted">
                                                                            {!!detail.isAdjustment && (
                                                                                <Badge className="p-1">
                                                                                    ADJ
                                                                                </Badge>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                    <td className="payrollcharge">
                                                                        {
                                                                            detail.chargeTypeName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollname">
                                                                        {
                                                                            detail.employeeName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollnote">
                                                                        {!!detail.note && (
                                                                            <div className="userNote">
                                                                                <div className="noteContent">
                                                                                    {
                                                                                        detail.note
                                                                                    }
                                                                                </div>
                                                                                <div className="noteContentByLine">
                                                                                    {`${
                                                                                        detail.modifiedBy
                                                                                    }, ${moment(
                                                                                        detail.modifiedOn,
                                                                                    ).fromNow()}`}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td className="payrollcharge">
                                                                        {parseFloat(
                                                                            detail.charge,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollpay">
                                                                        {parseFloat(
                                                                            detail.pay,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollbrv">
                                                                        {parseFloat(
                                                                            detail.brv,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollhol">
                                                                        {parseFloat(
                                                                            detail.hol,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollsck">
                                                                        {parseFloat(
                                                                            detail.sck,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollvac">
                                                                        {parseFloat(
                                                                            detail.vac,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    
                                                                    <td className="payrollcurrent">
                                                                        {detail.current
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        )
                                                    )}
                                            </tbody>
                                        </>
                                    ) : (
                                        <>
                                            <thead className="text-muted">
                                                <tr>
                                                    {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                        <th className="isadjusted" />
                                                    )}
                                                    <th className="payrollcharge">
                                            Charge
                                                    </th>
                                                    <th className="payrollname">
                                            Name
                                                    </th>
                                                    <th className="payrollnote">
                                            Notes
                                                    </th>
                                                    <th className="payrollcharge">
                                            Charge
                                                    </th>
                                                    <th className="payrollpay">Pay</th>
                                                    <th className="payrollreg">REG</th>
                                                    <th className="payrollrsd">RSD</th>
                                                    <th className="payrollot">OT</th>
                                                    <th className="payrollotsd">
                                            OTSD
                                                    </th>
                                                    <th className="payrollt15">T15</th>
                                                    <th className="payrollt15SD">
                                            T15SD
                                                    </th>

                                                    {Boolean(tenantSettings.enableAlternativePayrollBilling) && (
                                                        <>
                                                            <th className="payrollpr">PR</th>
                                                            <th className="payrollprsd">
                                                        PRSD
                                                            </th>
                                                        </>
                                                    )}

                                                    {Boolean(tenantSettings.enableDoubleTimePayroll) && (
                                                        <>
                                                            <th className="payrolldt">DT</th>
                                                            <th className="payrolldtsd">
                                                        DTSD
                                                            </th>
                                                        </>
                                                    )}

                                                    {Boolean(tenantSettings.enablePrevailingWageContracts) && (
                                                        <>
                                                            <th className="payrollpw">PW</th>
                                                            <th className="payrollpwot">
                                                        PWOT
                                                            </th>
                                                            <th className="payrollpwt15">
                                                        PWT15
                                                            </th>
                                                        </>
                                                    )}
                                            
                                                    <th className="payrollbillable">
                                            Billable
                                                    </th>
                                                    <th className="payrollbreak">
                                            Break
                                                    </th>
                                                    <th className="payrollCurrent">
                                            Current
                                                    </th>
                                                    {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                        <Can do="accept" on="timesheet">
                                                            <th className="payrolldelete" />
                                                        </Can>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!(payroll.payrollCalculations ?? [])
                                                    .length ? (
                                                        <tr>
                                                            <td
                                                                colSpan="5"
                                                                className="text-center"
                                                            >
                                                                No payroll found.
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        payroll.payrollCalculations.map(
                                                            (detail, detail_index) => (
                                                                <tr
                                                                    key={detail.id}
                                                                    className={`timesheet-payroll-table-row${
                                                                        detail.removed
                                                                            ? ' strikeout'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                                        <td className="isadjusted">
                                                                            {!!detail.isAdjustment && (
                                                                                <Badge className="p-1">
                                                                                    ADJ
                                                                                </Badge>
                                                                            )}
                                                                        </td>
                                                                    )}
                                                                    <td className="payrollcharge">
                                                                        {
                                                                            detail.chargeTypeName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollname">
                                                                        {
                                                                            detail.employeeName
                                                                        }
                                                                    </td>
                                                                    <td className="payrollnote">
                                                                        {!!detail.note && (
                                                                            <div className="userNote">
                                                                                <div className="noteContent">
                                                                                    {
                                                                                        detail.note
                                                                                    }
                                                                                </div>
                                                                                <div className="noteContentByLine">
                                                                                    {`${
                                                                                        detail.modifiedBy
                                                                                    }, ${moment(
                                                                                        detail.modifiedOn,
                                                                                    ).fromNow()}`}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td className="payrollcharge">
                                                                        {parseFloat(
                                                                            detail.charge,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollpay">
                                                                        {parseFloat(
                                                                            detail.pay,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollreg">
                                                                        {parseFloat(
                                                                            detail.reg,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollrsd">
                                                                        {parseFloat(
                                                                            detail.rsd,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollot">
                                                                        {parseFloat(
                                                                            detail.ot,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollotsd">
                                                                        {parseFloat(
                                                                            detail.otsd,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollt15">
                                                                        {parseFloat(
                                                                            detail.t15,
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className="payrollt15SD">
                                                                        {parseFloat(
                                                                            detail.t15SD,
                                                                        ).toFixed(2)}
                                                                    </td>

                                                                    {Boolean(tenantSettings.enableAlternativePayrollBilling) && (
                                                                        <>
                                                                            <td className="payrollpr">
                                                                                {parseFloat(
                                                                                    detail.pr,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                            <td className="payrollprsd">
                                                                                {parseFloat(
                                                                                    detail.prsd,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                        </>
                                                                    )}

                                                                    {Boolean(tenantSettings.enableDoubleTimePayroll) && (
                                                                        <>
                                                                            <td className="payrolldt">
                                                                                {parseFloat(
                                                                                    detail.dt,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                            <td className="payrolldtsd">
                                                                                {parseFloat(
                                                                                    detail.dtsd,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                        </>
                                                                    )}

                                                                    {Boolean(tenantSettings.enablePrevailingWageContracts) && (
                                                                        <>
                                                                            <td className="payrollpw">
                                                                                {parseFloat(
                                                                                    detail.pw,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                            <td className="payrollpwot">
                                                                                {parseFloat(
                                                                                    detail.pwot,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                            <td className="payrollpwt15">
                                                                                {parseFloat(
                                                                                    detail.pwT15,
                                                                                ).toFixed(2)}
                                                                            </td>
                                                                        </>
                                                                    )}

                                                                    <td className="payrollbillable">
                                                                        {
                                                                            detail.billable
                                                                        }
                                                                    </td>
                                                                    <td className="payrollbreak">
                                                                        {detail.isBreak
                                                                            ? detail.isPaidBreak
                                                                                ? 'Paid'
                                                                                : 'Unpaid'
                                                                            : ''}
                                                                    </td>
                                                                    <td className="payrollcurrent">
                                                                        {detail.current
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        )
                                                    )}
                                            </tbody>
                                        </>
                                    )}
                                </table>
                            )}
                        </div>
                    </CollapseUnderlineHeader>
                </SlideForm>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showRejectTimesheetModal}
                >
                    <ModalHeader>Reject Timesheet</ModalHeader>
                    <ModalBody>
                        <Form id="timeheetRejectionForm">
                            <FormGroup>
                                <FormLabel
                                    required
                                    text="Notes"
                                />
                                <textarea
                                    disabled={!!saving}
                                    id="timesheetRejectionNotes"
                                    name="timesheetRejectionNotes"
                                    className="form-control"
                                    defaultValue={rejectionNotes ?? ''}
                                    onChange={this.onChange}
                                    required
                                    placeholder="Enter notes regarding the rejection."
                                    type="text"
                                    maxLength="500"
                                    rows="5"
                                />
                                <small className="text-danger">
                  Notes are required.
                                </small>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {isRejectingTimesheet && (
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin mr-2"
                                size="sm"
                            />
                        )}
                        <Button
                            className="d-flex flex-row flex-nowrap align-items-center"
                            color={saving ? 'secondary' : 'primary'}
                            disabled={
                                !!saving || !!loading || !!isRejectingTimesheet
                            }
                            onClick={this.rejectTimesheet}
                        >
                            {!!this.state.saving && (
                                <>
                                    <Spinner
                                        size="sm"
                                        className="saving-button-progress text-success mr-2"
                                    />
                                    <span>Saving, please wait...</span>
                                </>
                            )}
                            {!this.state.saving && (
                                <span>Save and Reject Timesheet</span>
                            )}
                        </Button>
                        <Button
                            disabled={!!saving}
                            color="secondary"
                            onClick={() => {
                                if (!saving) {
                                    this.setState({
                                        selectedRowRejectionNotes: '',
                                        showRejectTimesheetModal: false,
                                    });
                                }
                            }}
                        >
              Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
