import React, { Fragment } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Row,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    Alert,
    Spinner,
} from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { toast } from 'react-toastify';
import {
    faExclamationTriangle,
    faPlus,
    faSave,
    faTimes,
    faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import CommonContext, {
    ApiRoutes,
    AppNavPaths,
    MomentDateFormat,
    MomentTimeFormat,
    ServiceResponseResult,
    TimesheetReviewType,
} from '../Common';
import {
    AppPageForm,
    FlexBetweenRow,
    FlexCenterRow,
    FlexColumnCenter,
    FlexColumnStart,
    FlexEndRow,
    FlexStartRow,
    FormBlocker,
    FormLabel,
    FormValidated,
    onFieldChange,
    onReactSelectChanged,
    PageWrap,
    ToastMessage,
    toasty,
} from '../common/forms/FormElements';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import authService from '../api-authorization/AuthorizeService';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import { NotAuthorizedPage } from '../status/StatusCodes';
import NumericInput from '../common/forms/NumericInput';
// import cls from 'classnames';
import './SelfDispatchTimesheetForm.scss';
import SignatureButton from '../common/forms/SignatureButton';
import {
    SelfDispatchTimesheet,
    SelfDispatchTimesheetDetailViewModel,
    SelfDispatchTimesheetReviewViewModel,
} from './SelfDispatching';
import { TimesheetSignatureType } from './ClientReview';
import { ClientTimesheetSignature } from './ClientTimesheetSignature';
import { TimesheetStatus } from './Timesheet';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { ChargeTypeUnits } from '../chargeType/ChargeType';
import SurveyQuestion, {
    onSelectAnswerChanged,
    onSelectChanged,
    onTextAnswerChanged,
} from '../formsAndSurveys/SurveyQuestion';
import TimePicker from '../common/forms/TimePicker';

const ContractNotSetUpPage = ({
    nonFlagging,
}) => (
    <PageWrap>
        <FlexColumnCenter>
            <span className="display-1 d-block text-danger text-center">
                {nonFlagging
                    ? 'Non-Flagging Contract Not Created'
                    : 'Self-Dispatching Contracts Not Created'}
            </span>
            <span className="h4 text-danger text-center">
        The Administrator has not yet performed setup for
        Non-Flagging timesheets. Please wait until this task is
        complete before trying to enter Non-Flagging time in the
        system.
            </span>
            <a href="/" className="btn btn-link">
        Back to Home
            </a>
        </FlexColumnCenter>
    </PageWrap>
);

class SelfDispatchTimesheetForm extends React.Component {
    // #region FORM SETUP
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.addTimeFormRef = React.createRef();
        this.employeeSignatureRef = React.createRef();
        this.clientSignatureRef = React.createRef();
        this.state = {
            contracts: [],
            equipmentOptions: [],
            timesheet: null,
            loading: true,
            saving: false,
            showAddChargeModal: false,
            currentTimeEntry: null,
            addTimeFormValidated: false,
            editable: false,
            clientSigIsComplete: false,
            equipmentLoading: false,
            errors: [],
        };
        this.handlers.change = this.handlers.change.bind(this);
        this.handlers.select = this.handlers.select.bind(this);
        this.onDetailTextAnswerChanged = this.onDetailTextAnswerChanged.bind(this);
        this.onDetailSelectAnswerChanged = this.onDetailSelectAnswerChanged.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps
            && this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    onAddTimeClicked = () => this.setState({
        editingTimeIndex: null,
        showAddChargeModal: true,
        selectedDetail: new SelfDispatchTimesheetDetailViewModel(),
        addTimeFormValidated: false,
    });

    onAddTimeSubmit = (event) => {
        const {
            timesheet, selectedDetail, editingTimeIndex, editable,
        } = {
            ...this.state,
        };

        if (!editable) return null;

        const creatingNew = Boolean(Number.isNaN(parseInt(editingTimeIndex)));

        if (selectedDetail.chargeTypeUnitsName === 'Hours') {
            // Validate the entry itself.  A valid range will have a duration > 0.
            const rangeDuration = this.getDuration(
                timesheet.date,
                selectedDetail.start,
                selectedDetail.end,
                selectedDetail.breakStart,
                selectedDetail.breakEnd,
                false,
            );
            if (rangeDuration <= 0) {
                toasty.error('Please enter a valid time range.');
                return false;
            }

            // If we are not editing, attempt to push the entry.
            if (creatingNew) {
                // RLC: Check if new entry will overlap.
                if (
                    !this.timeEntryOverlaps({ ...selectedDetail }, [
                        ...timesheet.timeEntries,
                    ])
                ) {
                    selectedDetail.value = rangeDuration;
                    timesheet.timeEntries.push({ ...selectedDetail });
                } else {
                    toasty.error(
                        'This time entry overlaps entries you\'ve already added for this date.',
                    );
                    return false;
                }

                // Otherwise, check to see if the edit will cause a time overlap.
            } else {
                // RLC: Spread and splice, to avoid mutating state while we check the newly modified entry.
                //     Remove the edited entry from the array first, only compare against the other entries.
                const toCheck = [...timesheet.timeEntries];
                toCheck.splice(editingTimeIndex, 1);

                // Don't check flat charges.
                const entriesToCheck = (toCheck ?? []).filter(
                    (x) => x.chargeTypeUnitsId === ChargeTypeUnits.Hours,
                );

                if (
                    !this.timeEntryOverlaps({ ...selectedDetail }, [
                        ...entriesToCheck,
                    ])
                ) {
                    // We are all good, overwrite the new state.
                    timesheet.timeEntries[editingTimeIndex] = {
                        ...selectedDetail,
                    };
                } else {
                    toasty.error(
                        'This time entry overlaps entries you\'ve already added for this date.',
                    );
                    return false;
                }
            }
        } else if (creatingNew) timesheet.timeEntries.push({ ...selectedDetail });
        else timesheet.timeEntries[editingTimeIndex] = { ...selectedDetail };

        // Order the user's time entries.  Order flat charges after the regular time detail.
        timesheet.timeEntries = this.sortTimesheetEntries([
            ...timesheet.timeEntries,
        ]);

        this.setState({
            timesheet,
            selectedDetail: null,
            showAddChargeModal: false,
        });
    };

    onChargeChange = (ev) => {
        const { selectedDetail } = { ...this.state };
        const val = ev.target.value;
        const { name } = ev.target;

        // We display the options in 12-hour format, but the db has 24.
        const timeValue = ev.target.value
            ? moment(val, [MomentTimeFormat.AnteTwelveHour]).format(
                MomentTimeFormat.TwentyFourHourWithLeading,
            )
            : '';

        selectedDetail[name] = name === 'value' ? val : timeValue;

        if (['breakStart', 'breakEnd'].some((v) => v == name) && !timeValue) {
            selectedDetail.breakStart = '';
            selectedDetail.breakEnd = '';
        }

        this.setState({
            selectedDetail,
            addTimeFormValidated: false /* reset validation to avoid highlights when changing info */,
        });
    };

    // #region TIME ENTRY
    onChargeTypeChanged = async (selection) => {
        const { selectedDetail } = { ...this.state };

        if (!selection) {
            selectedDetail.start = null;
            selectedDetail.end = null;
            selectedDetail.chargeTypeId = null;
            selectedDetail.chargeTypeUnitsId = null;
            selectedDetail.chargeTypeUnitsName = null;
        } else {
            selectedDetail.chargeTypeId = selection.chargeTypeId;
            selectedDetail.chargeTypeName = selection.description;
            const chargeTypeUnitsId = selection.chargeUnits === 'Hours'
                ? ChargeTypeUnits.Hours
                : ChargeTypeUnits.Flat;
            selectedDetail.chargeTypeUnitsId = chargeTypeUnitsId;
            selectedDetail.chargeTypeUnitsName = selection.chargeUnits;
        }

        if (selection.equipment) {
            await this.setState({ equipmentLoading: true });
            const equipmentOptions = await util.fetch.js(
                ApiRoutes.selfDispatchTimesheet.equipmentByChargeType(
                    selection.chargeTypeId,
                ),
            );
            this.setState({ equipmentOptions, equipmentLoading: false });
        }
        this.setState({ selectedDetail });
    };

    onClearErrors = () => this.setState((state) => ({ errors: [] }));

    onClientSigSavedCallback = (sigData) => {
        const { timesheet } = { ...this.state };
        if (!timesheet.clientReview) {
            timesheet.clientReview = new SelfDispatchTimesheetReviewViewModel();
            timesheet.clientReview.reviewTypeId = TimesheetReviewType.Client;
        }
        timesheet.clientReview.signatureData = sigData;
        this.setState({ timesheet });
    };
    // #endregion

    onDetailSelectAnswerChanged = onSelectAnswerChanged;

    onDetailSelectChanged = onSelectChanged;

    // #region QUESTIONS/RESPONSES
    onDetailTextAnswerChanged = onTextAnswerChanged;

    onEditTimeClicked = async (detailVM, inx) => {
        const { contracts, timesheet } = { ...this.state };

        if (!!detailVM.removed || !!detailVM.isAdjustment) return null;

        await this.setState({
            editingTimeIndex: inx,
            selectedDetail: { ...detailVM },
            showAddChargeModal: true,
        });

        const chargeType = contracts
            .find((x) => x.id === timesheet.contractId)
            .contractChargeTypes.find(
                (x) => x.chargeTypeId === detailVM.chargeTypeId,
            );

        this.onChargeTypeChanged({ ...chargeType });
    };

    onEmployeeSigSavedCallback = (sigData) => {
        const { timesheet } = { ...this.state };
        if (!timesheet.employeeReview) {
            timesheet.employeeReview = new SelfDispatchTimesheetReviewViewModel();
            timesheet.employeeReview.reviewTypeId = TimesheetReviewType.Employee;
            timesheet.employeeReview.signatureTypeId = TimesheetSignatureType.Employee;
        }
        timesheet.employeeReview.signatureData = sigData;
        this.setState({ timesheet });
    };

    onEquipmentChanged = (selection) => {
        const { selectedDetail } = { ...this.state };

        if (!selection) {
            selectedDetail.equipmentId = null;
            selectedDetail.equipmentName = null;
            selectedDetail.equipmentTypeId = null;
            selectedDetail.equipmentType = null;
        } else {
            selectedDetail.equipmentId = selection.id;
            selectedDetail.equipmentName = selection.description;
            selectedDetail.equipmentTypeId = selection.equipmentType.id;
            selectedDetail.equipmentType = selection.equipmentType.description;
        }

        this.setState({ selectedDetail });
    };

    onRemoveTimeEntryClicked = (inx) => {
        const { timesheet } = { ...this.state };
        if (!Number.isNaN(parseInt(inx))) {
            timesheet.timeEntries.splice(inx, 1);
            this.setState({ timesheet, showAddChargeModal: false });
        }
    };

    onSelectedContractChanged = (selection) => {
        this.setState(
            (state) => (
                (state.timesheet.contractId = selection?.value ?? null), state
            ),
        );
    };

    onSelectedDetailNotesChanged = (ev) => {
        const { value } = ev.target;
        this.setState((state) => ((state.selectedDetail.notes = value), state));
    };

    onSubmit = (e) => {
        const { timesheet } = { ...this.state };
        this.onClearErrors();
        this.setSaving(true);

        if (!this.additionalValidation()) {
            this.setSaving(false);
            return false;
        }

        [
            AppNavPaths.SelfDispatchTimesheetNew,
            AppNavPaths.NonFlaggingTimesheetNew,
        ].includes(this.props.location.pathname)
            ? this.create(timesheet)
            : this.update(timesheet);
    };

    getBreakName = (selectedContractChargeType) => (this.isBreakAllowed(selectedContractChargeType)
        ? selectedContractChargeType.configurations.find((c) => c.canBreak)
            .breakDescription
        : null);

    getDateTimeFromUtc = (dateStringUTC) => {
        const dateUtc = moment.utc(dateStringUTC).toDate();
        const dateLocal = moment(dateUtc)
            .local()
            .format(MomentDateFormat.DateTimeShortAnte);
        return dateLocal;
    };

    /**
     * getDuration
     *
     * Helper function to either print a duration between two moment times in hours/minutes,
     * or return the total amount of minutes between the two times. (Negative returns flat zero).
     *
     * R. Li Casanova
     *
     * @param {string} start HH:mm time format
     * @param {string} end HH:mm time format
     * @param {string} breakStart HH:mm time format
     * @param {string} breakEnd HH:mm time format
     * @param {boolean} bPrint print as string, or return total minutes
     */
    getDuration = (date, start, end, breakStart, breakEnd, bPrint) => {
    // Print duration between start and end time
        const totalDuration = '0h 00m';
        const dateFormat = 'YYYY-MM-DD HH:mm';

        if (start && end) {
            const mDate = moment(date).format('YYYY-MM-DD');
            const strStart = `${mDate} ${start}`;
            const strEnd = `${mDate} ${end}`;

            const mStartTime = moment(strStart, dateFormat);
            const mEndTime = moment(strEnd, dateFormat);

            // Account for times stretching into the next morning/day.
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
            }

            const duration = moment.duration(mEndTime.diff(mStartTime));

            let breakDurationString = '';

            if (breakStart && breakEnd) {
                const breakStartString = `${mDate} ${breakStart}`;
                const breakEndString = `${mDate} ${breakEnd}`;

                const breakStartTime = moment(breakStartString, dateFormat);
                const breakEndTime = moment(breakEndString, dateFormat);

                if (breakEndTime.isBefore(breakStartTime)) {
                    breakEndTime.add(1, 'day');
                }

                const breakDuration = moment.duration(
                    breakEndTime.diff(breakStartTime),
                );

                duration.subtract(breakDuration);

                const breakTotalMinutes = parseInt(breakDuration.asMinutes());

                const breakHours = Math.floor(breakTotalMinutes / 60);
                const breakMinutes = (breakTotalMinutes % 60)
                    .toString()
                    .padStart(2, '0');

                breakDurationString = breakHours > 0
                    ? ` (${breakHours}h ${breakMinutes}m break)`
                    : ` (${breakMinutes}m break)`;
            }

            const totalMinutes = parseInt(duration.asMinutes());

            if (totalMinutes >= 0) {
                const hours = Math.floor(totalMinutes / 60);
                const mins = (totalMinutes % 60).toString().padStart(2, '0');

                if (bPrint) return `${hours}h ${mins}m${breakDurationString}`;
                return totalMinutes;
            }
        }
        return bPrint ? totalDuration : 0.0;
    };

    getStartEndTimesForTimesheetEntry = (date, start, end) => {
        const dateFormat = 'YYYY-MM-DD HH:mm';
        if (start && end) {
            const mDate = moment(date).format('YYYY-MM-DD');
            const strStart = `${mDate} ${start}`;
            const strEnd = `${mDate} ${end}`;

            const mStartTime = moment(strStart, dateFormat);
            const mEndTime = moment(strEnd, dateFormat);

            // Account for times stretching into the next morning/day.
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
            }

            return { start: mStartTime.toDate(), end: mEndTime.toDate() };
        }
        return null;
    };

    getTimesheetStatusAlert = () => {
        const { timesheet } = { ...this.state };
        const { statusId, statusHistory } = { ...timesheet };

        let statusText = '';
        let colorContext = '';

        const history = statusHistory.find(
            (h) => h.timesheetStatusId === statusId,
        );
        const historyDate = this.getDateTimeFromUtc(history.changedOn);

        switch (statusId) {
        case TimesheetStatus.Submitted:
            statusText = (
                <FlexColumnStart className="w-100">
                    <span className="ml-2">{`Submitted on ${historyDate}`}</span>
                    
                    <small>
                        by
                        {' '}
                        {history.changedBy}
                    </small>
                </FlexColumnStart>
            );
            colorContext = 'secondary';
            break;

        case TimesheetStatus.Approved:
            statusText = (
                <FlexColumnStart className="w-100">
                    <span className="ml-2">{`Approved on ${historyDate}`}</span>
                    
                    <small>
                        by
                        {' '}
                        {history.changedBy}
                    </small>
                </FlexColumnStart>
            );
            colorContext = 'success';
            break;

        case TimesheetStatus.Rejected: {
            const rejection = timesheet.rejections[0];

            statusText = (
                <>
                    <small className="w-100 text-left border-bottom border-danger">
              Rejected by
                        {' '}
                        {`${rejection.item1} on ${historyDate}`}
                    </small>
                    <span className="w-100 pt-1 text-left">
                        <b>{rejection.item2}</b>
                    </span>
                </>
            );
            colorContext = 'danger';
            break;
        }
        default:
            return null;
        }

        return (
            <Alert
                className="m-0 pt-1 pb-2 pl-3 pr-3 text-center d-flex flex-row align-items-start"
                color={colorContext}
            >
                <FlexColumnStart className="flex-fill">
                    {statusText}
                </FlexColumnStart>
            </Alert>
        );
    };
    // #endregion

    getTotalDuration = () => {
        const { timesheet } = { ...this.state };
        if ((timesheet?.timeEntries ?? []).length) {
            const totalMinutes = timesheet.timeEntries
                .map((x) => (x.chargeTypeUnitsName === 'Hours'
                        && !!x.include
                        && !x.removed
                    ? this.getDuration(
                        timesheet.date,
                        x.start,
                        x.end,
                        x.breakStart,
                        x.breakEnd,
                    )
                    : 0.0))
                .reduce((a, b) => a + b, 0);

            const hours = Math.floor(totalMinutes / 60);
            const mins = (totalMinutes % 60).toString().padStart(2, '0');
            return `${hours}h ${mins}m`;
        }
        return '0h 00m';
    };

    setSaving = (b) => this.setState({ saving: b });

    // #region MAIN FORM
    additionalValidation = () => {
    // const { timesheet } = { ...this.state };
        const valid = true;

        // manual validation here as needed

        return valid;
    };

    compareFlatDetail = (a, b) => {
        if (a.chargeTypeName > b.chargeTypeName) {
            return -1;
        }
        if (a.chargeTypeName < b.chargeTypeName) {
            return 1;
        }
        return 0;
    };

    // Sort delegate
    compareTimeDetailVm = (a, b) => {
        const mStartA = this.formatTime24Hr(a.start);
        const mStartB = this.formatTime24Hr(b.start);

        if (mStartA.isBefore(mStartB)) {
            return -1;
        }
        if (mStartA.isAfter(mStartB)) {
            return 1;
        }
        return 0;
    };

    create = async (timesheet) => {
        const { nonFlagging } = { ...this.state };

        const response = await util.fetch.andGetResponse(
            util.fetch.types.post,
            ApiRoutes.selfDispatchTimesheet.create(),
            { ...timesheet },
            'Error Creating Timesheet',
        );

        if (!response) {
            this.setState({ saving: false });
            return false;
        }

        if (response.result === ServiceResponseResult.Ok) {
            toasty.success('Timesheet saved.');
            const url = nonFlagging
                ? AppNavPaths.NonFlaggingTimesheet
                : AppNavPaths.SelfDispatchTimesheet;
            this.props.history.push(`${url}/${response.data}`);
        } else {
            toast.error(
                <ToastMessage
                    icon={faExclamationTriangle}
                    header="There was a problem saving"
                    message={`${response.message}`}
                />,
            );
            this.setState({ saving: false });
        }
    };

    // #endregion

    // #region RENDERING
    flattenAnswers = (answers) => {
        if ((answers ?? []).length <= 0) return [];

        // First, flatten down the response types.
        const mappedAns = [...answers].map((a) => ({
            questionId: a.questionId,
            questionName: a.questionName,
            answerBoolean: a.answerBoolean,
            answerFloat: a.answerFloat,
            answerInt: a.answerInt,
            answerText:
                /*
                    RLC 2/4/22 - TODO: This needs to be expanded out to also print
                                    decimal/number answers when we support them. NBD, just
                                    need to consider formatting.
                */
                a.answerText
                ?? /* Typed answer */ a.questionOptionChoiceText /* Fall back to answer selection from drop down */,
        }));

        // Then merge the answers for display.
        const flattened = mappedAns.reduce((prev, curr, inx) => {
            const existingIndex = prev.findIndex((x) => x.questionId === curr.questionId) ?? -1;
            if (existingIndex > -1) {
                // update the item in place.  concatenates choice option text for multiple choice options, for display.
                prev[existingIndex].answerText += `, ${curr.answerText}`;
            } else {
                prev.push(curr);
            }
            return prev;
        }, []);

        return flattened;
    };

    formatTime24Hr = (stringNumber) => moment(stringNumber, MomentTimeFormat.TwentyFourHourWithLeading);

    handleSaveError = (err) => handleFormSaveError(this, err);

    handlers = {
        change: onFieldChange,
        select: onReactSelectChanged,
    };
    // #endregion

    isBreakAllowed = (selectedContractChargeType) => selectedContractChargeType
        && selectedContractChargeType.configurations
        && selectedContractChargeType.configurations.length
        && selectedContractChargeType.configurations.some((c) => c.canBreak);

    isValueInvalid() {
        return !this.state.selectedDetail?.value;
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!isAuthenticated) {
            return;
        }

        try {
            const { id } = this.props.match.params;
            const tenantUserProfile = await getTenantUserProfile();
            const { currentUser, userTenant } = tenantUserProfile;

            // Check tenant settings and user permissions for access to either self-dispatch ts or
            // non-flag ts.
            const selfDispatchTimesheetsEnabled = userTenant.tenantSettings.enableSelfDispatchTimesheets;
            if (!selfDispatchTimesheetsEnabled) return <NotAuthorizedPage />;

            const nonFlagging = this.props.location.pathname.indexOf(
                AppNavPaths.NonFlaggingTimesheet,
            ) > -1
                || this.props.location.pathname.indexOf(
                    AppNavPaths.NonFlaggingTimesheetNew,
                ) > -1;

            if (!nonFlagging) {
                if (!currentUser.canCreateSelfDispatchTimesheet) return <NotAuthorizedPage />;
            } else if (!currentUser.canCreateNonFlagTimesheet) return <NotAuthorizedPage />;

            let [nonFlagContract, contracts, timesheet] = await Promise.all([
                nonFlagging
                    ? util.fetch.js(ApiRoutes.contract.getNonFlagging())
                    : null,
                !nonFlagging
                    ? util.fetch.js(ApiRoutes.contract.getSelfDispatching())
                    : [],
                id
                    ? util.fetch.js(ApiRoutes.selfDispatchTimesheet.byId(id))
                    : new SelfDispatchTimesheet(),
            ]);

            if (!timesheet) {
                this.props.history.push(AppNavPaths.NotFound);
                return false;
            }

            // If we don't have the contract set up in the system, notify.
            if (!!nonFlagging && !nonFlagContract) return <ContractNotSetUpPage nonFlagging={nonFlagging} />;

            // On new nonFlag timesheets, set the contract to the system's nonFlag
            // contract (should only be one per tenant).
            if (!id) {
                timesheet.contractId = nonFlagContract.id;
                timesheet.contractDescription = nonFlagContract.description;
                timesheet.nonFlagging = nonFlagging;
                contracts = [nonFlagContract];
            } else {
                const contract = await util.fetch.js(
                    ApiRoutes.contract.byId(timesheet.contractId),
                );
                contracts = [contract];
                // Removed rows by management should not be seen by emps
                timesheet.timeEntries = timesheet.timeEntries.filter(
                    (x) => !x.removed,
                );
            }

            // Get unique groups for user
            const userGroups = [
                ...new Set(
                    tenantUserProfile.currentUser.appGroups.map(
                        (v) => v.groupName,
                    ),
                ),
            ];
            // Get only contract charges that the user is associated with
            contracts.forEach((contract) => {
                const chargetypes = contract.contractChargeTypes;
                contract.contractChargeTypes = chargetypes.filter((ct) => userGroups.includes(ct.group));
            });

            const originalData = JSON.stringify({ ...timesheet });

            // The user may edit the timesheet on new entries or rejection
            const editable = timesheet.statusId < TimesheetStatus.Submitted
                || timesheet.statusId === TimesheetStatus.Rejected;

            timesheet.timeEntries = this.sortTimesheetEntries([
                ...timesheet.timeEntries,
            ]);

            this.setState((state) => ({
                editable,
                newContract: !id,
                nonFlagging,
                nonFlagContract,
                contracts,
                originalData,
                loading: false,
                saving: false,
                errors: [],
                timesheet,
            }));
        } catch (error) {
            this.props.history.push(AppNavPaths.ServerError);
            return false;
        }
    }

    // Sorts entries by hours units chrono, then flat charges by charge name.
    sortTimesheetEntries = (timesheetEntries) => {
        if ((timesheetEntries ?? []).length > 1) {
            const hoursBasedEntries = timesheetEntries.filter(
                (x) => x.chargeTypeUnitsId === ChargeTypeUnits.Hours,
            ) ?? [];
            const flatEntries = timesheetEntries.filter(
                (x) => x.chargeTypeUnitsId === ChargeTypeUnits.Flat,
            ) ?? [];
            hoursBasedEntries.sort(this.compareTimeDetailVm);
            flatEntries.sort(this.compareFlatDetail);
            const sorted = [...hoursBasedEntries, ...flatEntries];
            return sorted;
        }
        return timesheetEntries;
    };

    /// Takes an entry and compares against the other timesheet entries to see if there's overlap in a range.
    timeEntryOverlaps = (entry, timeEntries) => {
        let overlaps = false;

        if (!timeEntries.length) return overlaps;

        timeEntries.sort(this.compareTimeDetailVm);

        const entryStart = this.formatTime24Hr(entry.start);
        const entryEnd = this.formatTime24Hr(entry.end);
        for (const e of timeEntries) {
            const start = this.formatTime24Hr(e.start);
            const end = this.formatTime24Hr(e.end);
            if (
            // Test the new entry against the iterated item
                (entryStart.isAfter(start) && entryStart.isBefore(end))
                || (entryEnd.isAfter(start) && entryEnd.isBefore(end))
                // Test the iterated item against the new entry.
                // Must be done in case the new entry is outside the bounds of the iterated item
                // e.g. 12AM - 6PM against 6:30AM - 12PM
                || (start.isAfter(entryStart) && start.isBefore(entryEnd))
                || (end.isAfter(entryStart) && end.isBefore(entryEnd))
            ) {
                overlaps = true;
                break;
            }
        }
        return overlaps;
    };

    toggleTimeEntryNotePopover = (inx) => {
        const { notesIndexDisplay } = { ...this.state };
        if (notesIndexDisplay === inx) this.setState({ notesIndexDisplay: null });
        else this.setState({ notesIndexDisplay: inx });
    };

    update = async (timesheet) => {
        const ts = { ...timesheet };
        ts.statusId = TimesheetStatus.Submitted;
        ts.status = 'Submitted';

        const response = await util.fetch.andGetResponse(
            util.fetch.types.put,
            ApiRoutes.selfDispatchTimesheet.byId(timesheet.id),
            ts,
            'Error Saving Timesheet',
        );

        if (!response) {
            this.setState({ saving: false });
            return false;
        }

        if (response.result === ServiceResponseResult.Ok) {
            toasty.success('Timesheet saved.');
            this.props.history.go(0);
        } else {
            toast.error(
                <ToastMessage
                    icon={faExclamationTriangle}
                    header="Unexpected problem saving the timesheet"
                    message={`${response.message}`}
                />,
            );
            this.setState({ saving: false });
        }
    };

    renderAnswers = (answers) => {
        if (!answers) return null;

        const flattenedAnswers = this.flattenAnswers([...answers]) ?? [];

        if (flattenedAnswers.length > 0) {
            return flattenedAnswers.map((ans, ansInx) => (
                <FlexColumnCenter className="mt-2 ml-3 mb-2" key={ansInx}>
                    <FlexStartRow className="border-bottom">
                        <small className="font-weight-bold text-info">
                            {ans.questionName.toUpperCase()}
                        </small>
                    </FlexStartRow>
                    <FlexStartRow className="pt-1">
                        <small>{ans.answerText}</small>
                    </FlexStartRow>
                </FlexColumnCenter>
            ));
        }
        return null;
    };

    renderForm() {
        const {
            editable,
            editingTimeIndex,
            contracts,
            originalData,
            saving,
            showAddChargeModal,
            timesheet,
            nonFlagging,
            selectedDetail,
            addTimeFormValidated,
            formValidated,
            clientSigIsComplete,
            equipmentOptions,
            equipmentLoading,
        } = { ...this.state };

        const newTimesheet = parseInt(timesheet?.id ?? 0) <= 0;

        const tenantSettings = this.context?.tenant?.tenantSettings;

        if (!timesheet || !tenantSettings) return '';

        const jTimesheet = JSON.stringify({ ...timesheet });

        const time = timesheet?.timeEntries ?? [];

        // Check the date selection before allowing time entry.
        let hasValidDate = false;
        if (timesheet?.date) hasValidDate = moment(timesheet?.date).isValid();

        const selectedContract = (contracts ?? []).find(
            (x) => x.id === timesheet?.contractId,
        );
        const selectedContractChargeType = (selectedContract?.contractChargeTypes ?? []).find(
            (x) => x.chargeTypeId === selectedDetail?.chargeTypeId,
        ) ?? '';

        const selectedIncrement = selectedContractChargeType?.configurations
            ? selectedContractChargeType.configurations[0].increment
            : '';

        // const notesLength = (selectedDetail?.notes ?? '').length;

        return (
            <>
                {!!editable && (
                    <Prompt
                        when={!saving && !isEqual(originalData, jTimesheet)}
                        message="You have unsaved changes, are you sure you want to leave?"
                    />
                )}
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId="selfDispatchTimesheetForm"
                    formHeadingIcon={faUserClock}
                    formHeading={
                        newTimesheet
                            ? 'Add Timesheet'
                            : editable
                                ? 'Resubmit Timesheet'
                                : 'Employee Timesheet'
                    }
                    formName="selfDispatchTimesheetForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={this.state.saving}
                    errors={this.state.errors}
                    loading={this.state.loading}
                    viewOnly={!editable}
                >
                    <FormBlocker showProgress show={!!saving} />
                    <Row
                        className={`self-dispatch-timesheet-content${editable ? ' editable' : ''
                        }`}
                    >
                        <Col
                            xl="6"
                            lg="8"
                            md="10"
                            sm="12"
                            className="ml-auto mr-auto pt-2"
                        >
                            {(timesheet?.statusId ?? 0)
                                >= TimesheetStatus.Submitted && (
                                <>
                                    <FormGroup>
                                        <FormLabel
                                            text="Status"
                                            required={false}
                                        />
                                        {this.getTimesheetStatusAlert()}
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel
                                            text="Employee"
                                            required={false}
                                        />
                                        <span className="form-control form-control-plaintext">
                                            {timesheet.employeeName}
                                        </span>
                                    </FormGroup>
                                </>
                            )}
                            <FormGroup>
                                <FormLabel
                                    htmlFor="timesheetDate"
                                    text="Date"
                                    required={!!editable}
                                />
                                <input
                                    required
                                    id="timesheetDate"
                                    name="timesheet.date"
                                    className={`form-control rounded-left${editable ? ' rounded-right' : ''
                                    }`}
                                    value={
                                        moment(timesheet?.date).format(
                                            MomentDateFormat.DateOnlyUTCHyphenated,
                                        ) ?? ''
                                    }
                                    onChange={(ev) => {
                                        const val = ev?.target?.value;
                                        this.setState(
                                            (state) => (
                                                (state.timesheet.date = moment(
                                                    val,
                                                ).format(
                                                    MomentDateFormat.DateOnlyUTCHyphenated,
                                                )),
                                                state
                                            ),
                                        );
                                    }}
                                    type="date"
                                />
                                <small className="w-100 text-right invalid-feedback text-danger">
                  Date is required.
                                </small>
                            </FormGroup>
                            {!nonFlagging && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="contract"
                                        text="Contract"
                                        required={!nonFlagging && !!editable}
                                    />
                                    <ValidatedSelect
                                        name="contractDropdown"
                                        required
                                        options={contracts}
                                        value={selectedContract}
                                        onChange={
                                            this.onSelectedContractChanged
                                        }
                                        validationMessage="Increment is required."
                                    />
                                </FormGroup>
                            )}
                            <FormGroup>
                                {!!editable && (
                                    <FlexBetweenRow className="pb-2">
                                        <FormLabel
                                            text="Time"
                                            className="p-0 m-0"
                                            required={!!editable}
                                        />
                                        {!!timesheet?.contractId
                                            && !!hasValidDate && (
                                            <Button
                                                color="link"
                                                className="p-0"
                                                onClick={
                                                    this.onAddTimeClicked
                                                }
                                            >
                                                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                                <small>Add Charge</small>
                                            </Button>
                                        )}
                                    </FlexBetweenRow>
                                )}
                                {
                                    <>
                                        <ListGroup>
                                            {!time.length && !!hasValidDate && (
                                                <ListGroupItem className="text-center">
                                                    No entries added.
                                                </ListGroupItem>
                                            )}
                                            {!time.length && !hasValidDate && (
                                                <ListGroupItem className="text-center">
                                                    Please select a date.
                                                </ListGroupItem>
                                            )}
                                            {!!time.length
                                                && time.map((tsdVm, inx) => {
                                                    const entryDuration = `${moment(
                                                        tsdVm.start,
                                                        MomentTimeFormat.TwentyFourHourWithLeading,
                                                    ).format(
                                                        MomentTimeFormat.AnteTwelveHour,
                                                    )} - ${moment(
                                                        tsdVm.end,
                                                        MomentTimeFormat.TwentyFourHourWithLeading,
                                                    ).format(
                                                        MomentTimeFormat.AnteTwelveHour,
                                                    )}`;

                                                    return (
                                                        <ListGroupItem
                                                            key={inx}
                                                            className={`d-flex flex-row align-items-center p-0 time-entry-item${tsdVm.isAdjustment
                                                                ? ' is-adjustment'
                                                                : ''
                                                            }`}
                                                        >
                                                            <Button
                                                                color="link"
                                                                onClick={() => this.onEditTimeClicked(
                                                                    tsdVm,
                                                                    inx,
                                                                )}
                                                                title="Click to edit this time entry"
                                                                className={`d-flex flex-column flex-fill p-2${!!editable
                                                                        && !tsdVm.isAdjustment
                                                                    ? ' cursor-pointer'
                                                                    : ''
                                                                }`}
                                                            >
                                                                <FlexBetweenRow>
                                                                    <span className="font-weight-bold">
                                                                        {!!tsdVm.isAdjustment && (
                                                                            <span
                                                                                className="badge badge-warning p-1 mr-2"
                                                                                style={{
                                                                                    lineHeight:
                                                                                        '1rem',
                                                                                }}
                                                                            >
                                                              ADJ
                                                                            </span>
                                                                        )}
                                                                        {
                                                                            tsdVm.chargeTypeName
                                                                        }
                                                                    </span>
                                                                    <FlexEndRow>
                                                                        {/* <span */}
                                                                        {/*    hidden={!tsdVm.notes} */}
                                                                        {/*    title={tsdVm.notes} */}
                                                                        {/*    className="fa fa-sticky-note mr-2" */}
                                                                        {/* > */}
                                                                        {/* </span> */}
                                                                        <span className="font-weight-bold">
                                                                            {tsdVm.chargeTypeUnitsId
                                                                                === ChargeTypeUnits.Hours
                                                                                && this.getDuration(
                                                                                    timesheet.date,
                                                                                    tsdVm.start,
                                                                                    tsdVm.end,
                                                                                    tsdVm.breakStart,
                                                                                    tsdVm.breakEnd,
                                                                                    true,
                                                                                )}
                                                                            {!!(
                                                                                tsdVm.chargeTypeUnitsId
                                                                                === ChargeTypeUnits.Flat
                                                                            )
                                                                                && `${parseFloat(
                                                                                    tsdVm.value,
                                                                                ).toFixed(
                                                                                    2,
                                                                                )} (Flat)`}
                                                                        </span>
                                                                    </FlexEndRow>
                                                                </FlexBetweenRow>
                                                                <FlexEndRow>
                                                                    <span className="text-muted d-flex flex-row align-items-center flex-nowrap">
                                                                        {!!(
                                                                            tsdVm.chargeTypeUnitsId
                                                                            === ChargeTypeUnits.Hours
                                                                        )
                                                                            && entryDuration}
                                                                        {!!(
                                                                            tsdVm.chargeTypeUnitsId
                                                                            === ChargeTypeUnits.Flat
                                                                        )
                                                                            && !!tsdVm.equipmentId && (
                                                                            <>
                                                                                <span>
                                                                                    {
                                                                                        tsdVm.equipmentType
                                                                                    }
                                                                                        &nbsp;-&nbsp;
                                                                                </span>
                                                                                <strong>
                                                                                    {
                                                                                        tsdVm.equipmentType
                                                                                    }
                                                                                </strong>
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </FlexEndRow>
                                                                {this.renderAnswers(
                                                                    tsdVm.answers,
                                                                )}
                                                                {!!tsdVm.notes && (
                                                                    <FlexColumnCenter className="mt-2 ml-3 mb-2">
                                                                        <FlexStartRow className="border-bottom">
                                                                            <small className="font-weight-bold text-info">
                                                              NOTES
                                                                            </small>
                                                                        </FlexStartRow>
                                                                        <FlexStartRow className="pt-1">
                                                                            <small>
                                                                                {
                                                                                    tsdVm.notes
                                                                                }
                                                                            </small>
                                                                        </FlexStartRow>
                                                                    </FlexColumnCenter>
                                                                )}
                                                            </Button>
                                                        </ListGroupItem>
                                                    );
                                                })}
                                        </ListGroup>
                                        <input
                                            required
                                            type="text"
                                            id="timeEntriesAdded"
                                            className="hidden-input-validation"
                                            defaultValue={
                                                (
                                                    this.state.timesheet
                                                        ?.timeEntries ?? []
                                                ).length
                                                    ? 'yes'
                                                    : ''
                                            }
                                        />
                                        <small className="invalid-feedback text-danger w-100 text-right">
                                            <b>Error:&nbsp;</b>
                      At least one time
                      entry is required.
                                        </small>
                                    </>
                                }
                            </FormGroup>
                            <hr />
                            <FormGroup className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                                <FormLabel
                                    className="mb-0 w-100"
                                    htmlFor="totalDurationAll"
                                    text="Total"
                                    required={false}
                                />
                                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
                                    <span
                                        id="totalDurationAll"
                                        className="h3 font-weight-bold text-muted m-0"
                                    >
                                        {!!timesheet?.timeEntries
                                            && this.getTotalDuration()}
                                    </span>
                                </div>
                            </FormGroup>
                            <hr />
                            {!!timesheet?.employeeReview?.isComplete && (
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="empSigPreview"
                                        text="Employee Signature:"
                                    />
                                    <img
                                        alt="Employee Signature Preview"
                                        id="empSigPreview"
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                            border: '1px solid #ccc',
                                        }}
                                        src={
                                            timesheet?.employeeReview
                                                ?.signatureData
                                        }
                                    />
                                </FormGroup>
                            )}
                            {!timesheet?.employeeReview?.isComplete && (
                                <SignatureButton
                                    id="employeeSignatureButton"
                                    ref={(ref) => (this.employeeSignatureRef = ref)}
                                    onSigSavedCallback={
                                        this.onEmployeeSigSavedCallback
                                    }
                                    sigData={
                                        timesheet?.employeeReview?.signatureData
                                    }
                                    signatureLabel="Employee"
                                />
                            )}
                            {
                                // Contract requires client sig
                                (!!selectedContract?.requireSelfDispatchTimesheetsClientSignature
                                    // If at some point we captured the sig - even if the contract was since modified to not require
                                    // the client sig - we should to historically display it regardless.
                                    || !!timesheet?.clientReview
                                        ?.signatureData) && (
                                    <>
                                        <hr />
                                        <ClientTimesheetSignature
                                            review={timesheet?.clientReview}
                                            onSignatureTypeChanged={
                                                this.onSignatureTypeChanged
                                            }
                                            onMissingSigReasonChanged={
                                                this.onMissingSigReasonChanged
                                            }
                                            onChangeNotes={this.onChangeNotes}
                                            onSigSavedCallback={
                                                this.onClientSigSavedCallback
                                            }
                                            signatureRef={
                                                this.clientSignatureRef
                                            }
                                            tenantSettings={tenantSettings}
                                            formValidated={formValidated}
                                            sigIsComplete={clientSigIsComplete}
                                        />
                                    </>
                                )
                            }
                            {!!timesheet.timesheetNumber && (
                                <FormGroup>
                                    <FormLabel
                                        text="Timesheet Number"
                                        required={false}
                                    />
                                    <span className="form-control form-control-plaintext">
                                        {timesheet.timesheetNumber}
                                    </span>
                                </FormGroup>
                            )}
                            {!!editable && (
                                <>
                                    <hr />
                                    <FlexCenterRow className="mt-3 mb-3 w-100">
                                        <Button
                                            disabled={
                                                !timesheet?.employeeReview
                                                    ?.signatureData
                                            }
                                            type="submit"
                                            color="success"
                                            name="selfDispatchTimesheetForm"
                                            className="w-100"
                                        >
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faSave}
                                            />
                                            {newTimesheet
                                                ? 'Submit Timesheet'
                                                : 'Resubmit Timesheet'}
                                        </Button>
                                    </FlexCenterRow>
                                </>
                            )}
                        </Col>
                    </Row>
                </AppPageForm>
                <Modal
                    className="full-screen-modal"
                    backdrop="static"
                    keyboard={false}
                    isOpen={showAddChargeModal}
                >
                    <ModalHeader>
                        <FlexBetweenRow className="w-100">
                            <span>
                                {!Number.isNaN(parseInt(editingTimeIndex))
                                    ? 'Edit Time'
                                    : 'Add Time'}
                            </span>
                            <Button
                                color="link"
                                onClick={() => this.setState({
                                    currentTimeEntry: null,
                                    showAddChargeModal: false,
                                })}
                            >
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                            </Button>
                        </FlexBetweenRow>
                    </ModalHeader>
                    <FormValidated
                        className="modal-body add-self-dispatch-time-form d-flex flex-column h-100 min-height-0"
                        id="addSelfDispatchTimeForm"
                        name="addSelfDispatchTimeForm"
                        ref={this.addTimeFormRef}
                        onSubmit={this.onAddTimeSubmit}
                        setIsValidated={(validated) => this.setState({ addTimeFormValidated: validated })}
                        isValidated={addTimeFormValidated}
                    >
                        <div className="d-flex flex-fill flex-column overflow-y-auto time-entry-content">
                            <FormGroup>
                                <FormLabel
                                    htmlFor="chargeTypesDropdown"
                                    text="Charge"
                                    required={!!editable}
                                />
                                <ValidatedSelect
                                    menuPosition="fixed" /* RLC: When being in an overflow container, this will prevent cutoff. */
                                    maxMenuHeight={150}
                                    isClearable={false}
                                    name="chargeTypesDropdown"
                                    required
                                    isDisabled={!!selectedDetail?.id}
                                    value={selectedContractChargeType}
                                    options={
                                        selectedContract?.contractChargeTypes
                                        ?? []
                                    }
                                    onChange={this.onChargeTypeChanged}
                                    getOptionLabel={(o) => o.description}
                                    getOptionValue={(o) => o.chargeTypeId}
                                    validationMessage="A charge is required."
                                />
                            </FormGroup>
                            {!!selectedContractChargeType?.equipment && (
                                <FormGroup>
                                    {!!equipmentLoading && (
                                        <FlexStartRow>
                                            <Spinner
                                                className="circular-progress-equipment-loading"
                                            />
                                            <span className="ml-2">
                    Loading equipment...
                                            </span>
                                        </FlexStartRow>
                                    )}
                                    {!equipmentLoading && (
                                        <>
                                            <FormLabel
                                                htmlFor="equipmentDropdown"
                                                text="Equipment"
                                                required={
                                                    !!selectedContractChargeType?.equipment
                                                }
                                            />
                                            <ValidatedSelect
                                                menuPosition="fixed" /* RLC: When being in an overflow container, this will prevent cutoff. */
                                                isClearable={false}
                                                name="equipmentDropdown"
                                                required={
                                                    !!selectedContractChargeType?.equipment
                                                    && !!editable
                                                }
                                                isDisabled={!editable}
                                                value={
                                                    (
                                                        equipmentOptions ?? []
                                                    ).find(
                                                        (eo) => eo.id
                                                            === selectedDetail?.equipmentId,
                                                    ) ?? ''
                                                }
                                                options={equipmentOptions ?? []}
                                                onChange={
                                                    this.onEquipmentChanged
                                                }
                                                getOptionLabel={(o) => o.description}
                                                getOptionValue={(o) => o.id}
                                                validationMessage="An equipment selection is required."
                                            />
                                        </>
                                    )}
                                </FormGroup>
                            )}
                            {selectedContractChargeType?.chargeUnits
                                === 'Flat' && (
                                <FormGroup>
                                    <FormLabel
                                        className="mb-0"
                                        htmlFor="start"
                                        text="Units"
                                        required={!!editable}
                                    />
                                    <NumericInput
                                        showMobileSpinners
                                        className="form-control"
                                        disabled={!editable}
                                        name="value"
                                        required={!!editable}
                                        value={selectedDetail.value ?? ''}
                                        onChange={(event) => {
                                            const { selectedDetail } = {
                                                ...this.state,
                                            };
                                            const { value } = event.target;
                                            selectedDetail.value = value;
                                            this.setState({
                                                selectedDetail,
                                            });
                                        }}
                                        placeholder="Enter value"
                                        type="number"
                                        step={selectedIncrement ?? 1}
                                    />
                                    {!!addTimeFormValidated
                                                && !!this.isValueInvalid() && (
                                        <small className="pt-1 text-danger w-100 text-right">
                                                  Value is required and must
                                                  be greater than zero.
                                        </small>
                                    )}
                                </FormGroup>
                            )}
                            {selectedContractChargeType?.chargeUnits
                                === 'Hours' && (
                                <>
                                    <FormGroup className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                                        <FormLabel
                                            className="mb-0"
                                            htmlFor="start"
                                            text="Start Time"
                                            required={!!editable}
                                        />
                                        <FlexColumnStart className="w-100">
                                            <TimePicker
                                                disabled={!editable}
                                                required={!!editable}
                                                name="start"
                                                value={selectedDetail.start}
                                                increment={selectedIncrement}
                                                onChange={this.onChargeChange}
                                            />
                                            <small className="invalid-feedback text-danger">
                                        Start Time is required.
                                            </small>
                                        </FlexColumnStart>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                                        <FormLabel
                                            className="mb-0 flex-fill"
                                            htmlFor="chargeEndTime"
                                            text="End Time"
                                            required={!!editable}
                                        />
                                        <FlexColumnStart className="w-100">
                                            <TimePicker
                                                disabled={
                                                    !selectedDetail?.start
                                                        || !editable
                                                }
                                                required={!!editable}
                                                name="end"
                                                value={selectedDetail.end}
                                                increment={selectedIncrement}
                                                onChange={this.onChargeChange}
                                            />
                                            <small className="invalid-feedback text-danger">
                                        End Time is required.
                                            </small>
                                        </FlexColumnStart>
                                    </FormGroup>

                                    {this.isBreakAllowed(
                                        selectedContractChargeType,
                                    ) && (
                                        <>
                                            <FormGroup className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                                                <FormLabel
                                                    className="mb-0"
                                                    htmlFor="breakStart"
                                                    text={`${this.getBreakName(
                                                        selectedContractChargeType,
                                                    )} Start`}
                                                />
                                                <FlexColumnStart className="w-100">
                                                    <TimePicker
                                                        disabled={!editable}
                                                        name="breakStart"
                                                        value={
                                                            selectedDetail.breakStart
                                                        }
                                                        increment={
                                                            selectedIncrement
                                                        }
                                                        onChange={
                                                            this.onChargeChange
                                                        }
                                                    />
                                                </FlexColumnStart>
                                            </FormGroup>
                                            <FormGroup className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                                                <FormLabel
                                                    className="mb-0 flex-fill"
                                                    htmlFor="breakEnd"
                                                    text={`${this.getBreakName(
                                                        selectedContractChargeType,
                                                    )} End`}
                                                />
                                                <FlexColumnStart className="w-100">
                                                    <TimePicker
                                                        disabled={
                                                            !selectedDetail?.breakStart
                                                                    || !editable
                                                        }
                                                        name="breakEnd"
                                                        value={
                                                            selectedDetail.breakEnd
                                                        }
                                                        increment={
                                                            selectedIncrement
                                                        }
                                                        onChange={
                                                            this.onChargeChange
                                                        }
                                                    />
                                                </FlexColumnStart>
                                            </FormGroup>
                                        </>
                                    )}

                                    <div className="p-0 mb-2 border-top" />
                                    <FormGroup className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                                        <FormLabel
                                            className="mb-0 w-100"
                                            htmlFor="totalDuration"
                                            text="Total"
                                            required={false}
                                        />
                                        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-start">
                                            <span
                                                id="totalDuration"
                                                className="h3 font-weight-bold text-muted m-0"
                                            >
                                                {this.getDuration(
                                                    timesheet?.date,
                                                    selectedDetail?.start,
                                                    selectedDetail?.end,
                                                    selectedDetail?.breakStart,
                                                    selectedDetail?.breakEnd,
                                                    true,
                                                )}
                                            </span>
                                        </div>
                                    </FormGroup>
                                    <div className="p-0 mb-2 border-top" />
                                </>
                            )}
                            {!!(selectedContractChargeType?.questions ?? [])
                                .length
                                && selectedContractChargeType.questions
                                    .sort((q1, q2) => q1.order - q2.order)
                                    .map((q, qInx) => (
                                        <SurveyQuestion
                                            disabled={!editable}
                                            key={qInx}
                                            question={q}
                                            questionIndex={qInx}
                                            answers={selectedDetail.answers}
                                            onTextAnswerChanged={(event) => this.onDetailTextAnswerChanged(
                                                event,
                                                q,
                                                'selectedDetail',
                                                'answers',
                                            )}
                                            onSelectAnswerChanged={(
                                                selection,
                                            ) => this.onDetailSelectAnswerChanged(
                                                selection,
                                                q,
                                                'selectedDetail',
                                                'answers',
                                            )}
                                            onSelectChanged={(selection) => this.onDetailSelectChanged(
                                                selection,
                                                q,
                                                'selectedDetail',
                                                'answers',
                                            )}
                                        />
                                    ))}
                        </div>
                        <FlexColumnStart className="w-100">
                            {!!editable && (
                                <FlexCenterRow className="w-100">
                                    <Button
                                        type="submit"
                                        name="addSelfDispatchTimeForm"
                                        className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center"
                                        color="primary"
                                    >
                  Save Time Entry
                                    </Button>
                                </FlexCenterRow>
                            )}
                            {!!editable
                                && !Number.isNaN(parseInt(editingTimeIndex)) && (
                                <FlexCenterRow className="w-100">
                                    <Button
                                        title="Click to remove this time entry"
                                        color="outline-danger"
                                        className="mt-3 cursor-pointer w-100"
                                        onClick={() => this.onRemoveTimeEntryClicked(
                                            editingTimeIndex,
                                        )}
                                    >
                                        <i className="fa fa-trash mr-1" />
                                    Delete Time Entry
                                    </Button>
                                </FlexCenterRow>
                            )}
                        </FlexColumnStart>
                    </FormValidated>
                </Modal>
            </>
        );
    }

    render() {
        if (!!this.state.loading || !this.context?.tenant?.tenantSettings) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting timesheet...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }
        return this.renderForm();
    }
    // #endregion
}
export default withRouter(SelfDispatchTimesheetForm);
