import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Input,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { ExecutionHistory } from './common/ExecutionHistory';

const syncOptions = [
    { value: 'USE_DEFAULTS', label: 'Use Contract Defaults' },
    { value: 'FORCE_BILLING', label: 'Move Timesheet to Billing' },
    { value: 'FORCE_BILLING_CHARGES', label: 'Move Timesheet to Billing: Charges' },
];

class BaseSyncTimesheetContractBilling {
    timesheetNumber = '';
    syncOption = syncOptions[0].value;
}

class SyncTimesheetContractBilling extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            syncTimesheetData: new BaseSyncTimesheetContractBilling(),
            selectedSyncOption: syncOptions[0],
            isValidated: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange = onFieldChange;

    onSyncOptionChanged = (selectedOption) => {
        this.setState((prevState) => ({
            selectedSyncOption: selectedOption,
            syncTimesheetData: {
                ...prevState.syncTimesheetData,
                syncOption: selectedOption ? selectedOption.value : null,
            },
        }));
    };

    onSubmit = async () => {
        const { syncTimesheetData } = { ...this.state };
        this.setState({ saving: true });

        try {
            await util.fetch.post(ApiRoutes.adminTool.syncTimesheetContractBilling(), syncTimesheetData);

            this.setState({
                syncTimesheetData: {
                    ...this.state.syncTimesheetData,
                    timesheetNumber: '',
                },
                isValidated: false,
            });

            toasty.success('Timesheet data has been synchronized');

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error('Error: Sync failed. Please Check Form Inputs');
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    render() {
        const {
            loading, saving, isValidated, syncTimesheetData,
            selectedSyncOption,
        } = this.state;

        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Sync Timesheet/Contract Billing"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNumber" text="Timesheet number" required />
                                    <Input
                                        id="timesheetNumber"
                                        name="syncTimesheetData.timesheetNumber"
                                        onChange={this.onChange}
                                        value={syncTimesheetData.timesheetNumber}
                                        onBlur={this.onTimesheetNumberBlur}
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Timesheet Number is Required.
                                    </small>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel htmlFor="syncOption" text="Sync behaviour" required />
                                    <ValidatedSelect
                                        id="syncOption"
                                        name="syncOption"
                                        required
                                        options={syncOptions}
                                        value={selectedSyncOption}
                                        onChange={this.onSyncOptionChanged}
                                        validationMessage="Select sync behaviour"
                                    />
                                </FormGroup>

                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <>
                            <div>
                                <List type="unstyled">
                                    {record.input.timesheet_number && (
                                        <li>
                                            <strong>Timesheet:</strong>
                                            {' '}
                                            {record.input.timesheet_number}
                                        </li>
                                    )}
                                    {record.input.sync_option && (
                                        <li>
                                            <strong>Sync behaviour:</strong>
                                            {' '}
                                            {record.input.sync_option}
                                        </li>
                                    )}
                                    {record.input.use_invoicing && (
                                        <li>
                                            <strong>Billing Used Invoicing:</strong>
                                            {' '}
                                            {record.input.use_invoicing}
                                        </li>
                                    )}
                                    {record.input.billing_status_id && (
                                        <li>
                                            <strong>Billing Had Status:</strong>
                                            {' '}
                                            {record.input.billing_status_id}
                                        </li>
                                    )}

                                    {record.input.billing_id && (
                                        <li>
                                            <strong>Billing ID:</strong>
                                            {' '}
                                            {record.input.billing_id}
                                        </li>
                                    )}
                                </List>
                            </div>

                            <div>
                                <List type="unstyled">
                                    {record.output.use_invoicing && (
                                        <li>
                                            <strong>Billing Now Uses Invoicing:</strong>
                                            {' '}
                                            {record.output.use_invoicing}
                                        </li>
                                    )}
                                    {record.output.billing_status_id && (
                                        <li>
                                            <strong>New Billing Status:</strong>
                                            {' '}
                                            {record.output.billing_status_id}
                                        </li>
                                    )}
                                </List>
                            </div>
                        </>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(SyncTimesheetContractBilling);
