import React from 'react';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { ExecutionHistory } from './common/ExecutionHistory';

class RemoveChargesVM {
    timesheetId = '';
    timesheetDetailIds = [];
}

class RemoveCharges extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: false,
            saving: false,
            removeTimesheetCharges: new RemoveChargesVM(),
            isValidated: false,
            selectedTimesheetOption: null,
            selectedChargeTypeOption: [],
            chargeTypeEmpty: false,
            chargesList: [],
        };

        this.onChange = this.onChange.bind(this);
    }

    onChargesChanged = (selectedOptions) => {
        const timesheetDetailIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.setState((prevState) => ({
            selectedChargeTypeOption: selectedOptions,
            removeTimesheetCharges: {
                ...prevState.removeTimesheetCharges,
                timesheetDetailIds: timesheetDetailIds,
            },
        }));
    };

    onChange = onFieldChange;

    onTimesheetChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            removeTimesheetCharges: {
                ...prevState.removeTimesheetCharges,
                timesheetId: selectedOption.value
            },
            selectedTimesheetOption: selectedOption,
        }));
    };

    onSubmit = async () => {
        const { removeTimesheetCharges } = this.state;
        const chargeTypeEmpty = !this.state.selectedChargeTypeOption || this.state.selectedChargeTypeOption.length === 0;
        if (chargeTypeEmpty) {
            this.setState({ isValidated: true });
            toasty.error('Please select at least one charge type.');
            return;
        }

        this.setState({ saving: true });

        try {
            await util.fetch.post(ApiRoutes.adminTool.removeCharges(), removeTimesheetCharges);

            this.setState({
                removeTimesheetCharges: {
                    ...this.state.removeTimesheetCharges,
                    timesheetId: '',
                    timesheetDetailIds: [],
                },
                isValidated: false,
                selectedTimesheetOption: null,
                selectedChargeTypeOption: [],
                chargesList: [],
            });

            toasty.success('Remove Charges from Timesheet Succeeded');

            // Refresh the table after successful submission
            await this.props.refreshUsageHistory();
        } catch (err) {
            toasty.error('Error: Removing Charges from Timesheet Failed. Please Check Form Inputs');
            await this.props.refreshUsageHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    onTimesheetNumberBlur = async (event) => {
        const { selectedTimesheetOption } = this.state;
        const timesheetId = selectedTimesheetOption ? selectedTimesheetOption.value : '';

        try {
            const timesheetDetails = await util.fetch.js(
                ApiRoutes.typeAheads.timesheetDetails(timesheetId),
            );

            if (!timesheetDetails || timesheetDetails.length === 0) {
                this.setState({
                    chargesList: [],
                });
                return;
            }

            const options = timesheetDetails.map((item) => ({
                value: item.value,
                label: `[${item.label}] ${item.employee ? ' - ' + item.employee : ''}`
            }));

            this.setState({
                chargesList: options
            });
        } catch (err) {
            this.setState({
                chargesList: [],
            });
        }
    };

    loadTimesheetOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.timesheetNumberSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.customer}] - ${item.status}${item.isDeleted ? ' (Deleted)' : ''}`,
                    value: item.value,
                    isDeleted: item.isDeleted
                }));
            })
            .catch((error) => {
                console.error('Error fetching timesheet options:', error);
                return [];
            });
    };

    render() {
        const {
            loading, saving, isValidated,
            chargesList, selectedChargeTypeOption,
        } = this.state;
        
        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                backUrl={AppNavPaths.AdminTool}
                backLabel="Back to Admin Tools"
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Remove Charges"
                formRef={this.formRef}
                formIsColumn
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNum" text="Timesheet Number" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadTimesheetOptions}
                                        placeholder="Type Timesheet Number Here"
                                        id="timesheetNumber"
                                        value={this.state.selectedTimesheetOption}
                                        onChange={this.onTimesheetChanged}
                                        onBlur={this.onTimesheetNumberBlur}
                                        cacheOptions
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Timesheet Number is Required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="chargeTypes" text="Charges to be Removed" required />
                                    <ValidatedSelect
                                        id="baseChargeType"
                                        name="baseChargeType"
                                        required
                                        options={chargesList}
                                        value={selectedChargeTypeOption}
                                        onChange={this.onChargesChanged}
                                        validationMessage="At least one charge type selection is required."
                                        isMulti={true}
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ExecutionHistory usageHistory={this.props.usageHistory}>
                    {(record) => (
                        <List type="unstyled">
                            {record.input.timesheetNumber && (
                                <li>
                                    <strong>Timesheet:</strong>
                                    {' '}
                                    {record.input.timesheetNumber}
                                </li>
                            )}
                            {record.input.removedCharges && record.input.removedCharges.length > 0 && (
                                <li>
                                    <strong>Charges Removed:</strong>
                                    <ul>
                                        {record.input.removedCharges.map((charge, index) => (
                                            <li key={index}>{charge}</li>
                                        ))}
                                    </ul>
                                </li>
                            )}
                            {record.input.chargeWithIssue && (
                                <li>
                                    <strong>Charges With Issue:</strong>
                                    {' '}
                                    {record.input.chargeWithIssue}
                                </li>
                            )}
                        </List>
                    )}
                </ExecutionHistory>
            </AppPageForm>
        );
    }
}

export default withRouter(RemoveCharges);
