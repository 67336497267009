import React from 'react';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, FormGroup } from 'reactstrap';
import { debounce } from 'lodash-es';
import AddressInput from './AddressInput';
import CommonContext, { ApiRoutes } from '../Common';
import {
    FormLabel,
    onReactSelectChanged,
    FormCheckbox,
} from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import { Address } from './Address';
import { util } from '../Util';
import { CountyCaptureType } from '../tenant/Tenant';

export default class AddressFormNew extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.addRef = React.createRef();
        this.state = {
            address: new Address(props.countryId, props.countryCode),
            formTitle: 'New Address',
            formValidated: false,
            loading: true,
            mapHidden: true,
            errors: {},
            validationMessage: '',
            show: false,
            showSameAs: false,
            showSameAsTitle: '',
            usStateObjects: [],
            usCounties: [],
            isCountyCaptureEnabled: false,
            saveDisabled: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.updateCounty = this.updateCounty.bind(this);
        this.onUsStateChange = this.onUsStateChange.bind(this);

        this.debouncedUpdateCounty = debounce(async () => {
            this.updateCounty();
        }, 1000);
    }

    componentDidMount() {
        return this.populateState();
    }

    componentDidUpdate(prevProps) {
        // Check if countryId has changed
        if (prevProps.countryId !== this.props.countryId) {
            this.setState((state) => ({
                address: {
                    ...state.address,
                    countryId: this.props.countryId,
                    countryCode: this.props.countryCode,
                },
            }), () => {
            });
        }
    }

    onChange = async function (e) {
        if (!e || !e.target || !e.target.name) {
            return;
        }

        const changedPropertyName = e.target.name.split('.').pop();

        const { address, tenantSettings } = this.state;

        address[changedPropertyName] = e.target.value;

        if (
            this.useCountyCapture(tenantSettings)
            && this.isCompleteEnoughToGeocode(address)
            && ['address1', 'city', 'state', 'country'].includes(changedPropertyName)
        ) {
            this.setState({ address, saveDisabled: true });
            this.debouncedUpdateCounty();
        } else {
            this.setState({ address });
        }
    };

    onCheckedChanged = (e) => {
        const { address } = this.state;
        address[e.target.name] = e.target.checked;
        this.setState({ address });
    };

    onClose = () => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.setState({ show: false });
    };

    onSelectChange = onReactSelectChanged;

    onSubmit = async (e) => {
        this.props.onSaveCallback({ ...this.state.address });
        this.onClose();
    };

    onUsStateChange = async function (item) {
        const stateAbbreviation = (item ?? {}).value ?? '';

        const { address, tenantSettings } = this.state;

        address.state = stateAbbreviation;

        if (
            this.useCountyCapture(tenantSettings)
            && this.isCompleteEnoughToGeocode(address)
        ) {
            this.setState({ address, saveDisabled: true });
            this.debouncedUpdateCounty();
        } else {
            this.setState({ address });
        }
    };


    getIndicatedCountyId = (mapResponse) => {
        if (
            !mapResponse ||
            mapResponse.status !== 'OK' ||
            !mapResponse.results ||
            mapResponse.results.length === 0
        ) {
            return null;
        }
    
        const { address, usCounties, usStateObjects } = this.state;
    
        // Find the active state based on the selected address's state
        const activeState = usStateObjects.find(
            (s) => s.value === address.state || s.abbreviation === address.state
        );
    
        if (!activeState) {
            return null;
        }
    
        // Filter counties for the active state
        const activeCounties = usCounties.filter(
            (c) => c.usStateId === activeState.id
        );
    
        // Extract "administrative_area_level_2" (County) from the Google Maps response
        const responseCounty = mapResponse.results[0].address_components.find(
            (ac) => ac.types.includes('administrative_area_level_2')
        );
    
        if (responseCounty) {
            // Match the parent county
            const activeCounty = activeCounties.find(
                (c) =>
                    c.name === responseCounty.long_name || // Exact match
                    c.name === responseCounty.short_name || // Short match
                    c.name.startsWith(`${responseCounty.long_name} (Independent City)`) // Match independent cities
            );
    
            if (activeCounty) {
                // Dynamically find sub-counties based on parentCountyId
                const subCounties = usCounties.filter(
                    (c) => c.parentCounty?.id === activeCounty.id
                );
    
                if (subCounties.length > 0) {
                    // Match sub-county by city
                    const subCounty = subCounties.find((sub) =>
                        sub.cities?.some(
                            (city) =>
                                city.toLowerCase().trim() ===
                                address.city.toLowerCase().trim()
                        )
                    );
    
                    if (subCounty) {
                        // Preserve both parent and sub-county details
                        address.subCountyName = subCounty.name;
                        address.parentCountyName = activeCounty.name; // Store parent name for display
                        return subCounty.id; // Return sub-county ID for saving
                    }
                }
    
                // If no sub-county is matched, fall back to parent county
                address.parentCountyName = activeCounty.name;
                address.subCountyName = null; // Clear sub-county name
                return activeCounty.id;
            }
        }
    
        // Fallback to using "locality" (City) if no "administrative_area_level_2" is found
        const responseLocality = mapResponse.results[0].address_components.find(
            (ac) => ac.types.includes('locality')
        );
    
        if (responseLocality) {
            const activeCounty = activeCounties.find(
                (c) =>
                    c.name === responseLocality.long_name || // Exact match
                    c.name === responseLocality.short_name || // Short match
                    c.name.startsWith(`${responseLocality.long_name} (Independent City)`) // Match independent cities
            );
    
            if (activeCounty) {
                address.parentCountyName = activeCounty.name;
                address.subCountyName = null;
                return activeCounty.id;
            }
        }
    
        return null;
    };    

    isCompleteEnoughToGeocode = (address) => address && address.address1 && address.city && address.state;

    open = (address, title, showSameAs, showSameAsTitle) => {
        this.resetForm();
        const currentAddress = { ...address };
        this.context.setFormOpened(true);
        this.setState(
            {
                address: currentAddress,
                show: true,
                formTitle:
                    title ?? (address.id ? 'Edit Address' : 'New Address'),
                showSameAs,
                showSameAsTitle,
            },
        );
    };

    populateState = async () => {
        const [usStateObjects, usCounties, tenant] = await Promise.all([
            util.fetch.js(ApiRoutes.USStates.all()),
            util.fetch.js(ApiRoutes.USCounties.all()),
            util.fetch.js(ApiRoutes.tenant.settings()),
            this.props.countryId ? util.fetch.js(ApiRoutes.typeAheads.USStatesbyCountryId(this.props.countryId)) : Promise.resolve([]),
        ]);

        const { tenantSettings } = tenant;
        const countyCaptureEnabled = this.useCountyCapture(tenantSettings);

        this.setState({
            usCounties,
            usStateObjects,
            tenantSettings,
            isCountyCaptureEnabled: countyCaptureEnabled,
            loading: false,
        });
    };

    resetForm = () => this.setState({ formValidated: false });

    updateCounty = async () => {
        const { address, tenantSettings } = this.state;

        if (
            !this.useCountyCapture(tenantSettings)
            || !this.isCompleteEnoughToGeocode(address)
        ) {
            this.setState({ saveDisabled: false });
            return;
        }

        const apiKey = tenantSettings.googleMapsApiKey;

        const addressPortion = encodeURI(
            `${address.address1} ${address.city},${address.state}`,
        );

        const fetchResponse = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${addressPortion}&key=${apiKey}`,
        );

        const mapResponse = await fetchResponse.json();

        const countyId = this.getIndicatedCountyId(mapResponse);

        address.usCountyId = countyId;

        this.setState({ address, saveDisabled: false });
    };

    useCountyCapture = (tenantSettings) => tenantSettings.captureCounty == CountyCaptureType.AllTimesheets
        || (tenantSettings.captureCounty == CountyCaptureType.PrevailingWageTimesheets && this.props.contractUsesPrevailingWage);

    render() {
        const {
            address,
            errors,
            formValidated,
            validationMessage,
            usCounties,
            isCountyCaptureEnabled,
            saveDisabled,
        } = this.state;
        if (!address) return '';
        return (
            <SlideForm
                loading={this.state.loading}
                show={this.state.show}
                id={this.props.id ?? 'addressForm'}
                formIcon={faAddressCard}
                formTitle={this.state.formTitle}
                ref={this.formRef}
                setIsValidated={(value) => this.setState({ formValidated: value })}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onDelete={this.onDelete}
                errors={errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                saveDisabled={saveDisabled}
            >
                <Row noGutters>
                    <Col>
                        <AddressInput
                            ref={this.addRef} 
                            namePrefix="address"
                            single
                            onChange={this.onChange}
                            address={address}
                            isCountyCaptureEnabled={isCountyCaptureEnabled}
                            usCounties={usCounties}
                            onStateChanged={this.onUsStateChange}
                        />
                        {!!this.state.showSameAs && (
                            <FormCheckbox
                                small
                                className="ml-3 mt-2"
                                id="duplicate"
                                name="duplicate"
                                checked={address.duplicate}
                                onChange={this.onCheckedChanged}
                                labelText={this.state.showSameAsTitle ?? ''}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col hidden={this.state.mapHidden}>
                        <FormLabel text="" />
                        <FormGroup>
                            <div
                                id="emp_routing_map"
                                className="border border-default"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </SlideForm>
        );
    }
}
