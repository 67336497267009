import React, { useEffect, useState, useContext } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ListGroup, ListGroupItem, Spinner
} from 'reactstrap';
import { util } from '../Util';
import CommonContext, { ApiRoutes, } from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import '../../style/NavMenu.scss';

const TenantAccessModal = ({ onClose, showTenantModal }) => {


    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTenants, setLoadingTenants] = useState(null);
    const { tenant } = useContext(CommonContext);
    useEffect(() => {
        fetchTenants();
    }, []);

    const fetchTenants = async () => {
        setLoading(true);
        try {
            const response = await util.fetch.js(ApiRoutes.tenant.all());
            setTenants(response);
        } catch (error) {
            console.error('Error fetching tenants:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleTenantClick = async (tenantId) => {
        setLoadingTenants(tenantId);
        await util.fetch.put(ApiRoutes.auth.updateTenant(tenantId));
        window.location.href = '/';
    };
    const toggleChangeTenantModal = () => {
        onClose();
    };

    return (
        <Modal isOpen={showTenantModal} toggle={() => toggleChangeTenantModal()}>
            <ModalHeader toggle={() => toggleChangeTenantModal()}>
                Change Tenant
            </ModalHeader>
            <ModalBody>
                {loading ? (
                    <Spinner
                        size="sm"
                        className="mr-1"
                    />
                ) : (
                    <div>
                        <ListGroup>
                            {tenants.map((t) => (
                                <ListGroupItem
                                    onClick={() => handleTenantClick(t.id)}
                                    tag="button"
                                    key={t.id}
                                    disabled={tenant?.id === t.id || loadingTenants === t.id}
                                >
                                    {t.companyName}
                                    {tenant?.id === t.id && (
                                        <FontAwesomeIcon icon={faCheck} className="ml-2 text-success" size="lg" />
                                    )}
                                    {loadingTenants === t.id && (
                                        <Spinner
                                            size="sm"
                                            className="ml-2"
                                        />
                                    )}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};

export default TenantAccessModal;












